import {apiUrl} from "./apiEnv";
import {axios} from "./apiWrapper";
import {useEffect, useState} from "react";
import {getType} from "mime";

export const getEvidence = async (targetType, targetId, operatorCode) => {
    return await axios.get(getEvidenceUrl(targetType, targetId, operatorCode))
}

export const getAttachmentForEvidence = async (attachmentGuid) => {
    return await axios.get(`${apiUrl}/attachments/${attachmentGuid}`)

}

export const getAttachmentsForEvidence = async (verificationCode) => {
    return (await axios.get(`${apiUrl}/evidence/all/attachments/${verificationCode}`)).data;
}

export const downloadFileFromS3FromUrl = async (downloadFile) => {
     const documentData = (axios.get(downloadFile.signedUrl, {
        responseType: 'arraybuffer',
    }));

    return {
        ...downloadFile,
        documentData
    }
}

export const getEvidenceUrl = (targetType, targetId, operatorCode) => {
    return targetType === EvidenceTarget.draft
        ? `${apiUrl}/evidence/drafts/${targetId}`
        : `${apiUrl}/evidence/operators/${operatorCode}/cases/${targetId}`
}

export const useEvidence = (targetType, targetId, refreshKey) => {
    let operatorCode = null;
    if (targetType === EvidenceTarget.case) {
        operatorCode = targetId.substring(0, 3);
    }

    const [isLoading, setIsLoading] = useState(true);
    const [evidenceForCase, setEvidenceForCase] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;
        let tmpEvidence = [];

        if (mounted) {
            setError(null);
            setIsLoading(true);
            getEvidence(targetType, targetId, operatorCode)
                .then(evidenceArray => {
                    if (evidenceArray === null) {
                        return Promise.resolve();
                    }

                    const promises = evidenceArray.data.map(evidence => {
                        return getAttachmentForEvidence(evidence.attachmentGuid).then(attachmentResponse => {
                            if (attachmentResponse != null) {
                                tmpEvidence.push({
                                    ...attachmentResponse.data,
                                    category: evidence.category,
                                });
                            }
                        });
                    })

                    return Promise.all(promises).then(() => setEvidenceForCase(tmpEvidence));
                })
                .catch(error => {
                    setError(error.message)
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        return () => mounted = false;
    }, [targetId, refreshKey, operatorCode, targetType]);

    return {
        isLoading,
        error,
        evidenceForCase,
    };
}

export const uploadAppellantFiles = async (targetType, targetId, operatorCode, correlationId, appellantFiles) => {
    return Promise.all(
        appellantFiles.map(async (appellantFile) => {
            return uploadAppellantFile(targetType, targetId, operatorCode, correlationId, appellantFile)
        })
    );
}

export const uploadOperatorFiles = async (targetType, targetId, operatorCode, operatorFiles) => {
    return Promise.all(
        operatorFiles.map(async (operatorFile) => {
            return uploadOperatorFile(targetType, targetId, operatorCode, operatorFile);
        })
    )
}

export const uploadAppellantFile = async (targetType, targetId, operatorCode, correlationId, appellantFile) => {
    const createAttachmentHeaders = {'X-Correlation-ID': correlationId};

    let errorStatus = {errorMessage: null};

    try {
        // POST attachment data,
        const attachmentResponse = await axios.post(
            `${apiUrl}/attachments/`,
            attachmentBuilder(appellantFile.values.filename),
            {headers: createAttachmentHeaders}
        )
        // PUT file to S3 using the signed url in attachment response
        await axios.put(
            attachmentResponse.data['signedUrl'],
            appellantFile.values.filename,
            {headers: {'Content-Type': attachmentResponse?.data.mimeType}}
        )
        // PUT attachment data to set to uploaded
        await axios.put(
            `${apiUrl}/attachments/`,
            updateAttachmentBuilder(attachmentResponse)
        )
        // PUT evidence data
        await axios.put(
            getEvidenceUrl(targetType, targetId, operatorCode),
            appellantEvidenceBuilder(targetType, targetId, attachmentResponse.data, appellantFile.values)
        )
    } catch(error) {
        errorStatus.errorMessage = `${appellantFile.values.filename.name} - ${error.message}` || `Problem uploading file ${appellantFile.values.filename.name}`
    }

    return errorStatus;
};

export const uploadOperatorFile = async (targetType, targetId, operatorCode, operatorFile) => {
    let errorStatus = {errorMessage: null};

    try {
        // Call post attachment and receive a signed url to put the file to
        const createAttachmentResponse = await axios.post(
            `${apiUrl}/attachments/`,
            attachmentBuilder(operatorFile)
        )
        // Upload the file to Amazon
        await axios.put(
            createAttachmentResponse.data['signedUrl'],
            operatorFile,
            {headers: {'Content-Type': createAttachmentResponse?.data.mimeType}}
        )
        // Put attachment, update the state to uploaded and return the attachment guid
        const updateAttachmentResponse = await axios.put(
            `${apiUrl}/attachments/`,
            updateAttachmentBuilder(createAttachmentResponse)
        )
        // Put evidence with the "attachment guid" & "case verification code"
        await axios.put(
            getEvidenceUrl(targetType, targetId, operatorCode) + "?complete=false",
            operatorEvidenceBuilder(targetType, targetId, updateAttachmentResponse.data)
        )
    } catch (error) {
        errorStatus.errorMessage = `${operatorFile.name} - ${error.message}` || `Problem uploading file ${operatorFile.name}`
    }

    return errorStatus
}

export const deleteFile = async (guid) => {
    return axios.delete(`${apiUrl}/attachments/${guid}`);
}

const attachmentBuilder = (evidenceData) => {
    return ({
        "filename": evidenceData.name,
        "mimeType": getType(evidenceData.name),
        "fileSize": evidenceData.size,
        "userId": "",
        "uploadStatus": "AWAITING_FILE",
    });
}

const updateAttachmentBuilder = (attachment) => {
    return ({
        ...attachment.data,
        "uploadStatus": "UPLOADED",
    });
}

export const appellantEvidenceBuilder = (targetType, targetId, attachment, fileData) => {
    if (targetType === EvidenceTarget.draft) {
        return {
            guid: attachment.guid,
            attachmentGuid: attachment.guid,
            caseVerificationCode: "",
            draftCaseId: targetId,
            evidenceOriginator: "APPELLANT",
            malwareStatus: "UNCHECKED",
            name: fileData.title,
            category: fileData.type,
            createdAt: attachment.createdAt,
            updatedAt: attachment.updatedAt,
            description: fileData.description,
            indexId: 0
        }
    }

    return {
        caseVerificationCode: targetId,
        evidenceOriginator: "APPELLANT",
        malwareStatus: "UNCHECKED",
        name: fileData.title,
        attachmentGuid: attachment.guid,
        category: fileData.type,
        description: fileData.description,
    };
}

export const operatorEvidenceBuilder = (targetType, targetId, attachment) => {
    if (targetType === EvidenceTarget.draft) {
        return {
            guid: attachment.guid,
            attachmentGuid: attachment.guid,
            caseVerificationCode: "",
            draftCaseId: targetId,
            evidenceOriginator: "OPERATOR",
            malwareStatus: "UNCHECKED",
            category: "OP_CASE_SUMMARY",
            name: attachment.filename,
            createdAt: attachment.createdAt,
            updatedAt: attachment.updatedAt,
            description: null,
            indexId: 0
        }
    }

    return {
        caseVerificationCode: targetId,
        evidenceOriginator: "OPERATOR",
        malwareStatus: "UNCHECKED",
        name: attachment.filename,
        attachmentGuid: attachment.guid,
        category: "OP_CASE_SUMMARY"
    };
}

export const EvidenceTarget = {
    case: 'cases',
    draft: 'drafts'
}