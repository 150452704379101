import React from 'react';

import PropTypes from "prop-types";
import OperatorFileAttachmentFiles from "./OperatorFileAttachmentFiles";

const OperatorFileAttachments = ({files, setFiles}) => {

    function removeFile(index) {
        const updatedFiles = files.filter((f, i) => i !== index)
        setFiles(updatedFiles)
    }

    return (
        <div>
            <OperatorFileAttachmentFiles files={files} onRemove={removeFile}/>
        </div>
    )
}

OperatorFileAttachments.propTypes = {
    onFilesChanged: PropTypes.func,
    setFiles: PropTypes.func,
    files: PropTypes.array,
    disabled: PropTypes.bool
}

export default OperatorFileAttachments