import React, {ChangeEvent, useState} from 'react';
import {Paper, Box, Theme, Typography, Grid, TextField, MenuItem} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useCharges, addCharge, AddCharge} from "../../api/chargesApi";
import {Case} from "../../model/Case";
import {LoadingButton} from "@mui/lab";
import {displayDate} from "../../utils/dateUtils";
import {DataGridPro, GridOverlay} from "@mui/x-data-grid-pro";
import {customFormatter} from "../../utils/dataGridHelpers";
import {useUser} from "../../auth/useUser";
import useSnackBar from "../SnackBarContext";
import {ADD_CHARGE, hasAuthority} from "../../auth/AuthorityHelper";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        height: '100%',
        boxSizing: 'border-box',
    },
    formControl: {
        marginBottom: theme.spacing(2)
    }
}));

const columns = [
    {field: 'chargeType', headerName: 'Charge Type', flex: 1},
    {field: 'createdAt', headerName: 'Added At', flex: 1, valueFormatter: customFormatter(displayDate, '-')},
]

const Charges = (props: Props) => {
    const {caseObject} = props;
    const {user} = useUser()
    const [chargeType, setChargeType] = useState('')
    const [addChargeIsLoading, setAddChargeIsLoading] = useState(false);
    const charges = useCharges(caseObject.verificationCode, user?.token);
    const classes = useStyles();
    const {showToast} = useSnackBar();

    const handleChangeChargeType = (e: ChangeEvent<HTMLInputElement>) => {
        setChargeType(e.target.value)
    }

    const handleAddCharge = async () => {
        setAddChargeIsLoading(true)
        const charge: AddCharge = {
            chargeType: chargeType
        }
        await addCharge(caseObject.verificationCode, charge)
            .then(() => {
                charges.refresh()
                showToast('Charge added to case', 'success')
            })
            .catch(error => {
                showToast(error.message, 'error')
            })
            .finally(() => {
                setAddChargeIsLoading(false)
            })
    }

    return (
        <Paper className={classes.paper}>
            <Grid container mt={0} spacing={2}>
                <Grid item xs={hasAuthority(ADD_CHARGE) ? 8 : 12}>
                    <Box px={2} pb={2}>
                        <Typography variant="subtitle1" gutterBottom>Charges</Typography>
                        <DataGridPro
                            columns={columns}
                            loading={charges.isLoading}
                            rows={charges.data || []}
                            getRowId={row => row.chargeId}
                            components={{
                                NoRowsOverlay: () => <GridOverlay>No charges have been applied to this case</GridOverlay>
                            }}
                            autoHeight
                            hideFooter
                        />
                    </Box>
                </Grid>
                {   hasAuthority(ADD_CHARGE) &&
                    (<Grid item xs={4}>
                    <Box px={2} pb={2}>
                        <Typography variant="subtitle1" gutterBottom>Add Charge</Typography>
                        <TextField
                            label="Charge Type"
                            name="chargeType"
                            data-testid="chargeType-select"
                            id="chargeType-select"
                            variant="filled"
                            value={chargeType}
                            disabled={false}
                            onChange={handleChangeChargeType}
                            fullWidth
                            select
                        >
                            <MenuItem value="MITIGATION">Mitigation</MenuItem>
                        </TextField>
                        <Box mt={2} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <LoadingButton
                                disabled={!chargeType}
                                loading={addChargeIsLoading}
                                onClick={handleAddCharge}
                                data-testid="add-charge"
                            >Add</LoadingButton>
                        </Box>
                    </Box>
                </Grid>)
                }
            </Grid>
        </Paper>
    )
}

interface Props {
    caseObject: Case
}

export default Charges