import {apiUrl} from "./apiEnv";
import {axios} from "./apiWrapper";
import {useEffect, useState} from "react";

export const getTemplate = (code) => {
    return axios.get(`${apiUrl}/templates/${code}`)
}

export const useTemplate = (code) => {
    const [isLoading, setIsLoading] = useState(true);
    const [template, setTemplate] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        getTemplate(code).then((result) => {
            setTemplate(result.data)
        }).catch(error => {
            setError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });

    }, [code]);

    return {
        isLoading,
        error,
        template,
        setTemplate
    };
}

