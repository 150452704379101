import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";

const StateStoreContext = createContext({})

export const StateStoreProvider = ({children}) => {
    const [values, setValues] = useState({})
    const [initialValues, setInitialValues] = useState({})

    return <StateStoreContext.Provider
        value={{values, setValues, initialValues, setInitialValues}}>{children}</StateStoreContext.Provider>
}

StateStoreProvider.propTypes = {
    children: PropTypes.element
}

/**
 * A 'useState' like function with a unique key
 * @param key - A unique key used to store this value in memory
 * @param initialValue - The initial value to use
 * @returns {[*, (function(*=): *)]}
 */
export const useStateStore = (key, initialValue) => {
    const hasSetInitialValues = useRef(false)
    const {values, setValues, setInitialValues} = useContext(StateStoreContext);
    const returnValue = values[key] === undefined ? initialValue : values[key]

    function setState(newValue) {
        let value = typeof newValue === 'function' ? newValue(values[key] === undefined ? initialValue : values[key]) : newValue
        return setValues(prev => {
            return {...prev, [key]: value}
        })
    }

    useEffect(() => {
        if (!hasSetInitialValues.current) {
            setInitialValues(prev => ({...prev, [key]: initialValue}))
            hasSetInitialValues.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, initialValue]);

    return [returnValue, setState]
}

/**
 * Reset: Remove items from the store, call with no arguments to remove all items
 * filtersAreInitial: Check if filter values are all the same as their initial values i.e. have been reset
 * @returns {{filtersAreInitial, reset: (function(): *)}}
 */
export const useClearStateStore = () => {
    const {values, setValues, initialValues} = useContext(StateStoreContext);

    const reset = () => setValues({})

    const filtersAreInitial = Object.entries(values).every(([k, v]) => {
        return v.toString() === initialValues[k].toString();
    })

    return {reset, filtersAreInitial}
}