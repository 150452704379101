/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {TextField} from "@mui/material";
import '../../src/App.css';

const MultiLineInput = ({dataTestId, label, ...props}) => {

    const [showError, setShowError] = useState(false);

    const onBlur = (event) => {
        !event.target.value.trim() ? setShowError(true) : setShowError(false);
    }

    return (
        <div className={'App-Input-MultiLineTextBox'}>
            <TextField
                data-testid={dataTestId}
                onBlur={onBlur}
                variant="filled"
                fullWidth
                multiline
                label={label}
                error={showError}
                helperText={showError && `Please enter your ${label.toLowerCase()}`}
                InputLabelProps={{
                    shrink: true,
                }}
                {...props}
            />
        </div>
    );
}

export default MultiLineInput;