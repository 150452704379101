import React, {useState} from 'react';
import {Box, Paper, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import DomPurify from 'dompurify';
import Input from "../components/Input";
import {useHistory, Redirect} from 'react-router-dom';
import useSnackBar from "../components/SnackBarContext";
import useAuth from "./AuthProvider";
import './LoginPage.css';
import {getAuthorities} from "./AuthorityHelper";

export const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    const {showToast} = useSnackBar();
    const {login, loading, authed} = useAuth();

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value);
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        if (username.trim().length === 0 || password.trim().length === 0) {
            return;
        }

        login(username, password)
            .then(() => {
                if (!getAuthorities().length) {
                    showToast("This user has been granted no authorities, some actions may be restricted", "warning", 8000)
                }
                history.push('/');
            })
            .catch((error: any) => {
                showToast(error.response?.data?.message || error.message, 'error')
            });
    }

    if (authed) {
        return <Redirect to="/" />
    }

    return (
        <div className="LoginPage">
            <div data-testid="login_form">
                <Paper elevation={3} className="App-Login-Form">
                    <div dangerouslySetInnerHTML={{__html: DomPurify.sanitize(`<img src="/images/popla-logo-color.svg" alt="Popla logo" class="App-Popla-Logo" />`)}} />
                    <div className={"App-Login-SubForm"}>
                        <Typography variant="h5" className={"App-Login-Header"}>
                            <b>Log In</b>
                        </Typography>
                        <Input dataTestId={"input_username"} label={"Email"} name={"username"} type={"text"} onChange={handleUsernameChange} onKeyDown={handleKeyDown} />
                        <Input dataTestId={"input_password"} label={"Password"} name={"password"} type={"password"} onChange={handlePasswordChange} onKeyDown={handleKeyDown} />
                        <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                            <LoadingButton loading={loading} data-testid="button_submit" onClick={handleSubmit}>Log in</LoadingButton>
                        </Box>
                    </div>
                </Paper>
            </div>
        </div>
    );
}


