import {useEffect, useState} from "react";
import {axios} from "./apiWrapper";
import {apiUrl} from "./apiEnv";

export const getEvidence = async (verificationCode, token) => {
    return await axios.get(`${apiUrl}/cases/${verificationCode}/comments`)
}

export const addComment= async (comment, token) => {
    return axios.post(`${apiUrl}/operators/${comment.operatorCode}/cases/${comment.caseId}/comments`,
        comment)
}

export const useComments = (verificationCode, token, updateTime) => {
    const [isLoading, setIsLoading] = useState(true);
    const [operatorEvidence, setOperatorEvidence] = useState([]);
    const [motoristEvidence, setMotoristEvidence] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;
        getEvidence(verificationCode, token)
            .then(result => {
                if (mounted) {
                    const operatorComments = result?.data.filter(function (comment) {
                        return comment.commentType === "OPERATOR_COMMENT"
                    });

                    const motoristComments = result?.data.filter(function (comment) {
                        return comment.commentType === "APPELLANT_COMMENT"
                    });

                    setOperatorEvidence(operatorComments);
                    setMotoristEvidence(motoristComments);
                }
            })
            .catch(error => {
                setError(error.message);
                return null;
            })
            .finally(() => {
                setIsLoading(false);
            })

        return () => mounted = false;

    }, [verificationCode, token, updateTime]);

    return {
        isLoading,
        error,
        operatorEvidence,
        motoristEvidence,
    };
};
