/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {TextField} from "@mui/material";

const ValidInput = ({errorText, ...restProps}) => {
    const [helperText, setHelperText] = useState('')

    useEffect(() => {
        setHelperText(errorText)
    }, [errorText])

    return (
        <div className="App-Input-TextBox">
            <TextField
                variant="filled"
                error={!!errorText}
                helperText={helperText || restProps.helperText || ''}
                fullWidth
                InputLabelProps={{shrink: true}}
                {...restProps}
            />
        </div>
    );
}

export default ValidInput;