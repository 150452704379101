import React, {useState} from 'react'
import {Breadcrumbs, Box, Link as MaterialLink, Typography, Grid, Button} from "@mui/material";
import {Link as ReactLink, useHistory} from "react-router-dom";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {PersonAdd} from "@mui/icons-material";
import {useRoles, useUsers} from "../api/securityApi";
import {CREATE_ASSESSOR, EDIT_ASSESSOR, hasAuthority} from "../auth/AuthorityHelper";

const AssessorUsersPage = () => {
    const [pageNum, setPageNum] = useState(0);
    const {isLoading : isLoadingUsers , assessorUsers} = useUsers(false);
    const {isLoading : isLoadingRoles, roles}  = useRoles();
    const history = useHistory();
    const canEdit = hasAuthority(EDIT_ASSESSOR);

    const columns = [
        {field: 'name', headerName: 'Name', flex: 1},
        {field: 'email', headerName: 'Email', flex: 1},
        {field: 'roleId', headerName: 'Role', flex: 1, valueFormatter: params => {
            if (!isLoadingRoles) {
                return roles.find((element) => element.id === params.value)?.name;
            } else {
                return "";
            }
       }},
    ]

    const handleRowClick = (params) => {
        if (canEdit) {
            history.push({
                pathname: `/assessors/edit`,
                state: {assessorUser: params.row},
            })
        }
    }

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '28px'}}>
                <MaterialLink component={ReactLink} to="/" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <Typography color="textPrimary">Assessor Users</Typography>
            </Breadcrumbs>
            <Box>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h1" gutterBottom>View Assessor Users</Typography>
                    </Grid>
                    {hasAuthority(CREATE_ASSESSOR) &&
                        <Grid item>
                            <Button data-testid="add-assessor-user" startIcon={<PersonAdd/>} component={ReactLink}
                                    to="/assessors/create">Add User</Button>
                        </Grid>
                    }
                </Grid>
                <DataGridPro
                    rows={assessorUsers || []}
                    columns={columns}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    page={pageNum}
                    loading={isLoadingRoles || isLoadingUsers}
                    paginationMode="client"
                    autoHeight
                    disableColumnMenu
                    disableSelectionOnClick
                    columnBuffer={4}
                    onPageChange={setPageNum}
                    onRowClick = {canEdit ? handleRowClick : null}
                />
            </Box>
        </div>
    );
}

AssessorUsersPage.protoTypes = {
}

export default AssessorUsersPage