import React, {useContext, useEffect} from 'react';
import {
    Box,
    FormControl,
    FormControlLabel, FormLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Button,
    Autocomplete
} from "@mui/material";
import ValidInput from "../../../components/ValidInput";
import {OperatorUserFormContext, formModes} from "./OperatorUserFormContext"
import {useHistory} from 'react-router-dom';
import {PersonAdd, Save, ArrowBack} from "@mui/icons-material";
import {useOperators} from "../../../api/operatorApi";
import PropTypes from "prop-types";
import {hasSomeAuthorities} from "../../../auth/AuthorityHelper";

const CreateOperatorUserForm = ({operatorUser}) => {
    const { isLoading, operators } = useOperators(0, 1000);
    const history = useHistory();
    const {
        formValues,
        setFormValues,
        formErrors,
        formMode,
        setFormMode,
        handleInput,
        handleOperatorCodeInput,
        handleSubmit
    } = useContext(OperatorUserFormContext)

    useEffect(() => {
        if (operatorUser) {
            setFormMode(formModes.EDIT)
            setFormValues({
                id: operatorUser.id,
                email: operatorUser.email,
                name: operatorUser.name,
                roles: operatorUser.roles,
                operatorCodes: operatorUser.operatorCodes,
                disabled: operatorUser.disabled,
            })
        }
    }, [operatorUser, setFormMode, setFormValues])

    const getOperatorsInputValue = () => {
        if (operators?.content && formValues.operatorCodes.length) {
            return operators.content.filter(o => formValues.operatorCodes.includes(o.operatorCode))
        }
        return []
    }

    return (
        <Paper elevation={3}>
            <Box p={2}>
                <ValidInput
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                    value={formValues.email}
                    onChange={handleInput}
                    errorText={formErrors.email}
                    inputProps={{maxLength: 100}}
                    disabled={ !hasSomeAuthorities('CREATE_OPERATOR_USER', 'EDIT_OPERATOR_USER') }
                    required
                />

                <ValidInput
                    id="name"
                    label="Name"
                    name="name"
                    value={formValues.name}
                    onChange={handleInput}
                    errorText={formErrors.name}
                    inputProps={{maxLength: 70}}
                    disabled={ !hasSomeAuthorities('CREATE_OPERATOR_USER', 'EDIT_OPERATOR_USER') }
                    required
                />

                <Box mb={"30px"}>
                    <FormControl fullWidth disabled={ !hasSomeAuthorities('CREATE_OPERATOR_USER', 'EDIT_OPERATOR_USER') }>
                        <FormLabel component="legend">Role</FormLabel>
                        <RadioGroup row name="roles" value={formValues.roles} onChange={handleInput}>
                            <FormControlLabel value="SUPERUSER" control={<Radio />} label="Superuser" />
                            <FormControlLabel value="PARENT" control={<Radio />} label="Parent" />
                            <FormControlLabel value="CHILD" control={<Radio />} label="Child" />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box mb={"30px"}>
                    <Autocomplete
                        multiple
                        id="operators"
                        loading={isLoading}
                        options={operators?.content ? operators?.content : []}
                        getOptionLabel={(option) => option.operatorCode + ' - ' + option.name}
                        filterSelectedOptions
                        onChange={handleOperatorCodeInput}
                        value={getOperatorsInputValue()}
                        disabled={ !hasSomeAuthorities('CREATE_OPERATOR_USER', 'EDIT_OPERATOR_USER') }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Operators"
                                InputLabelProps={{ shrink: true }}
                                placeholder="Type for operators"
                                name="operatorCodes"
                                helperText={formErrors.operatorCodes}
                                error={!!formErrors.operatorCodes}
                            />
                        )}
                    />
                </Box>

                <Box mb={"30px"}>
                    <FormControl fullWidth disabled={ !hasSomeAuthorities('CREATE_OPERATOR_USER', 'EDIT_OPERATOR_USER') }>
                        <FormLabel component="legend">Status</FormLabel>
                        <RadioGroup row name="disabled" value={formValues.disabled.toString()} onChange={handleInput}>
                            <FormControlLabel value="false" control={<Radio />} label="Enabled" />
                            <FormControlLabel value="true" control={<Radio />} label="Disabled" />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Button data-testid="back-button" startIcon={<ArrowBack />} onClick={() => history.goBack()}>Back</Button>
                    {(formMode === formModes.EDIT && hasSomeAuthorities('CREATE_OPERATOR_USER', 'EDIT_OPERATOR_USER')) && (<Button data-testid="submit-button" startIcon={<Save />} onClick={handleSubmit}>Save Operator User</Button>)}
                    {(formMode === formModes.CREATE && hasSomeAuthorities('CREATE_OPERATOR_USER', 'EDIT_OPERATOR_USER')) && (<Button data-testid="submit-button" startIcon={<PersonAdd />} onClick={handleSubmit}>Add Operator User</Button>)}
                </Box>
            </Box>
        </Paper>
    )
}

CreateOperatorUserForm.propTypes = {
    operatorUser: PropTypes.object
}

export default CreateOperatorUserForm