import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {schema, validate} from "./schema";
import {useHistory} from "react-router-dom";
import {SnackBarContext} from "../../components/SnackBarContext";
import {useUser} from "../../auth/useUser";
import {addOperator, updateOperator} from "../../api/operatorApi";

export const formModes = { CREATE: "create", EDIT: "edit" }

export const OperatorFormContext = React.createContext({
    formValues: {},
    setFormValues: {},
    formErrors: {},
    setFormErrors: {},
    formMode: formModes.CREATE,
    setFormMode: {},
    handleInput: {},
    handleSubmit: {},
    isSaving: false
})

const defaultFormValues = {
    operatorCode: '',
    email: '',
    name: '',
    phone: '',
    portalAccountStatus: 'CREATED',
    url: '',
    country: 'EW',
}

const defaultFormErrors = {}

export const OperatorFormProvider = ({children}) => {
    const [formValues, setFormValues] = useState({...defaultFormValues})
    const [formErrors, setFormErrors] = useState({...defaultFormErrors})
    const [formMode, setFormMode] = useState(formModes.CREATE)
    const [isSaving, setIsSaving] = useState(false);
    const {user} = useUser();
    const history = useHistory()
    const {showToast} = useContext(SnackBarContext)

    function handleInput(e) {
        let { name, value, type } = e.target;
        let updatedValues = {}

        if (type === 'checkbox') {
            value = e.target.checked
        }

        updatedValues[name] = value;
        setFormValues(Object.assign({...formValues}, updatedValues))
    }

    async function handleSubmit() {
        const updatedFormErrors = validate(formValues, schema)
        setFormErrors(updatedFormErrors)

        if (Object.keys(updatedFormErrors).length === 0) {
            setIsSaving(true)
            formMode === formModes.EDIT
                ? await submitUpdate()
                : await submitCreate()
        } else {
            showToast('There are errors in the form, please correct them before continuing', 'error');
        }
    }

    async function submitCreate() {
        const result = await addOperator(formValues, user?.token);
        if(result.status){
            setIsSaving(false)
            history.push('/operators')
            showToast('Operator added successfully')
        }else {
            showToast(`Problem while saving operator ${result.errorMessage}`, 'error');
        }
    }

    async function submitUpdate() {
        const result = await updateOperator(formValues, user?.token);
        if (result.status) {
            setIsSaving(false)
            history.push('/operators')
            showToast('Operator updated successfully')
        } else {
            showToast(`Problem while saving operator ${result.errorMessage}`, 'error');
        }
    }

    return (
        <OperatorFormContext.Provider value={{
            formValues,
            setFormValues,
            formErrors,
            setFormErrors,
            formMode,
            setFormMode,
            handleInput,
            handleSubmit,
            isSaving
        }}>
            {children}
        </OperatorFormContext.Provider>
    )
}

OperatorFormProvider.propTypes = {
    children: PropTypes.element
}