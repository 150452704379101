import {truncate} from "../utils/stringUtils";
import {CaseNote} from "./CaseNote";

export type CaseSource = "POSTAL";

export interface CreateCase {
    draftCaseId: string,
    verificationCode: string,
    operatorCode: string,
    correlationId: string,
    caseSource: CaseSource,
    yourDetailsModel: AppellantDetails,
    appellantAnswers: AppellantAnswer[],
}

export interface DraftCase {
    verificationCode: string,
    name: string,
    address: string,
    phone: string,
    email: string,
    data: any,
    notes: CaseNote[],
}

export interface AppellantDetails {
    isThirdParty: boolean,
    motoristTitle: string,
    motoristFirstName: string,
    motoristLastName: string,
    motoristAddressModel: AddressDetails,
    appellantTitle: string,
    appellantFirstName: string,
    appellantLastName: string,
    appellantAddressModel: AddressDetails,
    appellantPhoneNumber: string,
    appellantEmail: string,
    appellantVehicleRegistration: string,
    appellantPcnNumber: string,
    appellantOperatorName: string,
    allowCxSurveys: boolean,
    ageGroup: string,
    carParkType: string,
    easeOfParking: string,
    queueForPay: string,
    evCharge: string,
    specialSpaces: string,
    vehicleDetails: VehicleDetails,
}

export interface AppellantAnswer {
    questionCode: string,
    answer: string,
}

export interface AddressDetails {
    BuildingNumber: string,
    AddressLine1: string,
    AddressLine2: string,
    County: string,
    PostTown: string,
    Postcode: string,
}

export interface VehicleDetails {
    make: string,
    model: string,
    dateOfFirstRegistration: string,
    yearOfManufacture: string,
    cylinderCapacity: string,
    co2Emissions: string,
    fuelType: string,
    transmission: string,
    taxStatus: string,
    colour: string,
    typeApproval: string,
    wheelPlan: string,
    revenueWeight: string,
    taxDetails: string,
    motDetails: string,
    vin: string,
    taxed: boolean,
    mot: boolean,
}

export const toDraftCase = (source: any): DraftCase => {
    const address = [
        source.appellantBuildingNumber,
        source.appellantAddressLine1,
        source.appellantAddressLine2,
        source.appellantCounty,
        source.appellantPostTown,
        source.appellantPostcode,
    ]

    return {
        verificationCode: source.verificationCode,
        name: truncate(`${source.appellantFirstName} ${source.appellantLastName}`, 100, '...'),
        address: truncate(address.filter(v => v.length).join(', '), 200, '...'),
        phone: source.appellantPhoneNumber,
        email: source.appellantEmail,
        data: {...source},
        notes: source.notes
    }
}

export const toCreateCase = (source: any, draftId: any): CreateCase => {
    return {
        draftCaseId: draftId,
        verificationCode: source.verificationCode,
        operatorCode: source.operatorCode,
        correlationId: source.correlationId,
        caseSource: source.caseSource,
        yourDetailsModel: {
            isThirdParty: source.showMotorist,
            motoristTitle: source.motoristTitle,
            motoristFirstName: source.motoristFirstName,
            motoristLastName: source.motoristLastName,
            motoristAddressModel: {
                BuildingNumber: source.motoristBuildingNumber,
                AddressLine1: source.motoristAddressLine1,
                AddressLine2: source.motoristAddressLine2,
                County: source.motoristCounty,
                PostTown: source.motoristPostTown,
                Postcode: source.motoristPostcode,
            },
            appellantTitle: source.appellantTitle,
            appellantFirstName: source.appellantFirstName,
            appellantLastName: source.appellantLastName,
            appellantAddressModel: {
                BuildingNumber: source.appellantBuildingNumber,
                AddressLine1: source.appellantAddressLine1,
                AddressLine2: source.appellantAddressLine2,
                County: source.appellantCounty,
                PostTown: source.appellantPostTown,
                Postcode: source.appellantPostcode,
            },
            appellantPhoneNumber: source.appellantPhoneNumber,
            appellantEmail: source.appellantEmail,
            appellantVehicleRegistration: source.vehicleRegistration,
            appellantPcnNumber: source.appellantPcnNumber,
            appellantOperatorName: '',
            allowCxSurveys: false,
            ageGroup: '',
            carParkType: '',
            easeOfParking: '',
            queueForPay: '',
            evCharge: '',
            specialSpaces: '',
            vehicleDetails: {
                make: source.vehicleMake,
                model: source.vehicleModel,
                colour: source.vehicleColor,
                taxed: source.taxed,
                mot: source.mot,
                motDetails: source.motDetails,
                dateOfFirstRegistration: source.dateOfFirstRegistration,
                yearOfManufacture: source.yearOfManufacture,
                cylinderCapacity: source.cylinderCapacity,
                co2Emissions: source.co2Emissions,
                fuelType: source.fuelType,
                revenueWeight: source.revenueWeight,
                taxDetails: source.taxDetails,
                taxStatus: source.taxStatus,
                transmission: source.transmission,
                typeApproval: source.typeApproval,
                vin: source.vin,
                wheelPlan: source.wheelPlan,
            },
        },
        appellantAnswers: source.answers ? Object.entries<string>(source.answers)
            .filter(([, value]) => value !== '')
            .map(([key, value]): AppellantAnswer => ({
                questionCode: key,
                answer: value,
            })) : [],
    };
}
