import React, {useContext, useEffect, useState, useRef} from 'react'
import {CaseFormContext, withCaseFormContext} from "./CaseFormContext";
import {Breadcrumbs, Link as MaterialLink, Tabs, Typography, Container, Box} from "@mui/material";
import {Link as ReactLink, useParams} from "react-router-dom";
import CaseTab from "../../components/email/CaseTab";
import tabProps from "../../utils/tabProps";
import CaseForm from './CaseForm'
import PropTypes from "prop-types";
import {NoteTarget} from "../../api/notesApi";
import NotesView from "../../components/notes/NotesView";
import {EvidenceTarget} from "../../api/evidenceApi";
import {DocumentsView} from "../../components/documents/DocumentsView";
import DraftEmailTab from "./DraftEmailTab";
import {EmailTarget} from "../../api/emailApi";

const CreateCasePage = () => {
    const params = useParams()
    const hasLoadedDraftData = useRef(false);
    const [tabValue, setTabValue] = useState(0);
    const {loadDraftData, draftId} = useContext(CaseFormContext);

    useEffect(() => {
        if (params.id && !hasLoadedDraftData.current) {
            loadDraftData(params.id);
            hasLoadedDraftData.current = true
        }
    }, [params.id, loadDraftData])

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '28px'}}>
                <MaterialLink component={ReactLink} to="/" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <Typography color="textPrimary">Cases</Typography>
                <Typography color="textPrimary">Create</Typography>
            </Breadcrumbs>
            <Container maxWidth="lg">
                <Typography variant="h1" gutterBottom>Create case</Typography>
                {draftId
                    ? (
                        <>
                            <Box pb={2}>
                                <Tabs
                                    value={tabValue}
                                    onChange={(e,v) => setTabValue(v)}
                                    TabIndicatorProps={{style: {display: "none"}}}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <CaseTab label={"Details"} {...tabProps(0)}/>
                                    <CaseTab label={"Notes"} {...tabProps(1)}/>
                                    <CaseTab label={"Documents"} {...tabProps(2)}/>
                                    <CaseTab label={"Emails"} {...tabProps(3)}/>
                                </Tabs>
                            </Box>
                            <TabPanel value={tabValue} index={0}>
                                <CaseForm />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <NotesView foreignKey={draftId} noteTarget={NoteTarget.draft} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <DocumentsView foreignKey={draftId} documentTarget={EvidenceTarget.draft} operatorCode={null} maxHeight={1000} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <DraftEmailTab foreignKey={draftId} emailTarget={EmailTarget.draft} />
                            </TabPanel>
                        </>
                    )
                    : <CaseForm />
                }
            </Container>
        </div>
    )
}

CreateCasePage.propTypes = {}

export default withCaseFormContext(CreateCasePage)

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};