import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";
import {render, unmountComponentAtNode} from "react-dom";
import ThemeProvider from "./ThemeProvider";

const ConfirmDialog = (props) => {
    const { title, children, confirmLabel = "Ok", cancelLabel = "Cancel", open, setOpen, onConfirm, onCancel = () => {},  } = props;

    function handleConfirm() {
        setOpen(false);
        onConfirm();
    }

    function handleClose() {
        setOpen(false);
        onCancel();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog"
            data-testid="confirm-dialog"
        >
            <DialogTitle data-testid="confirm-dialog-title">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button data-testid="confirm-button"
                    variant="contained"
                    onClick={handleConfirm}
                    color="secondary"
                >
                    {confirmLabel}
                </Button>
                <Button data-testid="cancel-button"
                    variant="contained"
                    onClick={handleClose}
                    color="primary"
                >
                    {cancelLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func
}

export default ConfirmDialog;

export const useConfirmation = () => {
    return (title, message, confirmLabel = "Ok", cancelLabel = "Cancel") => {
        return new Promise((resolve) => {
            createConfirmElement({
                title,
                children: message,
                confirmLabel,
                cancelLabel,
                open: true,
                setOpen: () => {},
                onConfirm: () => {
                    resolve(true)
                    removeConfirmElement()
                },
                onCancel: () => {
                    resolve(false)
                    removeConfirmElement()
                },

            })
        })
    }
}

function createConfirmElement ({children, ...props}) {
    let divTarget = document.getElementById('react-confirm')
    if (!divTarget) {
        divTarget = document.createElement('div')
        divTarget.id = 'react-confirm'
        document.body.appendChild(divTarget)
    }
    render(<ThemeProvider><ConfirmDialog {...props}>{children}</ConfirmDialog></ThemeProvider>, divTarget)
}

function removeConfirmElement() {
    const target = document.getElementById('react-confirm')
    if (target) {
        unmountComponentAtNode(target)
        target.parentNode.removeChild(target)
    }
}