import React from 'react';
import {
    ThemeProvider,
    createTheme,
    responsiveFontSizes,
} from "@mui/material/styles";
import {pink, grey} from "@mui/material/colors"
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/lab/themeAugmentation';

let defaultTheme = createTheme()

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const palette = {
    ...defaultTheme.palette,
    default: {
        main: grey[300]
    },
    primary: {
        main: '#0E2361'
    },
    secondary: {
        main: pink[500]
    }
}

let theme = createTheme({
    palette,
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained'
            },
            styleOverrides: {
                root: {
                    borderRadius: 50,
                    minWidth: '135px',
                    textTransform: 'none'
                }
            }
        },
        MuiLoadingButton: {
            defaultProps: {
                variant: 'contained'
            },
        },
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: '1em'
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                row: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: palette.grey[50],
                        '&:hover': {
                            backgroundColor: palette.grey[100],
                            outline: 'solid 1px #7BAFDF',
                        }
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 600,
                    backgroundColor: defaultTheme.palette.common.white,
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: defaultTheme.palette.grey[100],
                    },
                },
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'filled',
            },
        }
    },
    typography: {
        h1: {
            fontSize: "2.125rem"
        },
        subtitle1: {
            color: palette.primary.main,
            fontWeight: 600,
            fontSize: '1rem'
        },
        subtitle2: {
            color: palette.primary.main,
            fontWeight: 600,
            fontSize: "0.875rem"
        }
    }
})

theme = responsiveFontSizes(theme);

const CustomThemeProvider = (props: CustomThemeProviderProps) => {
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

interface CustomThemeProviderProps {
    children: any
}

export default CustomThemeProvider