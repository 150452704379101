import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Grid, Typography, Paper, IconButton, Box, Theme} from "@mui/material";
import {StatusView} from "../status/StatusView";
import {SummaryField} from "./SummaryField";
import {WithdrawView} from "../withdraw/WithdrawView";
import {displayDateTime, displayTargetDecisionDate} from "../../utils/dateUtils";
import {caseSourceMapper} from "../../utils/caseSourceMapper";
import AssessorSelector from "./AssessorSelector";
import {formatName} from "../../utils/stringUtils";
import AppellantDetails from "./AppellantDetails";
import {Edit} from "@mui/icons-material";
import EditCaseDetails from "./EditCaseDetails";
import useSnackBar from "../SnackBarContext";
import {Case} from "../../model/Case";
import Charges from "../charges/Charges";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        color: theme.palette.text.secondary,
        padding: theme.spacing(2),
        height: '100%',
        boxSizing: 'border-box',
    },
}));

interface SummaryViewProps {
    maxHeight: number;
    caseObject: any;
    setCaseObject: any;
}

export const SummaryView = (props: SummaryViewProps) => {
    const classes = useStyles();
    const [showCaseDetailsForm, setShowCaseDetailsForm] = useState(false);
    const {showToast} = useSnackBar() as SnackBarProvider;

    const handleCaseUpdated = (formValues: Case) => {
        props.caseObject.pcn = formValues.pcn;
        props.caseObject.vehicleRegistration = formValues.vehicleRegistration;
        setShowCaseDetailsForm(false);
        showToast("Case details updated", "success");
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Paper className={classes.paper}>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle1">Case Summary {props.caseObject.verificationCode}</Typography>
                            <IconButton size="small" onClick={() => setShowCaseDetailsForm(true)}>
                                <Edit />
                            </IconButton>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <SummaryField label={"Case Submission Date:"} value={displayDateTime(props.caseObject.submissionDate, "-")} isDate={true}/>
                                <SummaryField label={"Verification Code:"} value={props.caseObject.verificationCode}/>
                                <SummaryField label={"Appellant:"} value={formatName(props.caseObject?.appellant?.firstName, props.caseObject?.appellant?.lastName)}/>
                                <SummaryField label={"Submitted on behalf:"} value={props.caseObject.submittedOnBehalf ? "Yes" : "No"}/>
                                <SummaryField label={"Motorist:"} value={formatName(props.caseObject?.motorist?.firstName, props.caseObject?.motorist?.lastName)}/>
                                <SummaryField label={"Source:"} value={caseSourceMapper(props.caseObject.caseSource)}/>
                            </Grid>
                            <Grid item xs={6}>
                                <SummaryField label={"Operator:"} value={props.caseObject.operatorName}/>
                                <SummaryField label={"Vehicle Registration:"} value={props.caseObject.vehicleRegistration} />
                                <SummaryField label={"PCN Number:"} value={props.caseObject.pcn}/>
                                <SummaryField label={"Target Decision Date:"} value={displayTargetDecisionDate(props.caseObject.submissionDate, "-")} isDate={true}/>
                                <SummaryField label={"Decision Date:"} value={displayDateTime(props.caseObject.decisionDate, "-")} isDate={true}/>
                                <AssessorSelector caseObject={props.caseObject} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <StatusView caseObject={props.caseObject} setCaseObject={props.setCaseObject} />
                </Grid>
                <Grid item xs={8}>
                    <AppellantDetails caseObject={props.caseObject} />
                </Grid>
                <Grid item xs={4}>
                    <WithdrawView caseObject={props.caseObject} setCaseObject={props.setCaseObject} />
                </Grid>
                <Grid item xs={12}>
                    <Charges caseObject={props.caseObject} />
                </Grid>
            </Grid>
            <EditCaseDetails
                open={showCaseDetailsForm}
                onClose={() => setShowCaseDetailsForm(false)}
                onSubmit={handleCaseUpdated}
                caseObject={props.caseObject}
                verificationCode={props.caseObject.verificationCode}
            />
        </>
    );
};

interface SnackBarProvider {
    // eslint-disable-next-line no-unused-vars
    showToast(m: string, t: string): void;
}