import React, {useContext} from "react";
import {SnackBarContext} from "./SnackBarContext";

/*
    Higher order component used to give a component the ability to show a toast message.

    Usage:
        const MyComponent = (props) => {
            ...
        }
        export default withSnackbar(MyComponent)
 */
const withSnackbar = (WrappedComponent) => {
    const HocComponent = (props) => {
        const {showToast} = useContext(SnackBarContext)
        return <WrappedComponent showToast={showToast} {...props} />
    }
    HocComponent.displayName = 'WithSnackBar'
    return HocComponent
}

export default withSnackbar