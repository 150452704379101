import {apiUrl} from "./apiEnv";
import {axios} from "./apiWrapper";
import {useEffect, useState} from "react";

function getOperatorUsers() {
    return axios.get(`${apiUrl}/security/users?user_filter=OPERATOR`)
}

export const useOperatorUsers = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [operatorUsers, setOperatorUsers] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        getOperatorUsers()
            .then((result) => {
                setOperatorUsers(result.data)
            })
            .catch(error => {
                setError(error.message)
            }).finally(() => {
                setIsLoading(false);
            });
    }, []);

    return {
        isLoading,
        operatorUsers,
        error
    };
}

export const addOperatorUser = async (operatorUser) => {
    operatorUser.userType = "OPERATOR";
    let result = { errorMessage: null, status: null };
    try {
        const res = await axios.post(`${apiUrl}/security/users`,
            operatorUser);
        result.status = res.status;
    } catch(err) {
        result.errorMessage = err.error;
    }
    return result;
};

export const updateOperatorUser = async (operatorUser) => {
    let result = { errorMessage: null, status: null };
    try {
        const res = await axios.put(`${apiUrl}/security/users?user_filter=OPERATOR`,
            operatorUser);
        result.status = res.status;
    } catch(err) {
        result.errorMessage = err.error;
    }
    return result;
};
