import React, {useState} from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl, FormControlLabel, FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Button
} from "@mui/material";
import {Add} from "@mui/icons-material";
import ValidInput from "../ValidInput";
import PropTypes from "prop-types";

const defaultFormValues = {
    type: 'GENERAL',
    note: ''
}

const defaultFormErrors = {
    type: null,
    note: null
}

export const AddNote = ({onAdd}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [formValues, setFormValues] = useState({...defaultFormValues})
    const [formErrors, setFormErrors] = useState({...defaultFormErrors})

    function handleClose() {
        setIsOpen(false)
    }

    function handleAddNote() {
        if (!validate()) {
            return;
        }

        let newNote = {
            type: formValues.type,
            note: formValues.note,
        }
        onAdd(newNote)
        setFormValues({...defaultFormValues})
        setIsOpen(false)
    }

    function handleInput(e) {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value})
    }

    function validate() {
        setFormErrors({...defaultFormErrors})

        if (formValues.note.trim().length === 0) {
            setFormErrors({...formErrors, note: 'This field is required'})
            return false
        }

        if (formValues.note.length > 2000) {
            setFormErrors({...formErrors, note: 'Maximum 2000 characters'})
            return false
        }

        if (formValues.note.match(/[<>]+/g)) {
            setFormErrors({...formErrors, note: 'Must not contain <> characters'})
            return false
        }

        return true
    }

    return (
        <div>
            <Button data-testid="add-note" variant="contained" startIcon={<Add />} onClick={() => setIsOpen(true)}>Add a note</Button>
            <Dialog open={isOpen} onClose={handleClose} fullWidth>
                <DialogTitle>Add new note</DialogTitle>
                <Box px={3}>
                    <FormControl>
                        <FormLabel>Type</FormLabel>
                        <RadioGroup aria-label="gender" row name="type" value={formValues.type} onChange={handleInput}>
                            <FormControlLabel value="GENERAL" control={<Radio />} label="General" />
                            <FormControlLabel value="CALL_LOG" control={<Radio />} label="Call log" />
                            <FormControlLabel value="CONFLICT_OF_INTEREST" control={<Radio />} label="Conflict of interest" />
                        </RadioGroup>
                        <FormHelperText error={!!formErrors.type}>{formErrors.type}</FormHelperText>
                    </FormControl>
                    <ValidInput
                        id={`note-note`}
                        name="note"
                        label="Note"
                        rows={7}
                        onChange={handleInput}
                        errorText={formErrors.note}
                        value={formValues.note}
                        required autoFocus multiline
                    />
                </Box>
                <Box px={2} pb={2}>
                    <DialogActions>
                        <Button data-testid="add-note-cancel" onClick={handleClose} color="default">Cancel</Button>
                        <Button data-testid="add-note-submit" onClick={handleAddNote}>Add</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}

AddNote.propTypes = {
    onAdd: PropTypes.func
}