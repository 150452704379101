import React from 'react';
import PropTypes from "prop-types";
import {Box, LinearProgress, Typography} from "@mui/material";

const LinearProgressWithLabel = (props) => {
    const percentage = Math.round(props.value);
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant={props.value > 0 ? 'determinate' : 'indeterminate'} {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${percentage}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel