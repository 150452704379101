import React, {useContext, useEffect, useState} from "react";
import {CaseFormContext} from "../CaseFormContext";
import ValidInput from "../../../components/ValidInput";
import PropTypes from "prop-types";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";

const AppealQuestion = ({question}) => {
    const {answers, setAnswers, questionErrors} = useContext(CaseFormContext)
    const [errorText, setErrorText] = useState('')

    useEffect(() => {
        if (questionErrors[question.questionCode] !== undefined) {
            setErrorText(questionErrors[question.questionCode])
        } else {
            setErrorText('')
        }
    }, [questionErrors, question.questionCode])

    function handleChange(e) {
        let {name, value, type} = e.target;
        if (type === 'checkbox') {
            value = e.target.checked
        }
        setAnswers({...answers, [name]: value})
    }

    function getQuestionType(questionCode) {
        const knownTypes = {
            "G1C1Q1": "textarea",
            "G1C1Q2": "date",
            "G2C1Q4": "checkbox",
            "G2C2Q1": "time",
            "G2C2Q2": "time",
            "G2C6Q1": "textarea",
            "G2C7Q1": "textarea",
            "G2C8Q1": "textarea",
            "G2C8Q2": "textarea",
            "G2C8Q3": "textarea",
            "G3C1Q2": "time",
            "G3C1Q4": "checkbox",
            "G3C2Q3": "textarea",
            "G4C1Q2": "datetime-local",
            "G4C1Q3": "date",
            "G4C1Q4": "checkbox",
            "G4C2Q1": "datetime-local",
            "G4C2Q2": "date",
            "G5C1Q1": "textarea",
            "G6C1Q1": "textarea",
            "G7C1Q1": "textarea",
            "G1C1E1": "evidence",
            "G2C1E1": "evidence",
            "G2C2E1": "evidence",
            "G2C3E1": "evidence",
            "G2C4E1": "evidence",
            "G2C5E1": "evidence",
            "G2C6E1": "evidence",
            "G2C7E1": "evidence",
            "G2C8E1": "evidence",
            "G2C9E1": "evidence",
            "G3C1E1": "evidence",
            "G3C2E1": "evidence",
            "G3C3E1": "evidence",
            "G4C1E1": "evidence",
            "G4C2E1": "evidence",
            "G4C3E1": "evidence",
            "G5C1E1": "evidence",
            "G6C1E1": "evidence",
            "G7C1E1": "evidence",
        }
        return knownTypes[questionCode] || "text";
    }

    let type = getQuestionType(question.questionCode);

    let multilineProps = {}
    if (type === "textarea") {
        multilineProps = {
            multiline: true,
            rows: 3
        }
    }

    const buildCheckbox = () =>
        <>
            <Typography variant="subtitle1">{question.question}</Typography>
            <FormControlLabel
                control={<Checkbox name={question.questionCode} color="primary"/>}
                label="Yes"
                labelPlacement="end"
                onChange={handleChange}
                data-testid={`question-${question.questionCode}`}
            />
        </>;

    const buildEvidence = () =>
        <ValidInput
            type="textarea"
            rows={2}
            data-testid={`question-${question.questionCode}`}
            disabled={true}
            name={question.questionCode}
            label={question.question}
            value="Please add any file uploads below"
        />;

    const buildInput = () =>
        <ValidInput
            {...multilineProps}
            type={type}
            data-testid={`question-${question.questionCode}`}
            name={question.questionCode}
            label={question.question}
            errorText={errorText}
            value={answers[question.questionCode] || ''}
            onInput={handleChange}
            required
        />;

    const createComponent = () => {
        switch (type) {
            case "checkbox":
                return buildCheckbox();
            case "evidence":
                return buildEvidence();
            default:
                return buildInput();
        }
    }

    return createComponent();
}

AppealQuestion.propTypes = {
    question: PropTypes.shape({
        questionCode: PropTypes.string,
        question: PropTypes.string,
    })
}

export default AppealQuestion