/* eslint-disable react/prop-types */
import React from "react";
import {FormControl, InputLabel, Select as MaterialSelect} from "@mui/material";

export default function Select({dataTestId, name, value, onChange, label, children}) {
    return (
        <div className="App-Input-TextBox">
            <FormControl fullWidth variant="filled">
                <InputLabel id={name + "-select-label"}>{label}</InputLabel>
                <MaterialSelect data-testid={dataTestId} labelId={name + "-select-label"} name={name} value={value} onChange={onChange}>
                    {children}
                </MaterialSelect>
            </FormControl>
        </div>
    )
}