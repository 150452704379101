import {Box, Grid, IconButton, Paper, Typography} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {SummaryField} from "./SummaryField";
import React, {useState} from "react";
import {Case, Contact} from "../../model/Case";
import {formatName} from "../../utils/stringUtils";
import EditDetails, {ContactType} from "./EditDetails";
import useSnackBar from "../SnackBarContext";

const AppellantDetails = (props: Props) => {
    const {caseObject} = props;
    const [appellantDetails, setAppellantDetails] = useState(caseObject.appellant);
    const [motoristDetails, setMotoristDetails] = useState(caseObject.motorist);
    const [showAppellantForm, setShowAppellantForm] = useState(false);
    const [showMotoristForm, setShowMotoristForm] = useState(false);
    const {showToast} = useSnackBar() as SnackBarProvider;

    const formatContactAddress = (contact: Contact | undefined): any => {
        if (!contact) {
            return "-";
        }

        const {addressLine1, addressLine2, addressCity, addressCounty, addressPostcode} = contact;
        const address = [addressLine1, addressLine2, addressCity, addressCounty, addressPostcode].filter(Boolean)

        if (!address.length) {
            return "-";
        }
        return (
            <span>
                {address.map((line, i) => <span key={i}>{line}{i < address.length-1 ? ',' : ''}<br/></span>)}
            </span>
        )
    }

    const handleAppellantUpdated = (formValues: Contact) => {
        setShowAppellantForm(false);
        setAppellantDetails(formValues);
        showToast("Appellant details updated", "success");
    }

    const handleMotoristUpdated = (formValues: Contact) => {
        setShowMotoristForm(false);
        setMotoristDetails(formValues);
        showToast("Motorist details updated", "success")
    }

    return (
        <Paper style={{height: '100%'}}>
            <Box p={2}>
                <Grid container spacing={3}>
                    <Grid item xs={motoristDetails ? 6 : 12}>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle1">Appellant details</Typography>
                            <IconButton size="small" onClick={() => setShowAppellantForm(true)}>
                                <Edit />
                            </IconButton>
                        </Box>
                        <SummaryField label={"Name:"} value={formatName(appellantDetails?.title, appellantDetails?.firstName, appellantDetails?.lastName)}/>
                        <SummaryField label={"Email:"} value={appellantDetails?.email ?? "-"}/>
                        <SummaryField label={"Phone:"} value={appellantDetails?.phone ?? "-"}/>
                        <SummaryField label={"Address:"} value={formatContactAddress(appellantDetails)}/>
                    </Grid>
                    {!!motoristDetails && (
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="subtitle1">Motorist details</Typography>
                                <IconButton size="small" onClick={() => setShowMotoristForm(true)}>
                                    <Edit />
                                </IconButton>
                            </Box>
                            <SummaryField label={"Name:"} value={formatName(motoristDetails.title, motoristDetails.firstName, motoristDetails.lastName)}/>
                            <SummaryField label={"Email:"} value={motoristDetails.email ?? "-"}/>
                            <SummaryField label={"Phone:"} value={motoristDetails.phone ?? "-"}/>
                            <SummaryField label={"Address:"} value={formatContactAddress(motoristDetails)}/>
                        </Grid>
                    )}
                </Grid>
                <EditDetails
                    open={showAppellantForm}
                    onClose={() => setShowAppellantForm(false)}
                    onSubmit={handleAppellantUpdated}
                    contact={appellantDetails}
                    verificationCode={caseObject.verificationCode}
                    type={ContactType.Appellant}
                />
                {!!motoristDetails && (
                    <EditDetails
                        open={showMotoristForm}
                        onClose={() => setShowMotoristForm(false)}
                        onSubmit={handleMotoristUpdated}
                        contact={motoristDetails}
                        verificationCode={caseObject.verificationCode}
                        type={ContactType.Motorist}
                    />
                )}
            </Box>
        </Paper>
    )
}

interface Props {
    caseObject: Case
}

interface SnackBarProvider {
    // eslint-disable-next-line no-unused-vars
    showToast(m: string, t: string): void;
}

export default AppellantDetails