import React, {useState} from 'react';
import {Breadcrumbs, Box, Link as MaterialLink, Typography, Grid, Button} from "@mui/material";
import {Link as ReactLink, useHistory} from "react-router-dom";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {AddBox, People} from "@mui/icons-material";
import {useOperators} from "../api/operatorApi.js";
import {operatorStatusMapper} from "../utils/operatorStatusMapper";
import {hasAuthority, hasSomeAuthorities} from "../auth/AuthorityHelper";

const accountStatus = (params) => operatorStatusMapper(params.row.portalAccountStatus || '')

const columns = [
    {field: "operatorCode", headerName: "Operator Code", width: 155},
    {field: "email", headerName: "Email", flex: 1},
    {field: "name", headerName: "Name", flex: 1},
    {field: "phone", headerName: "Phone", flex: 1},
    {field: "portalAccountStatus", headerName: "Account Status", flex: 1, valueGetter: accountStatus},
    {field: "url", headerName: "URL", flex: 1},
    {field: "country", headerName: "Country", width: 110},
]

const OperatorsPage = () => {
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const { isLoading, operators } = useOperators(pageNum, pageSize);
    const history = useHistory();

    const handleRowClick = (params) => {
        history.push({
            pathname: `/operators/edit`,
            state: { operator: params.row },
        })
    }

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '28px'}}>
                <MaterialLink component={ReactLink} to="/" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <Typography color="textPrimary">Operator Management</Typography>
            </Breadcrumbs>
            <Box>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h1" gutterBottom>Operator Management</Typography>
                    </Grid>
                    <Grid item>
                        { hasSomeAuthorities('CREATE_OPERATOR_USER',
                            'EDIT_OPERATOR_USER',
                            'VIEW_OPERATOR_USER') && (<Button data-testid="operator-user-management" startIcon={<People />} style={{marginRight: 16}}  component={ReactLink} operators={operators} to="/operators/users">View Operator Users</Button>)}
                        { hasAuthority('CREATE_OPERATOR') && (<Button data-testid="add-operator" startIcon={<AddBox />} component={ReactLink} to="/operators/create">Add Operator</Button>) }
                    </Grid>
                </Grid>
                <DataGridPro
                    rows={operators?.content || []}
                    columns={columns}
                    loading={isLoading}
                    pageSize={pageSize}
                    getRowId={(row => row.operatorCode)}
                    rowCount={operators?.totalElements || 0}
                    page={pageNum}
                    pagination
                    paginationMode="server"
                    autoHeight
                    disableColumnMenu
                    disableSelectionOnClick
                    onPageChange={setPageNum}
                    onPageSizeChange={setPageSize}
                    rowsPerPageOptions={[50,100,250,500]}
                    onRowClick={handleRowClick}
                />
            </Box>
        </div>
    );
}

OperatorsPage.protoTypes = {

}

export default OperatorsPage