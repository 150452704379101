import React, {useContext, useEffect, useState, useRef, useCallback} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import Clarification from "./Clarification";
import AppealQuestions from "./AppealQuestions";
import PropTypes from "prop-types";
import {CaseFormContext} from "../CaseFormContext";
import {evidenceQnCodeRegex} from "./QuestionConstants";

const AppealGrounds = ({grounds, disabled, mandatory, onChange}) => {
    const hasAddedGrounds = useRef(false);
    const {answers, setAnswers} = useContext(CaseFormContext)
    const checkboxRef = useRef()
    const [showOptions, setShowOptions] = useState(() => {
        // If answers are added at a higher level, show any question that have answers
        const result = Object.keys(answers).some(key => {
            // Check if any answers (e.g. G7C1Q1) start with the groundsCode (e.g. G7)
            return key.substring(0, grounds.groundsCode.length) === grounds.groundsCode
        })
        return result
    })

    function handleCheckbox(e) {
        if (disabled || mandatory) {
            return false;
        }

        const {checked} = e.target;
        setShowOptions(checked)
        if (checked && grounds.clarifications.length === 1) {
            addGroundsAnswers();
        }

        if (!checked) {
            removeGroundsAnswers()
        }

        onChange(e)
    }

    const addGroundsAnswers = useCallback(() => {
        grounds.clarifications[0].questions
            .filter(question => !question.questionCode.match(evidenceQnCodeRegex))
            .forEach(question => {
                const answer = answers[question.questionCode] || ''
                setAnswers({...answers, [question.questionCode]: answer})
            });
    }, [answers, grounds.clarifications, setAnswers])

    const removeGroundsAnswers = useCallback(() => {
        let disabledQuestions = [];
        grounds.clarifications.forEach(c => {
            disabledQuestions = disabledQuestions.concat(...c.questions)
        })

        setAnswers(prevAnswers => {
            let updatedAnswers = {...prevAnswers}
            disabledQuestions.forEach(answer => {
                delete updatedAnswers[answer.questionCode]
            })
            return updatedAnswers
        })
    }, [grounds.clarifications, setAnswers])

    // Add answers if the grounds are mandatory
    useEffect(() => {
        if (mandatory && !hasAddedGrounds.current) {
            addGroundsAnswers();
            setShowOptions(true);
            hasAddedGrounds.current = true;
        }
    }, [mandatory, addGroundsAnswers]);

    // Remove answers if the grounds are disabled
    useEffect(() => {
        if (!mandatory && disabled && showOptions) {
            setShowOptions(false)
            removeGroundsAnswers()
        }
    }, [mandatory, disabled, showOptions, removeGroundsAnswers])

    return (
        <div>
            <FormControlLabel
                data-testid={`grounds-checkbox-${grounds.groundsCode}`}
                disabled={disabled}
                checked={showOptions}
                control={<Checkbox name={grounds.groundsCode} color="primary" style={{visibility: mandatory ? 'hidden' : ''}}/>}
                label={grounds.grounds}
                labelPlacement="end"
                ref={checkboxRef}
                onChange={handleCheckbox}
            />
            {showOptions && (<div>
                {grounds.clarifications.length === 1 && <AppealQuestions questions={grounds.clarifications[0].questions} />}
                {grounds.clarifications.length > 1 && grounds.clarifications.map(c => (
                    <Clarification key={c.clarificationCode} clarification={c} />
                ))}
            </div>)}
        </div>
    )
}

AppealGrounds.propTypes = {
    disabled: PropTypes.bool,
    mandatory: PropTypes.bool,
    onChange: PropTypes.func,
    grounds: PropTypes.shape({
        grounds: PropTypes.string,
        groundsCode: PropTypes.string,
        clarifications: PropTypes.arrayOf(PropTypes.shape({
            clarificationCode: PropTypes.string,
            clarification: PropTypes.string,
            questions: PropTypes.arrayOf(PropTypes.shape({
            questionCode: PropTypes.string,
                question: PropTypes.string,
            }))
        }))
    }),
}

export default AppealGrounds