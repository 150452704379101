/**
 * Global flags to hide/show individual features.
 *
 * Override locally by adding a local storage item 'lumin-feature-flag-<<flagName>>' with value 'true' or 'false'
 */
export const featureFlags = {
    'assessor-prepopulated-responses':      true,
    'compose-email-operator':               true,
    'withdrawal-reason-selection':          true,
    'case-assessment-expandable-inputs':    true,
    'case-download-all-documents':          true,
    'user-management':                      true
}
const localStoragePrefix = 'lumin-feature-flag-';
/**
 * Load an override value of the flag from local storage, which can be used for local testing
 * or running integration tests on hidden features.
 */
const getLocalStorageFlagValue = flagName => {
    try {
        const flag = window.localStorage.getItem(`${localStoragePrefix}${flagName}`)
        if (flag === 'true') {
            return true
        } else if (flag === 'false') {
            return false
        }
    } catch {
        // Ignore any localStorage issues
    }
    return undefined;
}
/**
 * Calculate if a feature is enabled. Any valid local storage value takes precedence
 * over the flag setting specified above.
 */
const featureIsEnabled = flagName => {
    const flag = featureFlags[flagName];
    if (flag == null) {
        throw new Error(`No feature flag named ${flagName}`)
    }
    return getLocalStorageFlagValue(flagName) ?? flag;
}
export default featureIsEnabled;
/**
 * Set a feature flag in localStorage (for use in tests)
 */
export const setFeatureFlag = (flagName, value) => {
    featureFlags.__defaults = featureFlags.__defaults ?? {...featureFlags}
    featureFlags[flagName] = value
}

export const resetFeatures = () => {
    if (!featureFlags.__defaults) {
        return
    }
    // Clear all flags (except __defaults)
    Object.keys(featureFlags).filter(k => k !== '__defaults').forEach(key => delete featureFlags[key])
    // Restore from defaults
    Object.entries(featureFlags.__defaults).forEach(([key, val]) => {
        featureFlags[key] = val
    })
    // Remove defaults
    delete featureFlags.__defaults
}