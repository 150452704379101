const axios = require('axios');

export const apiURL = "https://dvlasearch.appspot.com"

export function dvlaSearch(licencePlate) {
    return axios.get(`${apiURL}/DvlaSearch`, {
        params: {
            apikey: process.env.REACT_APP_DVLA_API_KEY,
            licencePlate
        }
    })
}