import React, {useState} from 'react'
import FileAttachmentFiles from "../../components/FileAttachmentFiles";
import FileAttachment from "../../components/FileAttachment";
import PropTypes from "prop-types";
import {Typography} from "@mui/material";

const FileAttachments = ({onFilesChanged, heading, disabled = false}) => {
    const [files, setFiles] = useState([])

    function handleFileUpload(name, values) {
        const updatedFiles = [...files, {name, values}]
        setFiles(updatedFiles)
        onFilesChanged(updatedFiles);
    }

    function removeFile(index) {
        const updatedFiles = files.filter((f, i) => i !== index)
        setFiles(updatedFiles)
        onFilesChanged(updatedFiles);
    }

    return (
        <div>
            <Typography variant="h5" gutterBottom>{heading}</Typography>
            <FileAttachment name="files" onUpload={handleFileUpload} label="Please upload any evidence to support appeal here." disabled={disabled}>
                <FileAttachmentFiles files={files} onRemove={removeFile} />
            </FileAttachment>
        </div>
    )
}

FileAttachments.propTypes = {
    onFilesChanged: PropTypes.func,
    heading: PropTypes.string,
    disabled: PropTypes.bool
}

export default FileAttachments