import {axios} from "./apiWrapper";
import {apiUrl} from "./apiEnv";
import {AxiosError, AxiosResponse} from "axios";
import {useEffect, useState} from "react";

export const getCharges = (verificationCode: string): Promise<Charge[]> => {
    return axios
        .get(`${apiUrl}/cases/${verificationCode}/charges`)
        .then((res: AxiosResponse<Charge[]>) => res.data)
}

export const useCharges = (verificationCode: string, token: string) => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState<Charge[]>([])
    const [error, setError] = useState(null);
    const [refreshToken, setRefreshToken] = useState(1);

    const refresh = () => {
        setRefreshToken(token => token + 1)
    }

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);

        getCharges(verificationCode)
            .then((data) => {
                isMounted && setData(data)
            })
            .catch(error => {
                isMounted && setError(handleAxiosError(error))
            })
            .finally(() => {
                isMounted && setIsLoading(false)
            })

        return () => {
            isMounted = false;
        }
    }, [verificationCode, token, refreshToken])

    return {
        isLoading,
        data,
        error,
        refresh
    }
}

function handleAxiosError(error: AxiosError) {
    if (error.response?.data) {
        return error.response.data
    }
    return error.message
}

export const addCharge = (verificationCode: string, charge: AddCharge) => {
    return axios
        .post(`${apiUrl}/cases/${verificationCode}/charges`, charge)
        .then(res => res.data)
}

export interface Charge {
    id: number
    chargeType: string
    createdAt: string
}

export interface AddCharge {
    chargeType: string
}