import { useState} from "react";
import {storageType, useStorage} from "../store";
import {UserToken} from "../model/User";

export const useUser = () => {
    const [rawUser] = useStorage(storageType.SESSION, 'user');
    const [isLoading, ] = useState(false);

    const user = rawUser as unknown as UserToken;
    return { isLoading, user };
}
