import * as yup from "yup";

export const schema = yup.object().shape({
    operatorCode: yup.string().matches(/[0-9]{3}/, "${path} must be a valid 3-digit number").required().max(3).label('Operator Code'),
    email: yup.string().required().email().max(100).label('Email'),
    name: yup.string().required().max(100).label('Name'),
    phone: yup.string().nullable().max(50).label('Phone'),
    portalAccountStatus: yup.string().required().max(32).label('Account Status'),
    url: yup.string().required().max(100).label('URL'),
    country: yup.string().required().max(5).label('Country'),
})

export function validate(formValues, schema) {
    let errors = {}
    schema._nodes.forEach((node) => {
        try {
            schema.validateSyncAt(node, formValues)
        } catch (err) {
            errors[node] = err.message
        }
    })
    return errors
}