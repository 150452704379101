import {apiUrl} from "./apiEnv";
import {axios} from "./apiWrapper";
import {getDraftCases} from "./draftApi"
import {useEffect, useState} from "react";
import featureIsEnabled from "../utils/featureFlag";

export const getCase = (verificationCode) => {
    return axios.get(`${apiUrl}/cases/${verificationCode}`)
}

export const useCase = (verificationCode) => {
    const [isLoading, setIsLoading] = useState(true);
    const [caseObject, setCaseObject] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        getCase(verificationCode).then((result) => {
            setCaseObject(result.data)
        }).catch(error => {
            setError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });

    }, [verificationCode]);

    return {
        isLoading,
        error,
        caseObject,
        setCaseObject
    };
}

export const getCases = (page, size, assessor_cases, searchParams = [], sortValue = 'submissionDate,desc', allClosedCases) => {

    const queryParams = [
        {page},
        {size},
        {assessor_cases},
        {closed_cases_past_days: allClosedCases? 0: 14},
        {sort: sortValue},
        ...searchParams
    ]

    const params = new URLSearchParams()
    queryParams.forEach(param => {
        Object.entries(param).forEach(([key, value])=> {
            params.append(key, value)
        })
    })

    return axios.get(`${apiUrl}/cases`, {params})

}

export const getAllCases = async (assessor_cases, draft_cases, searchParams, totalRows, rowLimit, updateProgress, sortValue, allClosedCases) => {
    const perPage = Math.min(totalRows, rowLimit);
    let pages = Math.ceil(totalRows / perPage);
    let requests = [];

    const getItems = draft_cases ? getDraftCases : getCases
    let progressIncrement = 100 / pages;
    let progress = 0;
    for (let i = 0; i < pages; i++) {
        requests.push(() => getItems(i, perPage, assessor_cases, searchParams, sortValue, allClosedCases))
    }

    let taskNo = 0;
    let results = [];
    async function runNextTask() {
        const task = requests[taskNo];
        await task()
            .then(async (result) => {
                results.push(result)
            })
            .catch(() => {}); // Should we try here?
        progress = Math.round(progress + progressIncrement)
        if (typeof updateProgress == "function") {
            updateProgress(progress)
        }
        if (taskNo < requests.length-1) {
            taskNo++;
            await runNextTask();
        }
    }

    await runNextTask();

    return results.reduce((prev, current) => prev.concat(current.data.content), [])
}

export const useCases = (page, size, assessor_cases, draft_cases,
                         searchParams, refreshKey, sortValue, allClosedCases) => {
    const [isLoading, setIsLoading] = useState(true);
    const [cases, setCases] = useState(null);
    const [error, setError] = useState(null);
    const getItems = draft_cases ? getDraftCases : getCases
    const stringifiedParams = JSON.stringify(searchParams)

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true)

        getItems(page, size, assessor_cases, searchParams, sortValue, allClosedCases).then(res => {
            isMounted && setCases(res.data)
        }).catch((err) => {
            isMounted && setError(err)
        }).finally(() => {
            isMounted && setIsLoading(false)
        })

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },  [page, size, assessor_cases, draft_cases, stringifiedParams,
                        refreshKey, sortValue, getItems, allClosedCases]);

    return {
        isLoading,
        error,
        cases,
    };
}

export const useGroundsForAppeal = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [grounds, setGrounds] = useState(null)
    const [error, setError] = useState(null);

    const sortGroundsData = (groundsData) => ({
        grounds: groundsData.grounds.sort((a, b) => a.groundsCode < b.groundsCode ? -1 : a.groundsCode > b.groundsCode ? 1 : 0)
    });

    useEffect(() => {
        let isMounted = true;
        axios.get(`${apiUrl}/questions`
        ).then((result) => {
            isMounted && setGrounds(result.data)
            isMounted && setGrounds(sortGroundsData(result.data))
        }).catch(e => {
            isMounted && setError(e.message)
        }).finally(() => {
            isMounted && setIsLoading(false);
        });

        return () => isMounted = false;
    }, [])

    return {
        isLoading,
        grounds,
        error
    }
}

export const submitCaseDecision = async (verificationCode, assessorDecision) => {
    let errorStatus = {errorMessage: null};
    await axios.put(`${apiUrl}/cases/${verificationCode}/decision`,
        assessorDecision,
    ).catch(error => {
        if (typeof error === "string" && error !== '') {
            errorStatus.errorMessage = error
        } else if (error?.message) {
            errorStatus.errorMessage = error.message
        } else {
            errorStatus.errorMessage = 'an unknown error occurred'
        }
    });

    return errorStatus;
}

export const createCase = async (createCaseModel) => {
    return await axios.post(`${apiUrl}/cases`, createCaseModel)
        .then((response) => response.data);
};

export const submitCaseState = async (verificationCode, status, statusReason, condition) => {
    let errorStatus = {errorMessage: null};
    const caseState = {'status': `${status}`, 'statusReason': statusReason, 'condition' : `${condition}`};

    await axios.put(`${apiUrl}/cases/${verificationCode}/state`,
        caseState
    ).catch(error => {
        if (typeof error === "string" && error !== '') {
            errorStatus.errorMessage = error
        } else if (error?.message) {
            errorStatus.errorMessage = error.message
        } else {
            errorStatus.errorMessage = 'an unknown error occurred'
        }
    });

    return errorStatus;
}

export const submitCaseWithdraw = async (verificationCode, reasonForWithdraw, withdrawnBy, withdrawReasonCode) => {
    let errorStatus = {errorMessage: null};
    const caseState = {reasonForWithdraw, withdrawnBy};
    if (featureIsEnabled('withdrawal-reason-selection')) {
        caseState.withdrawReasonCode = withdrawReasonCode
    }

    await axios.post(`${apiUrl}/cases/${verificationCode}/withdraw`,
        caseState
    ).catch(error => {
        if (typeof error === "string" && error !== '') {
            errorStatus.errorMessage = error
        } else if (error?.error) {
            errorStatus.errorMessage = error.error
        } else {
            errorStatus.errorMessage = 'an unknown error occurred'
        }
    });

    return errorStatus;
}

export const useCaseHistory = (verificationCode) => {
    const [isLoading, setIsLoading] = useState(true)
    const [history, setHistory] = useState([])
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        axios.get(`${apiUrl}/cases/${verificationCode}/history`
        ).then((result) => {
            isMounted && setHistory(result.data)
        }).catch(e => {
            isMounted && setError(e.message)
        }).finally(() => {
            isMounted && setIsLoading(false);
        });

        return () => isMounted = false;
    }, [verificationCode])

    return {
        isLoading,
        history,
        error
    }
}

export const reassignCase = async (verificationCode, userId) => {
    const payload = {
        action: "REASSIGN_CASE",
        verificationCode: verificationCode,
        assessor: userId
    }
    return axios.post(`${apiUrl}/actions`, payload)
}

export const unassignCase = async (verificationCode) => {
    const payload = {
        action: "UNASSIGN_CASE",
        verificationCode: verificationCode,
    }
    return axios.post(`${apiUrl}/actions`, payload);
}

export const getNextCase = async () => {
    let result = {verificationCode: null, errorMessage: null};
    const action = {'action': 'ASSIGN_NEXT_AVAILABLE_CASE'};

    await axios.post(`${apiUrl}/actions`,
        action
    ).then(response => result.verificationCode = response.data.verificationCode
    ).catch(error => {
        if (typeof error === "string" && error !== '') {
            result.errorMessage = error;
        } else if (error?.error) {
            result.errorMessage = error.error;
        } else if (error?.status && error?.status === 409) {
            result.errorMessage = "Cannot assign case at this time, please try again.";
        } else {
            result.errorMessage = 'Unable to get next case.';
        }
    });
    return result;
}

export const updateAppellantDetails = async (verificationCode, payload) => {
    return axios.patch(`${apiUrl}/cases/${verificationCode}/appellant`, payload)
}

export const updateMotoristDetails = async (verificationCode, payload) => {
    return axios.patch(`${apiUrl}/cases/${verificationCode}/motorist`, payload)
}

export const updateCaseDetails = async (verificationCode, payload) => {
    return axios.patch(`${apiUrl}/cases/${verificationCode}`, payload)
}

export const getWithdrawalReasons = async (userType) => {
    return axios.get(`/cases/withdrawalReasons?userType=` + userType).then(res => res.data)
}

export const useWithdrawalReasons = (userType) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [reasons, setReasons] = useState([]);
    const [refreshToken, setRefreshToken] = useState(1);

    useEffect(() => {
        let isMounted = true;
        setLoading(true)

        getWithdrawalReasons(userType)
            .then(data => {
                isMounted && setReasons(data)
            })
            .catch(err => {
                isMounted && setError(err)
            })
            .finally(() => {
                isMounted && setLoading(false)
            })

        return () => isMounted = false;
    }, [refreshToken, userType])

    function refetch() {
        setRefreshToken(t => t + 1)
    }

    return {
        loading, error, reasons, refetch
    }
}