import Typography from "@mui/material/Typography";
import {Autocomplete, Box, FormHelperText, FormLabel, TextField} from '@mui/material';
import {AccountCircle} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {getUsers} from "../../api/securityApi";
import {reassignCase, unassignCase} from "../../api/caseApi";
import makeStyles from '@mui/styles/makeStyles';
import {Case} from '../../model/Case'
import {User} from '../../model/User'
import withSnackbar from "../WithSnackbar";
import {useConfirmation} from "../ConfirmDialog";

const useStyles = makeStyles({
    IndicatorOpen: {
        transform: 'none'
    },
    autocomplete: {
        marginTop: -2
    }
})

const unassigned: User = {
    id: 0,
    email: "",
    userType: "",
    roles: "",
    name: "Unassigned",
    disabled: 0
}

const AssessorSelector = (props: Props) => {
    const {caseObject, showToast} = props
    const [users, setUsers] = useState<User[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>("")
    const [value, setValue] = useState<User | undefined>(undefined)
    const [inputValue, setInputValue] = useState<string>("")
    const confirm = useConfirmation()
    const classes = useStyles();

    const handleChange = async (e: any, newUser: User) => {
        if (newUser.id === 0) {
            await unassignUserFromCase()
        } else {
            await assignUserToCase(newUser)
        }
    }

    const assignUserToCase = async (newUser: User) => {
        const confirmed = await confirm("Confirm change of assessor", `Are you sure you want to reassign this case to ${newUser.name}?`, "Reassign")
        if (confirmed) {
            reassignCase(caseObject.verificationCode, newUser.id)
                .then(() => {
                    setValue(newUser)
                    showToast("Case assigned to " + newUser.name)
                })
                .catch(() => {
                    showToast("Could not reassign case, please try again later", "error")
                })
        }
    }

    const unassignUserFromCase = async () => {
        const confirmed = await confirm("Confirm unassignment", `Are you sure you want to unassign this case?`, "Unassign")
        if (confirmed) {
            unassignCase(caseObject.verificationCode)
                .then(() => {
                    setValue(unassigned)
                    showToast("Case unassigned")
                })
                .catch(() => {
                    showToast("Could not unassign case, please try again later", "error")
                })
        }
    }

    const handleInputChange = (_e: any, value: string) => {
        setInputValue(value)
    }

    useEffect(() => {
        setLoading(true);

        getUsers(true)
            .then((usersResponse: User[]) => {
                setUsers([unassigned, ...usersResponse])
                const assigned = usersResponse.find(u => u.id === caseObject.assessorId)
                if (assigned) {
                    setValue(assigned)
                    setInputValue(assigned.name)
                } else {
                    setValue(unassigned)
                }
            })
            .catch((error: string) => {
                setError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [caseObject.assessorId])

    return (
        <Box py={1}>
            <FormLabel>
                <Typography variant="subtitle2">Assessor:</Typography>
                {!loading && (
                    <Autocomplete
                        fullWidth
                        disableClearable
                        loading={loading}
                        popupIcon={<AccountCircle color="primary"/>}
                        className={classes.autocomplete}
                        classes={{popupIndicatorOpen: classes.IndicatorOpen}}
                        data-testid="assign-assessor"
                        options={users}
                        value={value}
                        inputValue={inputValue}
                        getOptionLabel={(option) => option.email ? `${option.name} (${option.email})` : option.name }
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isOptionEqualToValue={(option: User, value: User) => option.id === value.id || value.id === 0}
                        renderInput={(params) => <TextField {...params} variant="standard" placeholder="-"/>}
                    />
                )}
            </FormLabel>
            {!!error && <FormHelperText error={true}>{error}</FormHelperText>}
        </Box>
    );
}

export default withSnackbar(AssessorSelector)

interface Props {
    caseObject: Case
    showToast: any
}

