import React from "react";
import PropTypes from "prop-types";

function AttachmentLink({filename, url}) {
    return (
        <span style={{paddingLeft: 5, paddingRight: 5}}>
            <a href={url} target="_blank" rel="noreferrer">{filename}</a>
        </span>
    )
}

AttachmentLink.propTypes = {
    filename: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
}

export default AttachmentLink