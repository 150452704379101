import {axios} from "./apiWrapper";
import {apiUrl} from "./apiEnv";
import {useEffect, useState} from "react";
import {CaseNote} from "../model/CaseNote";
import {AxiosResponse, AxiosError} from "axios";

export const useNotes = (foreignKey: string, refreshKey: string, type: string = NoteTarget.case) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [notes, setNotes] = useState<CaseNote[]>([])
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (foreignKey) {
            setIsLoading(true);
            axios.get(`${apiUrl}/${type}/${foreignKey}/notes`
            ).then((result: AxiosResponse<any>) => {
                setNotes(result.data)
            }).catch((error: AxiosError) => {
                setError(error.message)
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [foreignKey, refreshKey, type]);

    return {
        isLoading,
        notes,
        error
    }
}

export const deleteNotes = async (foreignKey: string, noteIDs: string[], type: string = NoteTarget.case) => {
    const requests = noteIDs.map(id => axios.delete(`${apiUrl}/${type}/${foreignKey}/notes/${id}`))
    return Promise.allSettled(requests)
}

export const addNote = async (foreignKey: string, note: CaseNote, type: string = NoteTarget.case) => {
    return axios.post(`${apiUrl}/${type}/${foreignKey}/notes`, note)
}

export const NoteTarget = {
    case: 'cases',
    draft: 'drafts'
}