import React, {useContext, useEffect} from 'react';
import {
    Box,
    FormControl,
    FormControlLabel, FormHelperText,
    FormLabel,
    Paper,
    Radio,
    RadioGroup,
    Button
} from "@mui/material";
import ValidInput from "../../components/ValidInput";
import {OperatorFormContext} from "./OperatorFormContext"
import { useHistory } from 'react-router-dom';
import {AddBox, ArrowBack, Save} from "@mui/icons-material";
import PropTypes from "prop-types";
import {formModes} from "../users/CreateOperatorUserPage/OperatorUserFormContext";
import {hasSomeAuthorities} from "../../auth/AuthorityHelper";

const CreateOperatorForm = ({operator}) => {
    const history = useHistory();
    const {
        formValues,
        setFormValues,
        formErrors,
        formMode,
        setFormMode,
        handleInput,
        handleSubmit
    } = useContext(OperatorFormContext)

    useEffect(() => {
        if (operator) {
            setFormMode(formModes.EDIT)
            setFormValues({
                operatorCode: operator.operatorCode ?? '',
                email: operator.email ?? '',
                name: operator.name ?? '',
                phone: operator.phone ?? '',
                portalAccountStatus: operator.portalAccountStatus ?? '',
                primaryContactId: operator.primaryContactId ?? '',
                url: operator.url ?? '',
                country: operator.country ?? '',
            })
        }
    }, [operator, setFormMode, setFormValues])

    return (
        <Paper elevation={3}>
            <Box p={2}>
                <ValidInput
                    id="operatorCode"
                    label="Operator Code"
                    name="operatorCode"
                    data-testid="operatorCode"
                    value={formValues.operatorCode}
                    onChange={handleInput}
                    errorText={formErrors.operatorCode}
                    inputProps={{maxLength: 3}}
                    disabled={formMode === formModes.EDIT}
                    required
                />
                <ValidInput
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                    value={formValues.email}
                    onChange={handleInput}
                    errorText={formErrors.email}
                    inputProps={{maxLength: 100}}
                    disabled={ !hasSomeAuthorities('CREATE_OPERATOR', 'EDIT_OPERATOR') }
                    required
                />
                <ValidInput
                    id="name"
                    label="Name"
                    name="name"
                    value={formValues.name}
                    onChange={handleInput}
                    errorText={formErrors.name}
                    inputProps={{maxLength: 100}}
                    disabled={ !hasSomeAuthorities('CREATE_OPERATOR', 'EDIT_OPERATOR') }
                    required
                />
                <ValidInput
                    id="phone"
                    label="Phone"
                    name="phone"
                    value={formValues.phone}
                    onChange={handleInput}
                    errorText={formErrors.phone}
                    inputProps={{maxLength: 50}}
                    disabled={ !hasSomeAuthorities('CREATE_OPERATOR', 'EDIT_OPERATOR') }
                />
                <ValidInput
                    id="url"
                    label="URL"
                    name="url"
                    value={formValues.url}
                    onChange={handleInput}
                    errorText={formErrors.url}
                    inputProps={{maxLength: 100}}
                    disabled={ !hasSomeAuthorities('CREATE_OPERATOR', 'EDIT_OPERATOR') }
                    required
                />
                <Box pb={2}>
                    <FormControl fullWidth error={!!formErrors.portalAccountStatus}
                                 disabled={ !hasSomeAuthorities('CREATE_OPERATOR', 'EDIT_OPERATOR') }>
                        <FormLabel>Account Status</FormLabel>
                        <RadioGroup row name="portalAccountStatus" value={formValues.portalAccountStatus} onChange={handleInput}>
                            <FormControlLabel value="CREATED" control={<Radio />} label="Active" />
                            <FormControlLabel value="NOT_CREATED" control={<Radio />} label="Disabled" />
                        </RadioGroup>
                        {!!formErrors.portalAccountStatus && <FormHelperText>{formErrors.portalAccountStatus}</FormHelperText>}
                    </FormControl>
                </Box>
                <Box pb={2}>
                    <FormControl fullWidth error={!!formErrors.country}
                                 disabled={ !hasSomeAuthorities('CREATE_OPERATOR', 'EDIT_OPERATOR') }>
                        <FormLabel>Country</FormLabel>
                        <RadioGroup row name="country" value={formValues.country} onChange={handleInput}>
                            <FormControlLabel value="EW" control={<Radio />} label="England and Wales" />
                            <FormControlLabel value="SCO" control={<Radio />} label="Scotland" />
                            <FormControlLabel value="NI" control={<Radio />} label="Northern Ireland" />
                        </RadioGroup>
                        {!!formErrors.country && <FormHelperText>{formErrors.country}</FormHelperText>}
                    </FormControl>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Button data-testid="back-button" startIcon={<ArrowBack />} onClick={() => history.goBack()}>Back</Button>
                    {(formMode === formModes.EDIT && hasSomeAuthorities('CREATE_OPERATOR', 'EDIT_OPERATOR')) && <Button data-testid="submit-button" startIcon={<Save />} onClick={handleSubmit}>Save Operator</Button>}
                    {(formMode === formModes.CREATE && hasSomeAuthorities('CREATE_OPERATOR', 'EDIT_OPERATOR')) && <Button data-testid="submit-button" startIcon={<AddBox />} onClick={handleSubmit}>Add Operator</Button>}
                </Box>
            </Box>
        </Paper>
    )
}

CreateOperatorForm.propTypes = {
    operator: PropTypes.object
}

export default CreateOperatorForm