export const displayDate = (dateToDisplay) => {
    if (dateToDisplay) {
        return new Intl.DateTimeFormat('en-GB', {day: '2-digit', month: '2-digit',  year: 'numeric'})
            .format(new Date(dateToDisplay));
    } else {
        return "";
    }
};

export const displayDateTime = (dateToDisplay, defaultValue) => {
    if (dateToDisplay) {
        return new Intl.DateTimeFormat('en-GB', {day: '2-digit', month: '2-digit',  year: 'numeric', hour: '2-digit', minute : '2-digit'})
            .format(new Date(dateToDisplay));
    } else {
        return defaultValue;
    }
};

export const displayTargetDecisionDate = (submissionDate, defaultValue) => {
    if (submissionDate) {
        let targetDate = new Date(submissionDate);
        targetDate.setDate(targetDate.getDate() + 55);
        return displayDate(targetDate, defaultValue);
    } else {
        return defaultValue;
    }
};

export const displayDate12HourFormat = (dateToDisplay) => {
    if (dateToDisplay == null) {
        return "-";
    } else {
        return new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        })
            .format(new Date(dateToDisplay));
    }
}

export const isoDate = (dateToDisplay) => {
    if (dateToDisplay) {
        const dateString = new Date(dateToDisplay).toISOString()
        return dateString.substring(0, 10)
    } else {
        return "";
    }
};
