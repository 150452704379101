import React from 'react';
import {Breadcrumbs, Container, Link as MaterialLink, Typography} from "@mui/material";
import {Link as ReactLink, useHistory} from "react-router-dom";
import CreateOperatorForm from "./CreateOperatorForm"
import {OperatorFormProvider} from "./OperatorFormContext";

const CreateOperatorPage = () => {
    const history = useHistory();
    const operator = history?.location?.state?.operator;

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '28px'}}>
                <MaterialLink component={ReactLink} to="/" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <MaterialLink component={ReactLink} to="/operators" className={"App-Breadcrumb-Link"}>
                    <u>Operator Management</u>
                </MaterialLink>
                <Typography color="textPrimary">{operator ? 'Edit' : 'Add'} Operator</Typography>
            </Breadcrumbs>
            <Container>
                <Typography variant="h1" gutterBottom>
                    {operator ? '' : 'New'} Operator Details
                </Typography>
                <OperatorFormProvider>
                    <CreateOperatorForm operator={operator} />
                </OperatorFormProvider>
            </Container>
        </div>
    )
}

CreateOperatorPage.propTypes = {}

export default CreateOperatorPage