import React, {useState} from 'react';
import {useNotes, addNote, deleteNotes, NoteTarget} from "../../api/notesApi";
import PropTypes from "prop-types";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {genericMapper} from '../../utils/stringUtils';
import {displayDateTime} from "../../utils/dateUtils";
import {Box, Dialog, DialogTitle, Paper, Alert, Typography, Button} from "@mui/material";
import {AddNote} from "./AddNote";
import {Remove} from "@mui/icons-material";
import ConfirmDialog from "../ConfirmDialog";
import makeStyles from '@mui/styles/makeStyles';
import useSnackBar from "../SnackBarContext";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        height: '100%',
        boxSizing: 'border-box',
    },
}));

const getNoteType = (params) => genericMapper(params.row.type)
const getCreatedDate = (params) => displayDateTime(params.row.createdAt)

const columns = [
    {field: 'type', headerName: 'Type', width: 140, valueGetter: getNoteType},
    {field: 'note', headerName: 'Note', flex: 3},
    {field: 'createdBy', headerName: 'Created by', width: 150},
    {field: 'createdAt', headerName: 'Created at', width: 140, valueGetter: getCreatedDate},
]

const NotesView = ({foreignKey, noteTarget = NoteTarget.case}) => {
    const classes = useStyles();
    const [refreshKey, setRefreshKey] = useState(null)
    const {isLoading, notes, error} = useNotes(foreignKey, refreshKey, noteTarget)
    const [selectedNote, setSelectedNote] = useState(null)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [gridSelectionModel, setGridSelectionModel] = useState([]);
    const {showToast} = useSnackBar()

    function displayNote(params) {
        setSelectedNote(params.row)
    }

    function handleCloseDisplay() {
        setSelectedNote(null)
    }

    function handleAddNote(note) {
        let newNote = {
            ...note,
            createdAt: new Date().toISOString()
        }
        addNote(foreignKey, newNote, noteTarget)
            .catch(error => {
                showToast(`Could not add note to case: ${error.errorMessage}`, 'error');
            }).then( () => {
                setRefreshKey(refreshKey + 1)
            }
        )
    }

    function handleDelete() {
        deleteNotes(foreignKey, gridSelectionModel, noteTarget)
            .then(results => {
                let errors = results.filter(r => r.status === 'rejected')
                if (errors.length) {
                    showToast(`Failed to delete ${errors.length} item${errors.length === 1 ? '' : 's'}`, 'error')
                }
                setGridSelectionModel([])
                setConfirmOpen(false)
                setRefreshKey(new Date())
            })
    }

    if (error) {
        return <Alert severity="error" variant="filled">{error}</Alert>
    }

    return (
        <Paper className={classes.paper}>
            <Typography variant="subtitle1" gutterBottom>Case Notes</Typography>
            <Box mb={1}>
                <DataGridPro
                    rows={notes}
                    loading={isLoading}
                    columns={columns}
                    autoHeight
                    disableColumnMenu
                    hideFooter
                    checkboxSelection
                    disableSelectionOnClick
                    onRowClick={displayNote}
                    selectionModel={gridSelectionModel}
                    onSelectionModelChange={setGridSelectionModel}
                />
            </Box>

            <Button
                disabled={gridSelectionModel && gridSelectionModel.length === 0}
                data-testid="delete-button"
                variant="text"
                color="error"
                startIcon={<Remove />}
                onClick={() => setConfirmOpen(true)}
            >Delete selected notes</Button>
            <AddNote onAdd={handleAddNote} />

            <Dialog open={!!selectedNote} onClose={handleCloseDisplay}>
                <DialogTitle>Note from {selectedNote?.createdByName} on {selectedNote ? displayDateTime(selectedNote.createdAt) : ''}</DialogTitle>
                <Box px={3} pb={3}>
                    {selectedNote?.note}
                </Box>
                <Box px={3} pb={3} display="flex" justifyContent="flex-end">
                    <Button data-testid="close-note" onClick={handleCloseDisplay}>Close</Button>
                </Box>
            </Dialog>

            <ConfirmDialog
                title="Confirm deletion"
                confirmLabel="Delete"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleDelete}>
                Are you sure you want to delete the selected notes?
            </ConfirmDialog>
        </Paper>
    )
}

NotesView.propTypes = {
    foreignKey: PropTypes.string,
    noteTarget: PropTypes.string,
}

export default NotesView