import {DeleteForever} from "@mui/icons-material";
import React, {useState} from "react";
import {deleteDraft} from "../../api/draftApi";
import ConfirmDialog from "../ConfirmDialog";
import PropTypes from "prop-types";

export default function DeleteDraftCase({row, onDelete}) {
    const [confirmOpen, setConfirmOpen] = useState(false)

    function handleDelete() {
        deleteDraft(row.id).then(() => {
            onDelete(row.id)
        })
    }

    function handleDialog(e) {
        e.preventDefault()
        e.stopPropagation()
        setConfirmOpen(true)
    }

    return (
        <div>
            <DeleteForever data-testid= "delete-draft" onClick = {handleDialog}/>
            <ConfirmDialog
                title="Delete Draft?"
                confirmLabel="Delete"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleDelete}>
                Are you sure you want to delete this draft?
            </ConfirmDialog>
        </div>
    )
}

DeleteDraftCase.propTypes = {
    row: PropTypes.any,
    onDelete: PropTypes.func
}