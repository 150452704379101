import axios, {AxiosRequestConfig} from 'axios';
import {getItem, storageType} from "../store";
import {UserToken} from "../model/User";
import {apiUrl} from "./apiEnv";

// Set the apiUrl as a default
axios.defaults.baseURL = apiUrl

// Intercept requests and inject Authorization token if we are logged in
axios.interceptors.request.use(
    (config: AxiosRequestConfig<any>) => {
        const rawUser: string | null = getItem(storageType.SESSION, 'user')
        if (!rawUser) {
            return config
        }

        const user: UserToken = JSON.parse(rawUser)

        if (isApiUrl(config?.url)) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${user.token}`
            }
        }

        return config
    }, (error) => {
        return Promise.reject(error);
    }
)

// Return error response data if present or fall back to the raw error
axios.interceptors.response.use(
    (response: any) => {
        return response;
    },
    (error: any) => {
        if (!isLoginAttempt(error?.config?.url) && error.response?.status === 401) {
            return window.location.replace('/login');
        }
        return Promise.reject(error);
    }
);

function isLoginAttempt(url: string) {
    return url?.includes("/security/authenticate") || false
}

function isApiUrl(url?: string) {
    return url?.startsWith(apiUrl) || url?.startsWith('/') || false;
}

export {axios}
