import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Box,
    ClickAwayListener,
    Grow,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from "@mui/material";
import {
    AccountCircle,
    SupervisedUserCircle,
} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import DomPurify from "dompurify";
import useAuth from "../auth/AuthProvider";
import featureIsEnabled from "../utils/featureFlag";
import {hasAuthority, hasSomeAuthorities, VIEW_ASSESSOR} from "../auth/AuthorityHelper";

export default function MenuAppBar() {
    const history = useHistory();
    const {authed, logout} = useAuth();

    // Menu Toggle stuff
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent|TouchEvent) => {
        // @ts-ignore
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }
    // end of menu toggle stuff

    const operatorManagement = () => {
        closePopper();
        history.push('/operators');
    };

    const assessorManagement = () => {
        closePopper();
        history.push('/assessors');
    };

    const handleLogout = () => {
        closePopper();
        logout();
        history.push("/login")
    };

    const closePopper = () => {
        if (open) {
            setOpen(false);
        }
    }

    if (!authed) {
        return null;
    }

    return (
        <AppBar position="static" data-testid="appbar">
            <Toolbar variant="dense" className={"App-Menu-Bar"} >
                <Box display='flex' flexGrow={1}>
                    <div dangerouslySetInnerHTML={{__html: DomPurify.sanitize(`<img src="/images/popla-logo-white.svg" class="App-Menu-Bar-Logo" alt="menu bar logo"/>`)}} />
                </Box>
                <IconButton
                    data-testid={"profile-icon-button"}
                    aria-label="account"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    size="large">
                    <AccountCircle fontSize={"large"} className={"App-Menu-Bar-Account-Button"} />
                </IconButton>
                <Popper open={open} anchorEl={anchorRef.current} transition disablePortal className={"App-Menu-Bar-Account-Menu"}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList data-testid={"profile_menu"} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {featureIsEnabled('user-management') && hasAuthority(VIEW_ASSESSOR) &&
                                            <MenuItem data-testid={"menuitem_user_management"}
                                                      onClick={assessorManagement}>
                                                <ListItemIcon>
                                                    <SupervisedUserCircle fontSize="small"/>
                                                </ListItemIcon>
                                                User management
                                            </MenuItem>
                                        }
                                        { hasSomeAuthorities('CREATE_OPERATOR',
                                        'EDIT_OPERATOR',
                                        'VIEW_OPERATOR') && (<MenuItem data-testid={"menuitem_operator_management"} onClick={operatorManagement}>
                                            <ListItemIcon>
                                                <SupervisedUserCircle fontSize="small"/>
                                            </ListItemIcon>
                                            Operator management
                                        </MenuItem>)}
                                        {/*<MenuItem data-testid={"menuitem_change_password"} >*/}
                                        {/*    <ListItemIcon>*/}
                                        {/*        <Create fontSize="small" />*/}
                                        {/*    </ListItemIcon>*/}
                                        {/*    Change password*/}
                                        {/*</MenuItem>*/}
                                        <MenuItem data-testid={"menuitem_logout"} onClick={handleLogout}>
                                            <ListItemIcon>
                                                <AccountCircle fontSize="small"/>
                                            </ListItemIcon>
                                            Log out
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Toolbar>
        </AppBar>
    )
}
