import React from 'react';
import {DataGridPro} from "@mui/x-data-grid-pro";
import {customFormatter, statusCell, tooltipCell} from "../utils/dataGridHelpers";
import {formatContactName} from "../utils/contactFormatter";
import {displayDate, displayTargetDecisionDate} from "../utils/dateUtils";
import {conditionMapper} from "../utils/conditionMapper";
import DeleteDraftCase from "../components/case/DeleteDraftCase";
import PropTypes from "prop-types";

const CasesDataGrid = ({showDraftCases, onDeleteRow, ...restProps}) => {
    const caseColumns = [
        {field: 'pcn', headerName: 'PCN', width: 10, sortable: true, filterable: false, hide: true},
        {field: 'verificationCode', headerName: 'Verification Code', width: 190, sortable: true, filterable: false},
        {field: 'vehicleRegistration', headerName: 'Vehicle Reg', width: 129, sortable: true},
        {field: 'appellant', headerName: 'Appellant', flex: 1, valueFormatter: customFormatter(formatContactName, '-'), sortable: true, filterable: false},
        {field: 'operatorName', headerName: 'Operator', width: 109, sortable: true, renderCell: tooltipCell},
        {field: 'assessorName', headerName: 'Assessor', flex: 1, sortable: true},
        {field: 'submissionDate', headerName: 'Target Date', width: 127, valueFormatter: customFormatter(displayTargetDecisionDate, '-'), sortable: true, filterable: false},
        {field: 'decisionDate', headerName: 'Decision Date', width: 142, valueFormatter: customFormatter(displayDate, '-'), sortable: true, filterable: false},
        {field: 'status', headerName: 'Status', width: 120, renderCell: statusCell, sortable: true, filterable: false},
        {field: 'condition', headerName: 'Condition', flex: 1.5, valueFormatter: customFormatter(conditionMapper, '-'), sortable: true, filterable: false},
    ]

    const draftColumns = [
        {field: 'verificationCode', headerName: 'Verification Code', width: 170, sortable: false, filterable: false},
        {field: 'name', headerName: 'Name', flex: 1, sortable: false, filterable: false},
        {field: 'address', headerName: 'Address', flex: 2, sortable: false, filterable: false},
        {field: 'phone', headerName: 'Phone', width: 140, sortable: false, filterable: false},
        {field: 'email', headerName: 'Email', flex: 1, sortable: false, filterable: false},
        {field: 'createdByName', headerName: 'Created by', width: 140, sortable: false, filterable: false},
        {field: 'createdAt', headerName: 'Created at', width: 140, sortable: false, filterable: false, valueFormatter: customFormatter(displayDate, '-')},
        // eslint-disable-next-line react/display-name
        {field: 'actions', headerName : 'Actions', filterable: false, sortable: false, width: 100, renderCell: (params) => <DeleteDraftCase row={params.row} onDelete={onDeleteRow} />}
    ]

    return (
        <DataGridPro
            columns={showDraftCases ? draftColumns : caseColumns}
            paginationMode="server"
            disableSelectionOnClick
            pagination
            {...restProps}
        />
    )
}

CasesDataGrid.propTypes = {
    showDraftCases: PropTypes.bool,
    onDeleteRow: PropTypes.func
}

export default CasesDataGrid