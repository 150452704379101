import React from "react"
import PropTypes from "prop-types";

function MessageProperty({name, value}) {
    return (
        <span>
            <strong>{name}</strong>: {value}
        </span>
    )
}

MessageProperty.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
}

export default MessageProperty