import React from 'react';
import Chip from '@mui/material/Chip';
import PropTypes from "prop-types";
import {statusColour} from "../utils/statusColour";

const StatusChip = ({status, ...restProps}) => {
    let style = {
        backgroundColor: statusColour(status),
        color: 'white'
    }

    return <Chip style={style} {...restProps} />
}

StatusChip.propTypes = {
    status: PropTypes.string,
}

export default StatusChip;