export const urlSlug = (str) => {
    return str
        .trim()
        .toLowerCase()
        .replace(/&/g, 'and')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
        .replace(/[^a-z0-9-_]/g, '');
}

export const truncate = (str, num, ellipsis = '') => {
    if (str.length + ellipsis.length <= num) {
        return str
    }
    return str.slice(0, num - ellipsis.length) + ellipsis
}

export const capitalize = (str) => {
    if (!str) return '';
    let [first, ...rest] = str;
    return first.toUpperCase() + rest.join('')
}

export const genericMapper = (value) => {
    if (!value) return '';
    let newValue = value.toLowerCase()
    newValue = newValue.replace(/_/g, ' ');
    newValue = newValue.split(/\s+/).map(s => capitalize(s)).join(' ')
    return (newValue);
}

export const formatName = (...args) => {
    const name = args.filter(Boolean).join(" ").trim();
    if (name === "") {
        return "-"
    }
    return name;
}