import React, {useState} from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    Button
} from "@mui/material";
import ValidInput from "../ValidInput";
import * as yup from "yup";
import {Case} from "../../model/Case";
import {updateCaseDetails} from "../../api/caseApi";
import useSnackBar from "../SnackBarContext";
import {LoadingButton} from "@mui/lab";

const defaultFormValues: FormValues = {
    pcn: '',
    vehicleRegistration: '',
}

const defaultFormErrors: FormErrors = {
    pcn: null,
    vehicleRegistration: null,
}

const caseDetailsSchemaShape = {
    pcn : yup.string().required().max(255).label('PCN Number'),
    vehicleRegistration: yup.string().required().max(10).label('Vehicle Registration'),
}

export const caseFieldsSchema = yup.object().shape(caseDetailsSchemaShape)


const EditCaseDetails = (props: Props) => {
    const {onSubmit, caseObject, verificationCode, open, onClose} = props;
    const [formValues, setFormValues] = useState(caseObject ? mapCaseToFormValues(caseObject) : {...defaultFormValues})
    const [formErrors, setFormErrors] = useState({...defaultFormErrors})
    const [isLoading, setIsLoading] = useState(false);
    const {showToast} = useSnackBar() as SnackBarProvider;
    const schema = caseFieldsSchema;

    function handleSubmit() {
        const errors: FormErrors = validate(formValues, schema);
        setFormErrors(errors)
        if (Object.entries(errors).length) return;

        setIsLoading(true);
        updateCaseDetails(verificationCode, formValues)
            .then(() => {
                onSubmit(formValues)
            })
            .catch(() => {
                showToast(`There was a problem updating the Case details, please try again later`, "error")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function handleInput(e: any) {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value})
    }

    function validate(formValues: any, schema: any): FormErrors {
        let errors: FormErrors = {}
        if (!formValues) return errors;

        schema._nodes.forEach((node: string) => {
            try {
                schema.validateSyncAt(node, formValues)
            } catch (err: any) {
                errors[node] = err.message
            }
        })

        return errors
    }

    function isFieldRequired(fieldName: string) {
        const field = schema.fields[fieldName] as any
        return field.spec.presence === 'required';
    }

    return (
        <div>
            <Dialog open={open} onClose={onClose} fullWidth>
                <DialogTitle>Edit Case Details</DialogTitle>
                <Box px={3}>
                    <ValidInput id="pcn" label="PCN Number" name="pcn" value={formValues.pcn} onChange={handleInput} errorText={formErrors.pcn} required={isFieldRequired('pcn')} />
                    <ValidInput id="vehicleRegistration" label="Vehicle Registration" name="vehicleRegistration" value={formValues.vehicleRegistration} onChange={handleInput} errorText={formErrors.vehicleRegistration} required={isFieldRequired('vehicleRegistration')} />
                </Box>
                <Box px={2} pb={2}>
                    <DialogActions>
                        <Button data-testid="add-note-cancel" onClick={onClose} color="inherit">Cancel</Button>
                        <LoadingButton data-testid="add-note-submit" onClick={handleSubmit} loading={isLoading}>Save</LoadingButton>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}

export function mapCaseToFormValues(caseObject: Case) {
    let values: FormValues = {}
    const validFields = ['pcn', 'vehicleRegistration'];
    Object.entries(caseObject).forEach(([key, val]) => {
        if (validFields.includes(key)) {
            values[key]  = val || ""
        }
    })
    return values;
}


interface Props {
    open: boolean
    onClose: any
    caseObject: Case | undefined
    verificationCode: string
    onSubmit: any
}

interface FormValues {
    [key: string]: string
}

interface FormErrors {
    [key: string]: string | null
}

interface SnackBarProvider {
    // eslint-disable-next-line no-unused-vars
    showToast(m: string, t: string): void;
}

export default EditCaseDetails;