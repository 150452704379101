import {Grid, MenuItem, Typography} from "@mui/material";
import Select from "../../components/Select";
import ValidInput from "../../components/ValidInput";
import React, {useContext} from "react";
import {CaseFormContext} from "./CaseFormContext";

const MotoristFields = () => {
    const {
        formValues,
        formErrors,
        isDraft,
        handleInput,
    } = useContext(CaseFormContext)

    return (
        <div data-testid="motorist-fields">
            <Typography variant="h5">Motorist details</Typography>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Select dataTestId="motorist-title-select" name="motoristTitle" value={formValues.motoristTitle} onChange={handleInput} label="Title">
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Mrs">Mrs</MenuItem>
                        <MenuItem value="Miss">Miss</MenuItem>
                        <MenuItem value="Dr">Dr</MenuItem>
                        <MenuItem value="Ms">Ms</MenuItem>
                        <MenuItem value="Prof">Prof</MenuItem>
                        <MenuItem value="Rev">Rev</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <ValidInput id="motoristFirstName" label="First name" name="motoristFirstName" value={formValues.motoristFirstName} errorText={formErrors.motoristFirstName} onChange={handleInput} required={!isDraft} />
                </Grid>
                <Grid item xs={4}>
                    <ValidInput id="motoristLastName" label="Last name" name="motoristLastName" value={formValues.motoristLastName} errorText={formErrors.motoristLastName} onChange={handleInput} required={!isDraft} />
                </Grid>
            </Grid>
            <ValidInput id="motoristAddressLine1" label="Address line 1" name="motoristAddressLine1" value={formValues.motoristAddressLine1} errorText={formErrors.motoristAddressLine1} onChange={handleInput} required={!isDraft} />
            <ValidInput id="motoristAddressLine2" label="Address line 2" name="motoristAddressLine2" value={formValues.motoristAddressLine2} errorText={formErrors.motoristAddressLine2} onChange={handleInput}/>
            <ValidInput id="motoristPostTown" label="Town" name="motoristPostTown" value={formValues.motoristPostTown} errorText={formErrors.motoristPostTown} onChange={handleInput} required={!isDraft} />
            <ValidInput id="motoristCounty" label="County" name="motoristCounty" value={formValues.motoristCounty} errorText={formErrors.motoristCounty} onChange={handleInput} required={!isDraft} />
            <ValidInput id="motoristPostcode" label="Postcode" name="motoristPostcode" value={formValues.motoristPostcode} errorText={formErrors.motoristPostcode} onChange={handleInput} required={!isDraft} />
        </div>
    )
}

export default MotoristFields