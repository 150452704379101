import StatusChip from "../components/StatusChip";
import React from "react";
import {Tooltip} from "@mui/material";

/**
 * Wrap a custom formatter so it can accept a params object from the DataGrid e.g. {value: 'some value'}
 * @param formatter - the formatter function used to format the value param
 * @param nullValue - if no value param is present, display this value, default is an empty string
 * @returns {(function(*): (string))|*}
 */
export function customFormatter(formatter, nullValue = '') {
    return (params) => {
        if (!params?.value) {
            return nullValue
        }
        return formatter(params.value)
    }
}

/**
 * Format a status cell using the StatusChip component
 * @param params - params object from the DataGrid e.g. {value: 'some value'}
 * @returns {JSX.Element}
 */
export const statusCell = (params) => <StatusChip size="small" label={params?.value} status={params?.value} />

/**
 * Display a datagrid cell using the Tooltip component
 * @param params - params object from the DataGrid e.g. {value: 'some value'}
 * @returns {JSX.Element}
 */
export const tooltipCell = (params) =>  <Tooltip title={params.value || ''}><div>{params.value}</div></Tooltip>