import {RouteComponentProps, withRouter} from "react-router-dom";
import jwt_decode from "jwt-decode";
import useAuth from "./AuthProvider";
import {getItem, storageType} from "../store/useStorage";
import {JwtPayload } from 'jwt-decode';

const AuthVerify = (props: Props) => {
    const {logout} = useAuth()
    const {history} = props;

    const handleLogout = () => {
        logout();
        history.push('/login');
    }

    history.listen(() => {
        const user = getItem(storageType.SESSION, 'user');
        if (!user) return;

        const {token} = JSON.parse(user);
        if (token && history.location.pathname !== '/login') {
            const decodedJwt: JwtPayload = jwt_decode(token);
            if ((decodedJwt.exp || 0) * 1000 < Date.now() && typeof logout == 'function') {
                handleLogout();
            }
        }
    });

    return null;
};

interface Props extends RouteComponentProps {}

export default withRouter(AuthVerify);