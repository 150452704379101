import React from 'react';
import {PageRoute} from "./PageRoute";
import CasesPage from "../cases/CasesPage";
import {BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";
import {PrivateRoute} from "./PrivateRoute";
import {LoginPage} from "../auth/LoginPage";
import MenuAppBar from "../components/MenuAppBar";
import CasePage from "../cases/CasePage";
import CreateCasePage from "../cases/CreateCasePage";
import OperatorsPage from "../operators/OperatorsPage"
import CreateOperatorPage from "../operators/CreateOperatorPage/index";
import OperatorUsersPage from "../operators/users/OperatorUsersPage";
import CreateOperatorUserPage from "../operators/users/CreateOperatorUserPage";
import AuthVerify from '../auth/AuthVerify';
import useAuth from '../auth/AuthProvider';
import AssessorUsersPage from "../assessors/AssessorUsersPage";
import CreateAssessorUserPage from "../assessors/CreateAssessorUserPage";

const routes: PageRoute[] = [
    {
        path: '/',
        component: CasesPage,
        private: true,
        exact: true,
    },
    {
        path: '/login',
        component: LoginPage,
        private: false,
        exact: true,
    },
    {
        path: '/case/create',
        component: CreateCasePage,
        private: true,
        exact: true,
    },
    {
        path: '/case/create/:id',
        component: CreateCasePage,
        private: true,
        exact: true,
    },
    {
        path: '/case/:verificationCode',
        component: CasePage,
        private: true,
        exact: true,
    },
    {
        path: '/operators',
        component: OperatorsPage,
        private: true,
        exact: true,
    },
    {
        path: '/operators/create',
        component: CreateOperatorPage,
        private: true,
        exact: true,
    },
    {
        path: '/operators/edit',
        component: CreateOperatorPage,
        private: true,
        exact: true,
    },
    {
        path: '/operators/users',
        component: OperatorUsersPage,
        private: true,
        exact: true,
    },
    {
        path: '/operators/users/create',
        component: CreateOperatorUserPage,
        private: true,
        exact: true,
    },
    {
        path: '/operators/users/edit',
        component: CreateOperatorUserPage,
        private: true,
        exact: true,
    },
    {
        path: '/assessors',
        component: AssessorUsersPage,
        private: true,
        exact: true,
    },
    {
        path: '/assessors/create',
        component: CreateAssessorUserPage,
        private: true,
        exact: true,
    },
    {
        path: '/assessors/edit',
        component: CreateAssessorUserPage,
        private: true,
        exact: true,
    }
];

export const Routes = () => {
    const {authed} = useAuth();
    return (
        <Router>
            <MenuAppBar />
            <Switch>
                {routes.map((route, index) => {
                    const RouteType = route.private ? PrivateRoute : Route;
                    return (
                        <RouteType
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            isAuthenticated={authed}
                        >
                            <route.component/>
                        </RouteType>
                    );
                })}
                <Route>
                    <Redirect to="/login"/>
                </Route>
            </Switch>
            <AuthVerify />
        </Router>
    )
};
