import {Box, CircularProgress, Paper, Typography, Button} from "@mui/material";
import ValidInput from "../ValidInput";
import React, {useState} from "react";
import {getCase} from "../../api/caseApi";
import {Redirect} from "react-router-dom";
import {ArrowForward} from "@mui/icons-material";

const LookupCase = () => {
    const [redirect, setRedirect] = React.useState(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationError, setVerificationError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    if (redirect) {
        return <Redirect push to={redirect}/>
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            checkCase()
        }
    }

    function checkCase() {
        if (verificationCode === '') {
            return;
        }

        if (!verificationCode.match(/[\d]{10}/)) {
            setVerificationError('verification code is invalid')
            return;
        }

        setIsLoading(true)
        getCase(verificationCode)
            .then(res => {
                setRedirect(`/case/${res.data.verificationCode}`)
            })
            .catch(() => {
                setVerificationError('case not found')
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <Paper elevation={3}>
            <Box p={3}>
                <Typography variant="h5" component="h2" style={{marginBottom: 16}}>Lookup Case Verification Code</Typography>
                <Box display="flex" alignItems="flex-start">
                    <ValidInput
                        data-testid="lookup-case-input"
                        label="Verification Code"
                        name="verificationCode"
                        type="text"
                        onChange={(e) => setVerificationCode(e.target.value)}
                        onKeyDown={handleKeyDown}
                        errorText={verificationError}
                    />
                    <Button
                        style={{marginTop: 25, marginLeft: 16}}
                        data-testid="lookup-case-submit"
                        onClick={checkCase}
                        endIcon={<ArrowForward />}
                    >
                        { isLoading ? <CircularProgress size={24} style={{color: 'white', backgroundColor: "transparent"}}/> : 'Check' }
                    </Button>
                </Box>
            </Box>
        </Paper>
    )
}

export default LookupCase