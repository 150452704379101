import React, {useState} from "react"
import PropTypes from "prop-types"
import {Divider, Paper, Box, IconButton, CircularProgress} from "@mui/material";
import {DeleteForever} from "@mui/icons-material";
import MessageProperty from "./MessageProperty";
import AttachmentLink from "./AttachmentLink";
import MessageBody from "./MessageBody";
import {displayDate12HourFormat} from "../../utils/dateUtils";
import makeStyles from '@mui/styles/makeStyles';
import ConfirmDialog from "../ConfirmDialog";
import withSnackbar from "../WithSnackbar";
import {deleteEmail, EmailTarget} from "../../api/emailApi";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        flexFlow: 'row wrap',
        alignItems: 'center',
        "& > span": {
            marginRight: theme.spacing(1)
        },
        "& > span::after": {
            content: '"|"',
            marginLeft: theme.spacing(1)
        },
        "& button": {
            marginLeft: 'auto'
        }
    },

}));

function EmailView({email, onChange, showToast, emailTarget = EmailTarget.case, targetId}) {
    const classes = useStyles();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const formatDirection = (direction) => {
        if (direction) {
            return direction.charAt(0).toUpperCase() + direction.slice(1).toLowerCase();
        } else {
            return '';
        }
    };

    const sentOrReceived = (direction) => {
        if (direction && (direction === 'OUTGOING')) {
            return 'Sent';
        } else {
            return 'Received';
        }
    };

    const deleteMessage = async () => {
        setIsLoading(true);
        deleteEmail(email.messageId, emailTarget)
            .then(() => {
                onChange();
                showToast(`Email from ${email.sentFrom} was successfully deleted`);
            })
            .catch(() => {
                showToast(`There was a problem deleting the email from ${email.sentFrom}, please try again later`, "error");
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const attachments = email?.attachments?.map(a => {
        return <AttachmentLink key= {a.filename} filename={a.filename} url={a?.downloadUrl}/>
    })

    return (
        <div style={{marginBottom: 24, position: 'relative'}}>
            <Paper elevation={5} style={{padding: 10, color: '#707070', fontSize: 12}}>
                <div>
                    <Box className={classes.header}>
                        <strong>{formatDirection(email?.direction)} </strong>
                        <MessageProperty name="From" value={email?.sentFrom || ''}/>
                        <MessageProperty name="To" value={email?.sentTo || ''}/>
                        <MessageProperty name="Subject" value={email?.subject || ''}/>
                        <MessageProperty name={sentOrReceived(email?.direction)} value={displayDate12HourFormat(email?.timestamp)}/>
                        <MessageProperty name="Created" value={displayDate12HourFormat(email?.createdDate)}/>
                        <MessageProperty name="CC'd" value={email?.cc || ''}/>
                        <IconButton style={{color: '#d32f2f'}} size="small" onClick={() => setDialogIsOpen(true)} data-testid="delete-email">
                            <DeleteForever  />
                        </IconButton>
                    </Box>
                    <Divider style={{marginTop: 10, marginBottom: 10}}/>
                    <MessageBody name="Message" value={email?.body?.body} type={email?.body?.type}/>
                    <Divider style={{marginTop: 10, marginBottom: 10}}/>
                    <div>
                        <div style={{marginBottom:10}}>
                            <strong>Attachments</strong>:
                        </div>
                        {attachments}
                    </div>
                </div>
            </Paper>
            <ConfirmDialog title="Confirm deletion" open={dialogIsOpen} setOpen={setDialogIsOpen} onConfirm={deleteMessage} >
                Are you sure you want to delete the email <b>{email?.subject}</b> from <b>{email?.sentFrom}</b>?
            </ConfirmDialog>
            {isLoading && (
                <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(255, 255, 255, 0.8)', display: 'flex'}}>
                    <CircularProgress style={{margin: 'auto'}} />
                </div>
            )}
        </div>
    )
}

EmailView.propTypes = {
    email: PropTypes.shape({
        messageId: PropTypes.string,
        caseVerificationCode: PropTypes.string,
        sentFrom: PropTypes.string.isRequired,
        sentTo: PropTypes.string.isRequired,
        cc: PropTypes.string,
        subject: PropTypes.string,
        timestamp: PropTypes.string.isRequired,
        createdDate: PropTypes.string,
        direction: PropTypes.string,
        body: PropTypes.shape({
            body: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }),
        attachments: PropTypes.arrayOf(PropTypes.shape({
            filename: PropTypes.string.isRequired,
            downloadUrl: PropTypes.string.isRequired
        }))
    }),
    onChange: PropTypes.func,
    showToast: PropTypes.func,
    emailTarget: PropTypes.string,
    targetId: PropTypes.string
}
export default withSnackbar(EmailView);