import {apiUrl} from "./apiEnv";
import {axios} from "./apiWrapper";

export const createDraftCase = async (formValues) => {
    return await axios.post(`${apiUrl}/drafts`, formValues)
        .then((response) => response.data);
}

export const getDraftCases = (page, size, assessor_cases, searchParams = []) => {
    const queryParams = [
        {page},
        {size},
        {assessor_cases},
        {sort: 'createdAt,desc'},
        ...searchParams
    ]

    const params = new URLSearchParams()
    queryParams.forEach(param => {
        Object.entries(param).forEach(([key, value])=> {
            params.append(key, value)
        })
    })

    return axios.get(`${apiUrl}/drafts`, {
        params
    })
}

export const getDraftCase = (id) => {
    return axios.get(`${apiUrl}/drafts/${id}`)
}

export const updateDraftCase = async (formValues, draftID) => {
    return await axios.put(`${apiUrl}/drafts/${draftID}`, formValues)
        .then((response) => response.data);
}

export const promoteDraftCase = async (guid, verificationCode, token) => {
    return await axios.patch(`${apiUrl}/drafts/${guid}/promote/${verificationCode}`, {})
        .then(response => response.data)
}

export const deleteDraft = (id, token) => {
    return axios.delete(`${apiUrl}/drafts/${id}`)
}