import React from 'react';
import {
    Box,
    Breadcrumbs,
    Link as MaterialLink,
    Tabs,
    CircularProgress
} from "@mui/material";
import {Link as ReactLink, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import CaseTab from "../components/email/CaseTab";
import Typography from "@mui/material/Typography";
import {useCase} from "../api/caseApi";
import tabProps from "../utils/tabProps";
import {DocumentsView} from "../components/documents/DocumentsView";
import EmailTab from "../components/email/EmailTab";
import {CaseAssessmentView} from "../components/assessment/CaseAssessmentView";
import {SummaryView} from "../components/summary/SummaryView";
import {MotoristResponseView} from "../components/motoristResponse/MotoristResponseView";
import {OperatorResponseView} from "../components/operatorResponse/OperatorResponseView";
import CaseHistoryView from "../components/history/CaseHistoryView";
import NotesView from "../components/notes/NotesView";
import {NoteTarget} from "../api/notesApi";
import Warning from '@mui/icons-material/Warning';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box mt={3} pl={0}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const CasePage = () =>{
    const {verificationCode} = useParams();
    const [value, setValue] = React.useState(0);
    const {isLoading, caseObject, error, setCaseObject} = useCase(verificationCode)

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    if (isLoading) {
        return (
            <Box justifyContent="center" display="flex" mt={5}>
                <CircularProgress/>
            </Box>
        )
    }

    const operatorTabProps = { iconPosition: "end" }
    if (!caseObject?.appellant?.email && caseObject?.condition === "AWAITING_MOTORIST_COMMENT") {
        operatorTabProps.appellantwarning = "true"
        operatorTabProps.title = "Appellant email is not present"
        operatorTabProps.icon = <Warning />
    } else if (caseObject?.operatorEvidenceExcluded) {
        operatorTabProps.warning = "true"
        operatorTabProps.icon = <Warning />
        operatorTabProps.title = "Operator evidence is not present"
    }

    const caseAssessmentTabProps = { iconPosition: "end" }
    if (!caseObject?.appellant?.email && caseObject?.status === "CLOSED") {
        caseAssessmentTabProps.warning = "true"
        caseAssessmentTabProps.title = "Appellant email is not present"
        caseAssessmentTabProps.icon = <Warning/>
    }

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: 28}}>
                <MaterialLink component={ReactLink} to="/" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <Typography color="textPrimary">Cases</Typography>
                {error
                    ? <Typography color="textPrimary">Case Not Found</Typography>
                    : <Typography color="textPrimary">Case {caseObject?.verificationCode}</Typography>
                }
            </Breadcrumbs>

            <Box display="flex" marginBottom={1}>
                <Tabs
                    value={value}
                    onChange={handleTabChange}
                    TabIndicatorProps={{style: {display: "none"}}}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <CaseTab label={"Summary"} {...tabProps(0)}/>
                    <CaseTab label={"Motorist Responses"} {...tabProps(1)}/>
                    <CaseTab label={"Operator Responses"} {...tabProps(2)} {...operatorTabProps} />
                    <CaseTab label={"Documents"} {...tabProps(3)}/>
                    <CaseTab label={"Emails"} {...tabProps(4)}/>
                    <CaseTab label={"Case Assessment"} {...tabProps(5)} {...caseAssessmentTabProps} />
                    <CaseTab label={"Case History"} {...tabProps(6)}/>
                    <CaseTab label={"Case Notes"} {...tabProps(7)}/>
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <SummaryView
                    caseObject={caseObject}
                    maxHeight={1000}
                    setCaseObject={setCaseObject}
                />
            </TabPanel>
            <TabPanel index={1} value={value}>
                <MotoristResponseView caseObject={caseObject}/>
            </TabPanel>
            <TabPanel index={2} value={value}>
                <OperatorResponseView caseObject={caseObject}/>
            </TabPanel>
            <TabPanel index={3} value={value}>
                <DocumentsView operatorCode={caseObject?.operatorCode} foreignKey={caseObject?.verificationCode} documentTarget={EventTarget.case} />
            </TabPanel>
            <TabPanel index={4} value={value}>
                <EmailTab caseObject={caseObject}/>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <CaseAssessmentView caseObject={caseObject} setCaseObject={setCaseObject} />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <CaseHistoryView verificationCode={caseObject?.verificationCode}
                                 submissionDate={caseObject?.submissionDate}/>
            </TabPanel>
            <TabPanel value={value} index={7}>
                <NotesView foreignKey={caseObject.verificationCode} noteTarget={NoteTarget.case} />
            </TabPanel>
        </div>
    );
}

CasePage.propTypes = {
    showToast: PropTypes.func
}

export default CasePage
