import React, {useContext} from "react"
import {Box, CircularProgress, Paper} from "@mui/material";
import EmailView from "../../components/email/EmailView"
import {useEmails} from "../../api/emailApi";
import {CaseFormContext} from "./CaseFormContext";
import PropTypes from "prop-types";

function DraftEmailTab({emailTarget}) {
    const {draftId} = useContext(CaseFormContext)
    const {emails, error, isLoading, refetch: refetchEmails} = useEmails(emailTarget, draftId)

    if (isLoading) {
        return <CircularProgress/>
    }

    if (error) {
        return (
            <Paper>
                <Box>Unable to load emails</Box>
            </Paper>
        )
    }

    return (
        <div>
            {emails.map(email => (
                <EmailView
                    emailTarget={emailTarget}
                    targetId={draftId}
                    key={email.messageId}
                    className="EmailTab"
                    email={email}
                    onChange={refetchEmails}
                />
            ))}
            {!emails.length && (
                <Paper>
                    <Box p={2}>There are no emails attached to this case</Box>
                </Paper>
            )}
        </div>
    )
}

DraftEmailTab.propTypes = {
    emailTarget: PropTypes.string
}

export default DraftEmailTab