import React, {useState} from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogTitle, Grid, MenuItem,
    Button
} from "@mui/material";
import ValidInput from "../ValidInput";
import Select from "../Select";
import {Contact} from "../../model/Case";
import * as yup from "yup";
import {updateAppellantDetails, updateMotoristDetails} from "../../api/caseApi";
import useSnackBar from "../SnackBarContext";
import {LoadingButton} from "@mui/lab";

export enum ContactType {
    // eslint-disable-next-line no-unused-vars
    Appellant = "Appellant",
    // eslint-disable-next-line no-unused-vars
    Motorist = "Motorist",
}

const defaultFormValues: FormValues = {
    type: 'GENERAL',
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    addressCity: '',
    addressCounty: '',
    addressPostcode: '',
    phone: '',
}

const defaultFormErrors: FormErrors = {
    type: null,
    title: null,
    firstName: null,
    lastName: null,
    email: null,
    addressLine1: null,
    addressLine2: null,
    addressCity: null,
    addressCounty: null,
    addressPostcode: null,
    phone: null,
}

const appellantSchemaShape = {
    title: yup.string().label('Title'),
    firstName: yup.string().required().max(50).label('First name'),
    lastName: yup.string().required().max(50).label('Last name'),
    phone: yup.string().required().max(50).label('Phone number'),
    email: yup.string().email().max(100).label('Email'),
    addressLine1: yup.string().required().max(250).label('Address line 1'),
    addressLine2: yup.string().max(250).label('Address line 2'),
    addressCity: yup.string().required().max(80).label('Town'),
    addressCounty: yup.string().required().max(50).label('County'),
    addressPostcode: yup.string().required().max(20).label('Postcode'),
}

export const appellantSchema = yup.object().shape(appellantSchemaShape)

export const motoristSchema = yup.object().shape({
    ...appellantSchemaShape,
    phone: yup.string().max(50).label('Phone number') // Make phone optional for motorists
});

const EditDetails = (props: Props) => {
    const {type, onSubmit, contact, verificationCode, open, onClose} = props;
    const [formValues, setFormValues] = useState(contact ? mapContactToFormValues(contact) : {...defaultFormValues})
    const [formErrors, setFormErrors] = useState({...defaultFormErrors})
    const [isLoading, setIsLoading] = useState(false);
    const {showToast} = useSnackBar() as SnackBarProvider;
    const schema = type === ContactType.Appellant ? appellantSchema : motoristSchema;

    function handleSubmit() {
        const errors: FormErrors = validate(formValues, schema);
        setFormErrors(errors)
        if (Object.entries(errors).length) return;

        setIsLoading(true);
        const update = type === ContactType.Appellant ? updateAppellantDetails : updateMotoristDetails;
        update(verificationCode, formValues)
            .then(() => {
                onSubmit(formValues)
            })
            .catch(() => {
                showToast(`There was a problem updating the ${type}, please try again later`, "error")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function handleInput(e: any) {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value})
    }

    function validate(formValues: any, schema: any): FormErrors {
        let errors: FormErrors = {}
        if (!formValues) return errors;

        schema._nodes.forEach((node: string) => {
            try {
                schema.validateSyncAt(node, formValues)
            } catch (err: any) {
                errors[node] = err.message
            }
        })

        return errors
    }

    function isFieldRequired(fieldName: string) {
        const field = schema.fields[fieldName] as any
        return field.spec.presence === 'required';
    }

    return (
        <div>
            <Dialog open={open} onClose={onClose} fullWidth>
                <DialogTitle>Edit {props.type} details</DialogTitle>
                <Box px={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Select dataTestId="appellant-title-select" name="title" value={formValues.title} onChange={handleInput} label="Title">
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Mrs">Mrs</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Dr">Dr</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                                <MenuItem value="Prof">Prof</MenuItem>
                                <MenuItem value="Rev">Rev</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <ValidInput id="appellantFirstName" label="First name" name="firstName" value={formValues.firstName} onChange={handleInput} errorText={formErrors.firstName} required={isFieldRequired('firstName')} />
                        </Grid>
                        <Grid item xs={4}>
                            <ValidInput id="appellantLastName" label="Last name" name="lastName" value={formValues.lastName} onChange={handleInput} errorText={formErrors.lastName} required={isFieldRequired('lastName')} />
                        </Grid>
                    </Grid>
                    <ValidInput id="appellantAddressLine1" label="Address line 1" name="addressLine1" value={formValues.addressLine1} onChange={handleInput} errorText={formErrors.addressLine1} required={isFieldRequired('addressLine1')} />
                    <ValidInput id="appellantAddressLine2" label="Address line 2" name="addressLine2" value={formValues.addressLine2} onChange={handleInput} errorText="" />
                    <ValidInput id="appellantPostTown" label="Town" name="addressCity" value={formValues.addressCity} onChange={handleInput} errorText={formErrors.addressCity} required={isFieldRequired('addressCity')} />
                    <ValidInput id="appellantCounty" label="County" name="addressCounty" value={formValues.addressCounty} onChange={handleInput} errorText={formErrors.addressCounty} required={isFieldRequired('addressCounty')} />
                    <ValidInput id="appellantPostcode" label="Postcode" name="addressPostcode" value={formValues.addressPostcode} onChange={handleInput} errorText={formErrors.addressPostcode} required={isFieldRequired('addressPostcode')} />
                    <ValidInput id="appellantPhoneNumber" label="Phone number" name="phone" value={formValues.phone} onChange={handleInput} errorText={formErrors.phone} required={isFieldRequired('phone')} />
                    <ValidInput id="appellantEmail" label="Email" name="email" value={formValues.email} onChange={handleInput} errorText={formErrors.email} required={isFieldRequired('email')} />
                </Box>
                <Box px={2} pb={2}>
                    <DialogActions>
                        <Button data-testid="add-note-cancel" onClick={onClose} color="inherit">Cancel</Button>
                        <LoadingButton data-testid="add-note-submit" onClick={handleSubmit} loading={isLoading}>Save</LoadingButton>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}

export function mapContactToFormValues(contact: Contact) {
    let values: FormValues = {}
    const validFields = ['title', 'firstName', 'lastName', 'email', 'phone', 'addressLine1', 'addressLine2', 'addressCity', 'addressCounty', 'addressPostcode'];
    Object.entries(contact).forEach(([key, val]) => {
        if (validFields.includes(key)) {
            values[key]  = val || ""
        }
    })
    return values;
}

interface Props {
    type: ContactType
    open: boolean
    onClose: any
    contact: Contact | undefined
    verificationCode: string
    onSubmit: any
}

interface FormValues {
    [key: string]: string
}

interface FormErrors {
    [key: string]: string | null
}

interface SnackBarProvider {
    // eslint-disable-next-line no-unused-vars
    showToast(m: string, t: string): void;
}

export default EditDetails;