import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
    Box,
    Breadcrumbs, Checkbox, FormControlLabel,
    Link as MaterialLink,
    Paper, TextField, Tooltip,
    Typography, Button
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Link as ReactLink, Redirect} from 'react-router-dom';
import {getAllCases, getNextCase, useCases} from "../api/caseApi";
import {useUser} from "../auth/useUser";
import {isoDate} from '../utils/dateUtils'
import makeStyles from '@mui/styles/makeStyles';
import LookupCase from "../components/case/LookupCase";
import {AddBox, ExpandLess, ExpandMore, GetApp, Replay} from "@mui/icons-material";
import { ReactComponent as Stack } from '../images/stack.svg'
import {utils, writeFile} from 'xlsx';
import {urlSlug} from "../utils/stringUtils";
import CaseAdvancedSearch from '../components/case/CaseAdvancedSearch'
import PropTypes from "prop-types";
import {SnackBarContext} from "../components/SnackBarContext";
import LinearProgressWithLabel from "../components/LinearProgressWithLabel";
import CasesDataGrid from "./CasesDataGrid";
import {mapCasesForExport} from "../utils/caseMapper";
import {useClearStateStore, useStateStore} from "../components/StateStoreProvider";

const caseSearchColumns = [
    {field: 'verificationCode', headerName: 'Verification code'},
    {field: 'vehicleRegistration', headerName: 'Vehicle Reg'},
    {field: 'appellant', headerName: 'Appellant'},
    {field: 'operatorName', headerName: 'Operator'},
    {field: 'assessorName', headerName: 'Assessor'},
    {field: 'targetDate', headerName: 'Target Date'},
    {field: 'decisionDate', headerName: 'Decision Date'},
    {field: 'status', headerName: 'Status'},
    {field: 'condition', headerName: 'Condition'}
]
const draftSearchColumns = [
    {field: 'verificationCode', headerName: 'Verification Code'},
    {field: 'name', headerName: 'Name'},
    {field: 'address', headerName: 'Address'},
    {field: 'phone', headerName: 'Phone'},
    {field: 'email', headerName: 'Email'},
    {field: 'createdAt', headerName: 'Created At'}
]

const useStyles = makeStyles((theme) => ({
    mast: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: "start",
    },
    searchPanel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        flexGrow: 1,
        [theme.breakpoints.up('xl')]: {
            flexDirection: 'row',
            alignItems: 'center',
        }
    },
    filterOptions:{
        paddingLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    mastActions: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
        justifyContent: 'end',
        gap: theme.spacing(1),
    },
    progressBar: {
        transition: 'none'
    }
}));

const CasesPage = () => {
    const {user} = useUser();
    const classes = useStyles()
    const {showToast} = useContext(SnackBarContext);
    const [pageSize, setPageSize] = useStateStore('cases.pageSize', 50)
    const [pageNum, setPageNum] = useStateStore('cases.pageNum', 0)
    const [showAssessorCases, setShowAssessorCases] = useStateStore('cases.showAssessorCases', true)
    const [showDraftCases, setShowDraftCases] = useStateStore('cases.showDraft', false)
    const [showAdvancedSearch, setShowAdvancedSearch] = useStateStore('cases.showSearchMode', false)
    const [allClosedCases, showAllClosedCases] = useStateStore('cases.allClosedCases', false)
    const [basicSearchValue, setBasicSearchValue] = useStateStore('cases.basicSearchValue', '')
    const [sortValue, setSortValue] = useStateStore('cases.sortValue', 'submissionDate,desc')
    const [searchParams, setSearchParams] = useStateStore('cases.searchParams', [])
    const [refreshKey, setRefreshKey] = useState(0);
    const [redirect, setRedirect] = React.useState(null);
    const [gettingNextCase, setGettingNextCase] = useState(false);
    const [csvDownloading, setCsvDownloading] = useState(false);
    const [progressLabel, setProgressLabel] = useState('');
    const [showProgress, setShowProgress] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const {reset: clearStateStore, filtersAreInitial} = useClearStateStore();

    const {cases, isLoading} = useCases(pageNum, pageSize, showAssessorCases, showDraftCases,
        searchParams, refreshKey, sortValue, allClosedCases)
    const searchTimer = useRef()
    const CSVPageLimit = 1000;

    function handleSearchToggle() {
        const checked = !showAdvancedSearch;
        setShowAdvancedSearch(checked)
        setBasicSearchValue('')
    }

    function handleDraftCasesToggle(e) {
        setShowDraftCases(e.target.checked)
        setShowAdvancedSearch(false)
        setShowAssessorCases(false)
        setBasicSearchValue('')
        showAllClosedCases(false)
    }

    function handleMyCasesToggle(e) {
        setShowAssessorCases(e.target.checked)
        setShowDraftCases(false)
    }

    function handleRowClick(params) {
        if (showDraftCases) {
            setRedirect(`/case/create/${params.row.id}`)
        } else {
            setRedirect(`/case/${params.row.verificationCode}`)
        }
    }

    async function exportCSV() {
        setCsvDownloading(true);
        setShowProgress(true);
        setProgressLabel('Downloading data...')
        const allCases = await getAllCases(showAssessorCases, showDraftCases, searchParams, cases.totalElements, CSVPageLimit, (progress) => {
            setProgressPercentage(progress)
        }, sortValue, allClosedCases)
        setProgressLabel('Generating file...')
        setProgressPercentage(0);

        const workSheet = utils.json_to_sheet(mapCasesForExport(allCases));
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, workSheet, "Cases");
        const date = isoDate(new Date())
        let username = user.username;
        if (username.includes('@')) {
            username = username.substring(0, user.username.indexOf('@'))
        }
        let name = showAssessorCases ? `${urlSlug(username)}` : 'all'
        if (showDraftCases) {
            name = name + '-draft'
        }

        try {
            writeFile(workBook, `cases-${name}-${date}.xls`);
            setCsvDownloading(false);
            setShowProgress(false);
            setProgressLabel('')
        } catch(err) {
            showToast("There was a problem generating the file", "error");
        }
    }

    function handleGetNext() {
        setGettingNextCase(true);
        getNextCase()
            .then(result => {
                if (result.errorMessage != null) {
                    showToast(result.errorMessage, 'error');
                } else {
                    showToast(`Next case: ${result.verificationCode}`);
                    setRedirect(`/case/${result.verificationCode}`);
                }
            })
            .finally(() => setGettingNextCase(false));
    }

    function handleBasicSearchChange(e) {
        const {value} = e.target;
        clearTimeout(searchTimer.current)
        setBasicSearchValue(value)
        searchTimer.current = setTimeout(() => {
            let params = []
            if (value.length > 2) {
                params = [
                    {filter: 'allFields'},
                    {value: value}
                ]
            }
            setPageNum(0)
            setSearchParams(params)
        }, 600)
    }

    const handleSearchInput = useCallback((params) => {
        setPageNum(0)
        setSearchParams(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleDeleteRow() {
        setRefreshKey(refreshKey + 1)
    }

    // Clear timeouts on exit
    useEffect(() => {
        return () => clearTimeout(searchTimer)
    }, [])

    if (redirect) {
        return <Redirect push to={redirect}/>
    }

    const handleSortModelChange = (newModel) => {
        if (newModel !== undefined
            && newModel.length > 0
            && newModel[0]["sort"] !== undefined
            && newModel[0]["field"] !== undefined) {

            switch(newModel[0]["field"]) {
                case 'appellant':
                    setSortValue('appellantPrimaryContactEntity_lastName,' + newModel[0].sort);
                    break;
                case 'operatorName':
                    setSortValue('operatorEntity_name,' + newModel[0].sort);
                    break;
                case 'assessorName':
                    setSortValue('assessorEntity_name,' + newModel[0].sort);
                    break;
                default:
                    setSortValue('' + newModel[0].field + ',' + newModel[0].sort);
            }

        } else {
            setSortValue('submissionDate,desc');
        }
    };

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: 28}}>
                <MaterialLink component={ReactLink} to="/" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <Typography color="textPrimary">Cases</Typography>
            </Breadcrumbs>

            <Box className={classes.mast}>
                <Box className={classes.searchPanel}>
                    <Paper variant="outlined" className={classes.filterOptions}>
                        <FormControlLabel
                            data-testid="toggle-assessor-cases"
                            control={<Checkbox name="assessor_cases" checked={showAssessorCases} color="primary" />}
                            label="My cases"
                            labelPlacement="end"
                            onChange={handleMyCasesToggle}
                        />
                        <FormControlLabel
                            data-testid="toggle-draft-cases"
                            control={<Checkbox name="draft_cases" checked={showDraftCases} color="primary" />}
                            label="Draft cases"
                            labelPlacement="end"
                            onChange={handleDraftCasesToggle}
                        />
                        <Tooltip title="Include all closed cases, including cases that are hidden from view by default because of their age" disableFocusListener>
                            <FormControlLabel
                                data-testid="toggle-all-closed-cases"
                                control={<Checkbox name="all_closed_cases"  checked={allClosedCases}/>}
                                label="Archived Cases"
                                labelPlacement="end"
                                onChange={(e) => showAllClosedCases(e.target.checked)}
                            />
                        </Tooltip>
                    </Paper>
                    <Box>
                        <Tooltip title="Search requires a minimum of 3 characters" disableFocusListener>
                            <TextField data-testid="cases-search" name="search" variant="standard" placeholder="Search" disabled={showAdvancedSearch} value={basicSearchValue} onChange={handleBasicSearchChange} />
                        </Tooltip>
                        <Button variant="text" onClick={handleSearchToggle} data-testid="toggle-advanced-search">{showAdvancedSearch ? <ExpandLess/> : <ExpandMore />} Advanced search</Button>
                    </Box>
                </Box>
                <Box className={classes.mastActions}>
                    <Button variant="text" onClick={() => clearStateStore()} style={{minWidth: 0}} startIcon={<Replay/>} disabled={filtersAreInitial}>Reset filters</Button>
                    <Button startIcon={<Stack />} onClick={handleGetNext} disabled={gettingNextCase} data-testid="get-next-case-link">Get next case</Button>
                    <Button startIcon={<AddBox />} component={ReactLink} to="/case/create" data-testid="create-case-link">Create new case</Button>
                </Box>
            </Box>

            {showAdvancedSearch && (
                <Box mb={2}>
                    <CaseAdvancedSearch
                        columns={showDraftCases ? draftSearchColumns : caseSearchColumns}
                        onChange={handleSearchInput}
                    />
                </Box>
            )}

            <Paper elevation={3} style={{height: 630, marginBottom: 28}} data-testid="cases-table">
                <CasesDataGrid
                    rows={cases ? cases.content : []}
                    rowCount={cases ? cases.totalElements : 0}
                    showDraftCases={showDraftCases}
                    onDeleteRow={handleDeleteRow}
                    getRowId={(row => row.caseId ? row.caseId : row.id)}
                    loading={isLoading}
                    pageSize={pageSize}
                    page={pageNum}
                    onPageChange={setPageNum}
                    onRowClick={handleRowClick}
                    onPageSizeChange={setPageSize}
                    onSortModelChange={handleSortModelChange}
                    rowsPerPageOptions={[50,100,250,500]}
                />
            </Paper>

            <Box display="flex" alignItems="flex-start">
                <LookupCase />
                <div style={{marginLeft: 'auto', textAlign: 'center'}}>
                    <LoadingButton
                        data-testid="export-csv"
                        startIcon={<GetApp />}
                        style={{marginBottom: 12}}
                        onClick={exportCSV}
                        loading={csvDownloading}
                        disabled={!cases?.content.length}
                    >
                        Download {showAssessorCases ? 'my' : 'all'} {showDraftCases ? 'draft' : ''} cases
                    </LoadingButton>
                    {showProgress && (
                        <>
                            <Typography>{progressLabel}</Typography>
                            <LinearProgressWithLabel  classes={{ bar: classes.progressBar }} value={progressPercentage} />
                        </>
                    )}
                </div>
            </Box>
        </div>
    );
}

CasesPage.propTypes = {
    showToast: PropTypes.func
}

export default CasesPage;