import React from 'react';
import {Breadcrumbs, Container, Link as MaterialLink, Typography} from "@mui/material";
import {Link as ReactLink, useHistory} from "react-router-dom";
import CreateOperatorUserForm from "./CreateOperatorUserForm"
import {OperatorUserFormProvider} from "./OperatorUserFormContext";

const CreateOperatorUserPage = () => {
    const history = useHistory();
    const operatorUser = history?.location?.state?.operatorUser;

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '28px'}}>
                <MaterialLink component={ReactLink} to="/" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <MaterialLink component={ReactLink} to="/operators" className={"App-Breadcrumb-Link"}>
                    <u>Operator Management</u>
                </MaterialLink>
                <MaterialLink component={ReactLink} to="/operators/users" className={"App-Breadcrumb-Link"}>
                    <u>View Operator Users</u>
                </MaterialLink>
                <Typography color="textPrimary">{operatorUser ? `Edit ${operatorUser.name}` : "Add Operator User"}</Typography>
            </Breadcrumbs>
            <Container>
                <Typography variant="h1" gutterBottom>{operatorUser ?  `Edit ${operatorUser.name}` : "New Operator User"}</Typography>
                <OperatorUserFormProvider>
                    <CreateOperatorUserForm operatorUser={operatorUser} />
                </OperatorUserFormProvider>
            </Container>
        </div>
    )
}

CreateOperatorUserPage.propTypes = {}

export default CreateOperatorUserPage