import {useEffect, useState} from "react";

let mountedComponents = {};

const notifyMountedComponents = (key, newValue) => {
    if (!mountedComponents || !mountedComponents[key]) {
        return;
    }
    Object.values(mountedComponents[key]).forEach(u => u(newValue));
};

export const storageType = {
    LOCAL: "local",
    SESSION: "session",
}

export const setItem = (type, key, value) => {
    switch (type) {
        case storageType.LOCAL:
            localStorage.setItem(key, JSON.stringify(value));
            break;
        case storageType.SESSION:
        default:
            sessionStorage.setItem(key, JSON.stringify(value));
            break;
    }
}

export const getItem = (type, key) => {
    switch (type) {
        case storageType.LOCAL:
            return localStorage.getItem(key);
        case storageType.SESSION:
        default:
            return sessionStorage.getItem(key);
    }
}

export const removeItem = (type, key) => {
    switch (type) {
        case storageType.LOCAL:
            localStorage.removeItem(key);
            break;
        case storageType.SESSION:
        default:
            sessionStorage.removeItem(key);
            break;
    }
}

export const clear = (type) => {
    switch (type) {
        case storageType.LOCAL:
            localStorage.clear();
            break;
        case storageType.SESSION:
        default:
            sessionStorage.clear();
            break;
    }
}

export const useStorage = (type, key, initialValue) => {
    let defaultValue = '';
    try {
        defaultValue = JSON.parse(getItem(type, key)) || initialValue;
    } catch (e) {
        defaultValue = initialValue;
    }
    const [value, setValue] = useState(defaultValue);
    const componentId = useState(Math.random().toString())[0];

    useEffect(() => {
        mountedComponents[key] = mountedComponents[key] || {};
        mountedComponents[key][componentId] = setValue;
        return () => delete mountedComponents[key][componentId];
    }, [key, componentId]);

    return [
        value,
        (newValue) => {
            setItem(type, key, newValue);
            notifyMountedComponents(key, newValue);
        }
    ];
};
