import {UserToken} from "../model/User";
import jwt_decode from "jwt-decode";
import {JwtContent} from "./TokenHelper";
import {getItem, storageType} from "../store";

export const CREATE_OPERATOR = "CREATE_OPERATOR";
export const VIEW_OPERATOR = "VIEW_OPERATOR";
export const ADD_CHARGE = "ADD_CHARGE";
export const VIEW_ASSESSOR = "VIEW_ASSESSOR";
export const CREATE_ASSESSOR = "CREATE_ASSESSOR";
export const EDIT_ASSESSOR = "EDIT_ASSESSOR";

export const getAuthorities = (): string[] => {
    const rawUser = getItem(storageType.SESSION, 'user')
    if (!rawUser) {
        return []
    }
    const userToken: UserToken  = JSON.parse(rawUser) as unknown as UserToken
    try {
        const decoded = jwt_decode<JwtContent>(userToken.token);
        return decoded.authorities || []
    } catch (err) {
        return []
    }
}

export const hasAuthority = (authority: string): boolean => {
    const userAuthorities = getAuthorities();
    return userAuthorities.includes(authority);
}

export const hasAuthorities = (...authorities: string[]): boolean => {
    const userAuthorities = getAuthorities();
    return authorities.every(x => userAuthorities.includes(x));
}

export const hasSomeAuthorities = (...authorities: string[]): boolean => {
    const userAuthorities = getAuthorities();
    return authorities.some(x => userAuthorities.includes(x));
}