import {apiUrl} from "./apiEnv";
import {axios} from "./apiWrapper";
import {User} from "../model/User"
import {AxiosResponse} from "axios";
import {Operator, PagedOperators} from "../model/Operator";
import {useEffect, useState} from "react";

export const getAuthenticationToken = async (username: string, password: string): Promise<string> => {
    return await axios.post(`${apiUrl}/security/authenticate`, {
        username: username,
        password: password
    }).then((response: AxiosResponse<AuthToken>) => {
        return response.data.token;
    })
};

export const getUsers = async (activeOnly: boolean): Promise<User[]> => {
    return axios.get(`${apiUrl}/security/users?user_filter=ASSESSOR&active_only=${activeOnly}`)
        .then((response: AxiosResponse<UsersResponse>) => {
        return response.data.users
    })
}

export const useUsers = (activeOnly: boolean) => {
    const [isLoading, setIsLoading] = useState(true);
    const [assessorUsers, setAssessorUsers] = useState([] as User[]);
    const [error, setError] = useState(null);

    useEffect(() => {
        getUsers(activeOnly)
            .then((result) => {
                setAssessorUsers(result)
            })
            .catch(error => {
                setError(error.message)
            }).finally(() => {
            setIsLoading(false);
        });
    }, [activeOnly]);

    return {
        isLoading,
        assessorUsers,
        error
    };
}

export const getRoles = async (): Promise<Role[]> => {
    return axios.get(`${apiUrl}/security/roles`)
        .then((response: AxiosResponse<Role[]>) => {
            return response.data;
        })
}

export const useRoles = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([] as Role[]);
    const [error, setError] = useState(null);

    useEffect(() => {
        getRoles()
            .then((result) => {
                setRoles(result)
            })
            .catch(error => {
                setError(error.message)
            }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    return {
        isLoading,
        roles,
        error
    };
}

export const getOperators = async (): Promise<PagedOperators> => {
    return axios.get('/security/operators').then((res: AxiosResponse<PagedOperators>) => res.data)
}

export const getOperator = async (operatorCode: string): Promise<Operator|undefined> => {
    const operators = await axios.get("/security/operators").then((res: AxiosResponse<PagedOperators>) => {
        return res.data.content
    });
    return operators.find((operator) => operator.operatorCode === operatorCode);
}

export interface AuthToken {
    token: string
    expires: string
}

export interface UsersResponse {
    users: User[]
}

export interface Role {
    id: number,
    code: string,
    name: string
}