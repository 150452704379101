import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Dialog, DialogTitle, DialogContent, DialogActions, FormHelperText, MenuItem, Button} from "@mui/material";
import {AttachFile} from '@mui/icons-material'
import ValidInput from "./ValidInput";
import Select from "./Select";

const defaultValues = {
    type: '',
    title: '',
    description: '',
    filename: ''
}

const FileAttachment = ({name, label, children, onUpload, disabled}) => {
    const [open, setOpen] = useState(false)
    const [filename, setFilename] = useState('No file chosen')
    const [values, setValues] = useState(defaultValues);
    const [errors, setErrors] = useState(defaultValues)
    const [fileKey, setFileKey] = useState('');
    const [submitEnabled, setSubmitEnabled] = useState(false)
    const maxFileSizeInMBs = 30;

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setFilename('No file chosen')
        setFileKey(Date.now())
        setValues(defaultValues)
        setErrors(defaultValues)
        setSubmitEnabled(false)
        setOpen(false);
    }

    function handleInput(e) {
        const {name, value} = e.target;
        const newValues = {...values, [name]: value}
        if (e.target.type === 'file') {
            setFilename(e.target.files[0].name)
            newValues.filename = e.target.files[0]
            setErrors({})
        }
        setValues(newValues)
        const emptyValues = Object.values(newValues).filter(v => v.length === 0)
        setSubmitEnabled(emptyValues.length === 0)
    }

    function handleSubmit() {
        let updatedErrors = validate();
        setErrors(updatedErrors);
        if (Object.keys(updatedErrors).length === 0) {
            onUpload(name, values)
            handleClose()
        }
    }

    function validate() {
        let errors = {};
        if (!values.filename.name.length) {
            errors.filename = "this is a required field"
        }
        if (values.filename.size > maxFileSizeInMBs * Math.pow(2, 20)) {
            errors.filename = "file size cannot exceed " + maxFileSizeInMBs + "MB.";
        }
        if (!values.type.length) {
            errors.type = "description field is required"
        }
        if (!values.title.length) {
            errors.title = "description field is required"
        }
        if (!values.description.length) {
            errors.description = "support field is required"
        }
        return errors
    }

    return (
        <div style={{marginBottom:'20px'}}>
            <label style={{display: 'block', marginBottom: '15px'}}>{label}</label>
            <Button data-testid="show-dialog" onClick={handleOpen} startIcon={<AttachFile />} disabled={disabled}>Attach File</Button>
            <Dialog open={open} onClose={handleClose} fullWidth data-testid="file-dialog">
                <DialogTitle>{label}</DialogTitle>
                <DialogContent style={{marginBottom: '20px'}}>
                    <Select native label="Type of document" name="type" onChange={handleInput} value={values.type} autoFocus>
                        <MenuItem value="MOTORIST_CONVERSATION">Conversation</MenuItem>
                        <MenuItem value="MOTORIST_EMAIL">Email</MenuItem>
                        <MenuItem value="MOTORIST_LETTER">Letter</MenuItem>
                        <MenuItem value="MOTORIST_OFFICIAL_DOCUMENT">Official document</MenuItem>
                        <MenuItem value="MOTORIST_PHOTO">Photo</MenuItem>
                        <MenuItem value="MOTORIST_VIDEO">Video</MenuItem>
                        <MenuItem value="MOTORIST_OTHER">Other</MenuItem>
                        <MenuItem value="MOTORIST_THIRD_PARTY">Third party</MenuItem>
                    </Select>
                    <FormHelperText error={errors.type !== ''}>{errors.type}</FormHelperText>
                    <ValidInput id={`question-description`} name="title" label="What is this file?" errorText={errors.title} onInput={handleInput} required />
                    <ValidInput multiline id={`question-support`} name="description" label="How does it support your appeal?" errorText={errors.description} onInput={handleInput} required />
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Button component="label">
                            Choose File
                            <input
                                type="file"
                                name="filename"
                                onChange={handleInput}
                                key={fileKey}
                                required
                                hidden
                            />
                        </Button>
                        <div style={{marginLeft: '20px'}}>{filename}</div>
                    </div>
                    <FormHelperText error={errors.filename !== ''} data-testid="file-error">{errors.filename}</FormHelperText>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleClose} data-testid="cancel-upload">Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!submitEnabled} data-testid="file-upload">Add</Button>
                </DialogActions>
            </Dialog>
            {children}
        </div>
    )
}

FileAttachment.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.any,
    onUpload: PropTypes.func,
    disabled: PropTypes.bool
}

export default FileAttachment