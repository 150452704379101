import React from "react";
import Typography from "@mui/material/Typography";
import {Divider, Box} from "@mui/material";
import {CalendarToday} from "@mui/icons-material";

interface SummaryFieldProps {
    label: string,
    value: string,
    isDate?: boolean
}

export const SummaryField = (props: SummaryFieldProps) => {
    return (
        <Box py={1}>
            <Typography variant="subtitle2">
                <b>{props.label}</b>
            </Typography>
            <Typography style={{fontSize: "small", padding: '5px 0', display: 'flex', justifyContent: 'space-between'}}>
                {!props.value ? "-" : props.value}
                {props.isDate === true && <CalendarToday fontSize={"small"} color="action" />}
            </Typography>
            <Divider/>
        </Box>
    );
}