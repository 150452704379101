import React, {useState} from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    CardContent, CircularProgress,
    Box, Tabs
} from '@mui/material';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useAnswers} from "../../api/answersApi";
import TabPanel from "./TabPanel"
import AnswersTab from "./AnswersTab";
import tabProps from "../../utils/tabProps";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const AnswersView = ({verificationCode}) => {
    const [appealTabValue, setAppealTabValue] = useState(0);
    const {
        isLoading,
        groundsForAppealList
    } = useAnswers(verificationCode)

    const classes = useStyles();

    const handleChangeAppealTab = (event, newAppealTabValue) => {
        setAppealTabValue(newAppealTabValue);
    };

    return (
        <Box pt={1}>
            {isLoading
                ?
                <CardContent><CircularProgress/></CardContent>
                :
                <Grid container spacing={3}>
                    <Grid item xs style={{maxWidth: 285}}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={appealTabValue}
                            TabIndicatorProps={{
                                style: {
                                    display: "none",
                                },
                            }}
                            onChange={handleChangeAppealTab}
                            aria-label="Vertical tabs example"
                            style={{maxWidth: 265}}
                            className={classes.tabs}
                        >
                            {groundsForAppealList.map((gfa, index) => (
                                <AnswersTab key={index} label={gfa.groundsForAppeal} {...tabProps(index)}
                                         data-testid="appeal_tab"/>
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={9} style={{padding: 0}}>
                        {groundsForAppealList.map((ground, groundIndex) => (
                            <TabPanel key={groundIndex} value={appealTabValue} index={groundIndex}
                                      style={{textAlign: 'left', padding: 0}}>

                                <Accordion style={{backgroundColor: '#0250A10B'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography component={'span'} style={{
                                            color: '#0E2361',
                                            fontWeight: "bold"
                                        }}>Grounds for Appeal</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {ground.groundsForAppeal}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {ground.subcategories.map((sub, subIndex) => (
                                    <div key={subIndex}>
                                        <Accordion style={{backgroundColor: '#0000000A'}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography component={'span'}>
                                                    {sub.groundsForAppealSubcategory}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                            </AccordionDetails>
                                        </Accordion>
                                        {sub.answeredQuestions.map((answeredQuestion, answerIndex) => (
                                            <Accordion key={answerIndex} style={{backgroundColor: '#0000000A'}}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography component={'span'}>
                                                        {answeredQuestion.question}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography component={'span'} style={{whiteSpace : 'pre-wrap'}}>
                                                        {answeredQuestion.answer}</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div>
                                ))}
                            </TabPanel>
                        ))}
                    </Grid>
                </Grid>
            }
        </Box>
    );
};

AnswersView.propTypes = {
    verificationCode: PropTypes.any.isRequired
};

export default AnswersView;