import React, {useContext, useEffect, useState} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import AppealQuestions from "./AppealQuestions";
import PropTypes from "prop-types";
import {CaseFormContext} from "../CaseFormContext";
import {evidenceQnCodeRegex} from "./QuestionConstants";

const Clarification = ({clarification}) => {
    const [showOptions, setShowOptions] = useState(false)
    const {answers, setAnswers} = useContext(CaseFormContext)

    function handleCheckbox(e) {
        const {checked} = e.target;
        setShowOptions(checked)
        if (!checked) {
            removeAnswers(clarification.questions)
        } else {
            clarification.questions
                .filter(question => !question.questionCode.match(evidenceQnCodeRegex))
                .forEach(question => setAnswers(prevAnswers => {
                    return {...prevAnswers, [question.questionCode]: ''}
                }));
        }
    }

    function removeAnswers(answersToRemove) {
        let updatedAnswers = {...answers}
        answersToRemove.forEach(answer => {
            delete updatedAnswers[answer.questionCode]
        })
        setAnswers(updatedAnswers)
    }

    useEffect(() => {
        Object.keys(answers).some(key => {
            if (key.substring(0, clarification.clarificationCode.length) === clarification.clarificationCode) {
                setShowOptions(true)
                return true
            }
            return false
        })
    }, [answers, clarification.clarificationCode])

    return (
        <div style={{paddingLeft: '30px'}}>
            <FormControlLabel
                data-testid={`clarification-checkbox-${clarification.clarificationCode}`}
                control={<Checkbox name={clarification.clarificationCode} checked={showOptions} color="primary" />}
                label={clarification.clarification}
                labelPlacement="end"
                onChange={handleCheckbox}
            />
            {showOptions && <AppealQuestions questions={clarification.questions} />}
        </div>
    )
}

Clarification.propTypes = {
    clarification: PropTypes.shape({
        clarificationCode: PropTypes.string,
        clarification: PropTypes.string,
        questions: PropTypes.arrayOf(PropTypes.shape({
            questionCode: PropTypes.string,
            question: PropTypes.string,
        }))
    })
}

export default Clarification