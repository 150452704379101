/* eslint no-unused-vars: "off" */
import React, {useState} from 'react';
import {
    CardContent,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Theme, Button,
} from '@mui/material';
import {
    EvidenceTarget,
    downloadFileFromS3FromUrl,
    getAttachmentsForEvidence,
    uploadAppellantFiles,
    uploadOperatorFiles,
    useEvidence
} from "../../api/evidenceApi";
import {makeStyles} from '@mui/styles';
import Typography from "@mui/material/Typography";
import {UploadOperatorFiles} from "./UploadOperatorFiles";
import {UploadAppellantFiles} from "./UploadAppellantFiles";
import DocumentRow from "./DocumentRow";
// @ts-ignore
import {v4 as uuidV4} from "uuid";
import JSZip from "jszip";
import AlertDialog from "../AlertDialog";
import useSnackBar from "../SnackBarContext";
import FileSaver from "file-saver";
import featureIsEnabled from "../../utils/featureFlag";
import {deduplicateNames} from "../../utils/fileUtils";
import {hasAuthority} from "../../auth/AuthorityHelper";

interface DocumentsViewProps {
    operatorCode: string,
    foreignKey: string;
    documentTarget: string
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        paper: {
            color: theme.palette.primary.main,
        }
    }
});

export const DocumentsView = (props: DocumentsViewProps) => {
    const {
        operatorCode,
        foreignKey,
        documentTarget = EvidenceTarget.case
    } = props;
    const [refreshKey, setRefreshKey] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const {isLoading, error, evidenceForCase} = useEvidence(documentTarget, foreignKey, refreshKey);
    const classes = useStyles();
    const {showToast} = useSnackBar();

    const reloadDocuments = () => {
        setRefreshKey(refreshKey + 1);
    }

    async function handleRefresh() {
        setRefreshKey(refreshKey + 1);
        setAlertOpen(false);
    }

    async function handleAllFileDownload() {
        try {
            setIsDownloading(true);
            const downloadAllFilesSignedUrls = await getAttachmentsForEvidence(foreignKey);
            const allDownloadedFiles = downloadAllFilesSignedUrls
                .map((downloadFile:any) => downloadFileFromS3FromUrl(downloadFile));
            Promise.all(allDownloadedFiles).then(downloadedFiles => {
                const zip = new JSZip();
                deduplicateNames(downloadedFiles)
                    .map(data => zip.file(data.fileName,
                    data.documentData.then((res:any) => res.data)));
                return zip.generateAsync({type:"blob"})
                    .then(content => {
                            setIsDownloading(false);
                            FileSaver.saveAs(content, `case-documents-${foreignKey}.zip`)
                        }
                    );
            });
        } catch (e: any) {
            setIsDownloading(false);
            showToast('Unable to download files' + e.message, 'error', 1.5e4);
        }
    }

    async function handleAppellantFileSubmit(files: AppellantFile[]) {
        try {
            const correlationId = uuidV4();
            const errorsRes = await uploadAppellantFiles(documentTarget, foreignKey, operatorCode, correlationId, files);
            const errors = errorsRes.filter(r => !!r.errorMessage)
            if (errors.length) {
                showToast('Some files could not be uploaded: ' + errors[0].errorMessage, 'error', 1.5e4);
            } else {
                setAlertOpen(true);
            }
        } catch (e: any) {
            showToast('Some files could not be uploaded: ' + e.message, 'error', 1.5e4);
        }
    }

    async function handleOperatorFileSubmit(files: File[]) {
        try {
            const errorsRes = await uploadOperatorFiles(documentTarget, foreignKey, operatorCode, files);
            const errors = errorsRes.filter(r => !!r.errorMessage)
            if (errors.length) {
                showToast('Some files could not be uploaded: ' + errors[0].errorMessage, 'error', 1.5e4);
            } else {
                setAlertOpen(true);
            }
        } catch (e: any) {
            showToast('Some files could not be uploaded: ' + e.message, 'error', 1.5e4);
        }
    }

    return (
        <Paper className={classes.paper}>
            <Box p={2} display="flex" justifyContent="space-between">
                <Typography variant="subtitle1">Documents</Typography>
                <Box display="flex" gridTemplateColumns="1fr 2fr" gap={1}>
                    <UploadOperatorFiles onUpload={handleOperatorFileSubmit} />
                    <UploadAppellantFiles onUpload={handleAppellantFileSubmit} />
                    {featureIsEnabled('case-download-all-documents' )
                        && (<Button data-testid="download-all-files" onClick={handleAllFileDownload}
                            disabled={evidenceForCase.length === 0}>Download all files
                            { isDownloading &&
                            <div>{`\u00A0`}
                                <CircularProgress data-testid="download-all-files-progress" color="inherit" size={17}/>
                            </div>
                            }
                        </Button>)
                    }
                </Box>
            </Box>
            {isLoading
                ?
                <CardContent><CircularProgress/></CardContent>
                :
                <>
                <TableContainer>
                    <Table aria-label="customized table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Document Name</TableCell>
                                <TableCell align="left">Category</TableCell>
                                <TableCell align="left">Created On</TableCell>
                                <TableCell align="center">Download</TableCell>
                                { hasAuthority("DELETE_DOCUMENT") && (<TableCell align="center">Delete</TableCell>) }
                            </TableRow>
                        </TableHead>
                        <TableBody style={{maxHeight: 400}}>
                            {evidenceForCase.map((row: any, i: number) => (
                                <DocumentRow key={i} row={row} onChange={reloadDocuments} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {(!error && evidenceForCase.length === 0) && <CardContent>No documents found.</CardContent>}

                <AlertDialog
                    title="Files successfully uploaded"
                    open={alertOpen}
                    setOpen={setAlertOpen}
                    onConfirm={handleRefresh}>
                    Uploaded documents are available after virus scan.
                </AlertDialog>
                </>
            }
        </Paper>
    );
};

interface AppellantFile {
    name: string,
    values: {
        type: string
        title: string
        description: string
        filename: File
    }
}
