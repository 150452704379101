import {apiUrl} from "./apiEnv";
import {axios} from "./apiWrapper";
import {useEffect, useState} from "react";

function getOperators(page, size) {
    return axios.get(`${apiUrl}/security/operators`, {
        params: {
            page, size
        }
    });
}
export const useOperators = (page, size) => {
    const [isLoading, setIsLoading] = useState(true);
    const [operators, setOperators] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        getOperators(page, size)
            .then((result) => {
            setOperators(result.data)
        }).catch(error => {
            setError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });

    }, [page, size]);

    return {
        isLoading,
        operators,
        error
    };
}

export const addOperator = async (operator) => {
    let result = {errorMessage: null, status: null };
    try{
        const res = await axios.post(`${apiUrl}/security/operators`, operator);
        result.status = res.status;
    }catch(e){
        result.errorMessage = e.message;
    }
    return result;
};

export const updateOperator = async (operator) => {
    let result = { errorMessage: null, status: null };
    try {
        const res = await axios.put(`${apiUrl}/security/operators/${operator.operatorCode}`, operator);
        result.status = res.status;
    } catch(e) {
        result.errorMessage = e.message;
    }
    return result;
};