import React, {useState} from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    FormHelperText,
    Button,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Add} from "@mui/icons-material";
import {ReactComponent as Logo} from '../../images/Inbox-Arrow-Up.svg';
import OperatorFileAttachments from "./OperatorFileAttachments";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export const UploadOperatorFiles = ({ onUpload }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [rawEvidenceList, setRawEvidenceList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const maxFileSizeInMBs = 30;
    function handleClose() {
        setIsOpen(false)
        setRawEvidenceList([])
        setErrorMessage('');
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        await onUpload(rawEvidenceList)
        setIsOpen(false)
        setIsLoading(false)
        setRawEvidenceList([])
        setErrorMessage('');
    };

    const handleErrorMessage = (message = 'The operation cannot be completed at this time') => {
        setErrorMessage(message);
        setIsOpen(true);
    }

    const selectFile = (event) => {
        if (event.target.files[0].size > maxFileSizeInMBs * Math.pow(2, 20)) {
            handleErrorMessage('The file chosen is larger than ' + maxFileSizeInMBs + 'MB.');
        } else {
            const file = event.target.files[0];
            file.title = event.target.files[0].name;
            setRawEvidenceList(rawEvidenceList => [...rawEvidenceList, file]);
            setErrorMessage('');
        }
    }

    return (
        <div>
            <Button data-testid="upload-operator-files" variant="outlined" startIcon={<Add />} onClick={() => setIsOpen(true)}>Upload Operator Files</Button>
            <Dialog
                data-testid={'upload-operator-files-dialog'}
                open={isOpen}
                onClose={handleClose}
                fullWidth>
                <DialogTitle>Upload Operator Files</DialogTitle>
                <Box px={3}>
                    <div style={{marginTop: '10px'}}>
                        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <input
                                    data-testid="file_select"
                                    accept="*/*"
                                    className={classes.input}
                                    id="contained-button-file"
                                    type="file"
                                    align="left"
                                    onChange={selectFile}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button
                                        data-testid="upload_evidence_button"
                                        component="span"
                                        startIcon={<Logo className='logo' />}
                                    >File Upload</Button>
                                </label>
                            </Grid>
                            <Grid item>
                                <FormHelperText error={errorMessage !== ''} data-testid="file-error">{errorMessage}</FormHelperText>
                            </Grid>
                        </Grid>
                    </div>
                    <OperatorFileAttachments files={rawEvidenceList} setFiles={setRawEvidenceList}/>
                </Box>
                <Box px={2} pb={2}>
                    <DialogActions>
                        <Button data-testid="upload-operator-files-cancel" onClick={handleClose} color="default">Cancel</Button>
                        <LoadingButton data-testid="upload-operator-files-submit"  disabled={!rawEvidenceList.length} loading={isLoading} onClick={handleSubmit}>Upload</LoadingButton>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );
}

UploadOperatorFiles.propTypes = {
    onUpload: PropTypes.func
}