import React from 'react';
import {Redirect, Route} from "react-router-dom";

interface PrivateRouteProps {
    isAuthenticated: boolean;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
    const { isAuthenticated, ...rest } = props;
    if (!isAuthenticated) {
        return <Redirect to='/login'/>
    }

    return (
        <Route {...rest} />
    );
}
