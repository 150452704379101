import React, { useState, createContext, useContext } from "react";
import {storageType, setItem, getItem, removeItem} from "../store";
import {getAuthenticationToken} from "../api/securityApi";
import {UserToken} from "../model/User";
import {isActive, validateToken} from "./TokenHelper";

interface AuthProviderInterface {
    authed: boolean
    loading: boolean
    login: (username: string, password: string) => Promise<UserToken>
    logout: () => void
}

const authContext = createContext<AuthProviderInterface>({} as AuthProviderInterface);

function useAuth(): AuthProviderInterface {
    const [authed, setAuthed] = useState(getInitialAuth());
    const [loading, setLoading] = useState(false);

    function login(username: string, password: string) {
        removeItem(storageType.SESSION, 'user')
        setLoading(true);

        return getAuthenticationToken(username, password)
            .then(token => {
                validateToken(token);
                const userToken: UserToken = { username, token }
                setItem(storageType.SESSION, 'user', userToken)
                setAuthed(true);
                return userToken
            })
            .finally(() => {
                setLoading(false);
            })
    }

    function logout() {
        return new Promise<void>((cb) => {
            setAuthed(false);
            removeItem(storageType.SESSION, 'user')
            if (typeof cb === "function") {
                cb();
            }
        });
    }

    function getInitialAuth() {
        const user = getItem(storageType.SESSION, "user");

        if (user) {
            const {token} = JSON.parse(user)
            return isActive(token)
        }

        return false
    }

    return {
        authed,
        login,
        logout,
        loading,
    };
}

export function AuthProvider(props: Props) {
    const { children } = props
    const auth = useAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

interface Props {
    children: any
}

export default function AuthConsumer() {
    return useContext(authContext);
}
