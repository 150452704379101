import React, {useState} from "react";
import PropTypes from "prop-types";
import {v4 as uuidV4} from "uuid";
import {draftSchema, postalSchema, validate, validateAnswers} from "./schema";
import {toCreateCase, toDraftCase} from "../../model/CreateCase";
import {createCase, getCase} from "../../api/caseApi";
import {createDraftCase, getDraftCase, promoteDraftCase, updateDraftCase} from "../../api/draftApi";
import {EvidenceTarget, uploadAppellantFiles} from "../../api/evidenceApi";
import {useHistory} from "react-router-dom";
import {deleteDraft} from "../../api/draftApi";
import useSnackBar from "../../components/SnackBarContext";

export const CaseFormContext = React.createContext({
    formValues: {},
    setFormValues: () => {},
    formErrors: {},
    setFormErrors: () => {},
    answers: {},
    setAnswers: () => {},
    questionErrors: {},
    setQuestionErrors: () => {},
    files: [],
    setFiles: () => {},
    isDraft: false,
    setIsDraft: () => {},
    draftId: null,
    setDraftId: () => {},
    handleInput: () => {},
    handleSubmit: () => {},
    loadDraftData: () => {},
    isLoading: false,
    isSaving: false
})

const defaultFormValues = {
    draftCaseId: '',
    answers: {},
    verificationCode: '',
    operatorCode: '',
    correlationId: uuidV4(),
    caseSource: 'POSTAL',
    showMotorist: false,
    motoristTitle: 'Mr',
    motoristFirstName: '',
    motoristLastName: '',
    motoristBuildingNumber: '',
    motoristAddressLine1: '',
    motoristAddressLine2: '',
    motoristCounty: '',
    motoristPostTown: '',
    motoristPostcode: '',
    appellantTitle: 'Mr',
    appellantFirstName: '',
    appellantLastName: '',
    appellantPhoneNumber: '',
    appellantEmail: '',
    appellantBuildingNumber: '',
    appellantAddressLine1: '',
    appellantAddressLine2: '',
    appellantCounty: '',
    appellantPostTown: '',
    appellantPostcode: '',
    appellantPcnNumber: '',
    vehicleRegistration: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleColor: '',
    taxed: '',
    mot: '',
    motDetails: '',
    dateOfFirstRegistration: '',
    yearOfManufacture: '',
    cylinderCapacity: '',
    co2Emissions: '',
    fuelType: '',
    revenueWeight: '',
    taxDetails: '',
    taxStatus: '',
    transmission: '',
    typeApproval: '',
    vin: '',
    wheelPlan: '',
}

const defaultFormErrors = {}

const CaseFormProvider = ({children}) => {
    const [formValues, setFormValues] = useState({...defaultFormValues})
    const [formErrors, setFormErrors] = useState({...defaultFormErrors})
    const [answers, setAnswers] = useState({})
    const [questionErrors, setQuestionErrors] = useState({})
    const [files, setFiles] = useState([])
    const [isDraft, setIsDraft] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [draftId, setDraftId] = useState(null)
    const {showToast} = useSnackBar();
    const history = useHistory()

    function handleInput(e) {
        let { name, value, type } = e.target;
        let updatedValues = {}

        if (type === 'checkbox') {
            value = e.target.checked
        }

        // Update operator code using verification code
        if (name === 'verificationCode') {
            updatedValues.operatorCode = value.substring(0, 3)
        }

        updatedValues[name] = value;
        setFormValues(Object.assign({...formValues}, updatedValues))
    }

    function handleSubmit() {
        const schema = isDraft ? draftSchema : postalSchema;

        const updatedFormErrors = validate(formValues, schema)
        setFormErrors(updatedFormErrors)

        const updatedQuestionErrors = validateAnswers(answers, isDraft)
        setQuestionErrors(updatedQuestionErrors)
        if (Object.keys(updatedFormErrors).length === 0 && Object.keys(updatedQuestionErrors).length === 0) {
            isDraft ? postDraftData() : postCaseData();
        } else {
            showToast('There are errors in the form, please correct them before continuing', 'error')
        }
    }

    function loadDraftData(guid) {
        setIsLoading(true)
        getDraftCase(guid)
            .then(res => {
                let {answers, ...values} = Object.assign({}, formValues, res.data.data)
                values.operatorCode = values.verificationCode.substring(0, 3)
                setIsDraft(true)
                setDraftId(guid)
                setFormValues(values)
                setAnswers(answers)
            })
            .catch(() => showToast('There was a problem loading this draft', 'error'))
            .finally(() => setIsLoading(false))
    }

    function postDraftData() {
        setIsSaving(true);
        formValues.answers = answers
        const draftCaseData = toDraftCase(formValues)

        if (draftId) {
            return updateDraftCase(draftCaseData, draftId)
                .then(() => {
                    showToast('Draft has been updated', 'success')
                })
                .catch(error => showError(error))
                .finally(() => setIsSaving(false));
        }

        createDraftCase(draftCaseData)
            .then((res) => {
                showToast('Draft has been saved', 'success')
                window.history.replaceState({}, null, `/case/create/${res.id}`)
                setDraftId(res.id)
            })
            .catch(error => showError(error))
            .finally(() => {
                setIsSaving(false)
            });
    }

    async function postCaseData() {
        setIsSaving(true);
        formValues.answers = answers
        const createCaseModel = toCreateCase(formValues, draftId);
        try {
            await checkVerificationCode()
            await uploadAppellantFiles(EvidenceTarget.case, formValues.verificationCode, formValues.operatorCode, defaultFormValues.correlationId, files)
            const newCase = await createCase(createCaseModel)
            if (draftId) {
                await promoteDraftCase(draftId, formValues.verificationCode)
                await deleteDraft(draftId)
            }
            history.push(`/case/${newCase.verificationCode}`)
        } catch (error) {
            showError(error)
        } finally {
            setIsSaving(false)
        }
    }

    async function checkVerificationCode() {
        return new Promise((resolve, reject) => {
            getCase(formValues.verificationCode)
                .then(() => {
                    setFormErrors(prevState => {
                        return {...prevState, verificationCode: 'Duplicate case verification code'}
                    })
                    reject(`Duplicate case verification code: ${formValues.verificationCode}`)
                })
                .catch(() => {
                    resolve()
                })
        })
    }

    const showError = (error) => {
        const errorMessage =
            typeof error === 'string'
                ? error
                : error?.status
                    ? `Error creating case. Status: ${error?.status}, Code: ${error?.code}`
                    : 'Unable to create case';
        showToast(errorMessage, 'error');
    };

    return (
        <CaseFormContext.Provider value={{
            formValues,
            setFormValues,
            formErrors,
            setFormErrors,
            answers,
            setAnswers,
            questionErrors,
            setQuestionErrors,
            files,
            setFiles,
            isDraft,
            setIsDraft,
            draftId,
            setDraftId,
            handleInput,
            handleSubmit,
            loadDraftData,
            isLoading,
            isSaving
        }}>
            {children}
        </CaseFormContext.Provider>
    )
}

CaseFormProvider.propTypes = {
    children: PropTypes.element,
    showToast: PropTypes.func
}

export default CaseFormProvider

// HOC for providing access to the CaseFormContext
export const withCaseFormContext = (WrappedComponent) => {
    const HocComponent = () => {
        return <CaseFormProvider><WrappedComponent /></CaseFormProvider>
    }
    HocComponent.displayName = 'WithCaseFormContext';
    return HocComponent;
}