/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {TextField} from "@mui/material";
import '../../src/App.css';

const Input = ({dataTestId, name, type, onChange, label, onKeyDown}) => {

    const [showError, setShowError] = useState(false);

    const onBlur = (event) => {
        !event.target.value.trim() ? setShowError(true) : setShowError(false);
    }

    return (
        <div className={'App-Input-TextBox'}>
            <TextField data-testid={dataTestId}
                       name={name}
                       type={type}
                       onChange={onChange}
                       onKeyDown={onKeyDown}
                       onBlur={onBlur}
                       variant="filled"
                       label={label}
                       id={"in-" + name}
                       fullWidth
                       error={showError}
                       helperText={showError && `Please enter your ${label.toLowerCase()}`}
                       InputLabelProps={{
                        shrink: true,
                       }} />
        </div>
    );
}

export default Input;