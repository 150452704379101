import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {schema, validate} from "./schema";
import {useHistory} from "react-router-dom";
import {SnackBarContext} from "../../components/SnackBarContext";
import {useUser} from "../../auth/useUser";
import {addAssessorUser, updateAssessorUser} from "../../api/assessorUsersApi";
export const formModes = { CREATE: "create", EDIT: "edit" }

export const AssessorUserFormContext = React.createContext({
    formValues: {},
    setFormValues: {},
    formErrors: {},
    setFormErrors: {},
    formMode: formModes.CREATE,
    setFormMode: {},
    handleInput: {},
    handleSubmit: {},
    isSaving: false
})

const defaultFormValues = {
    roleId: '',
    email: '',
    name: '',
    disabled: 'false',
    accountLocked: 'false'

}

const defaultFormErrors = {}

export const AssessorUserFormProvider = ({children}) => {
    const [formValues, setFormValues] = useState({...defaultFormValues})
    const [formErrors, setFormErrors] = useState({...defaultFormErrors})
    const [formMode, setFormMode] = useState(formModes.CREATE)
    const [isSaving, setIsSaving] = useState(false);
    const {user} = useUser();
    const history = useHistory()
    const {showToast} = useContext(SnackBarContext)

    function handleInput(e) {
        let { name, value, type } = e.target;
        let updatedValues = {}

        if (type === 'checkbox') {
            value = e.target.checked
        }

        updatedValues[name] = value;
        setFormValues(Object.assign({...formValues}, updatedValues))
    }

    async function handleSubmit() {
        const updatedFormErrors = validate(formValues, schema)
        setFormErrors(updatedFormErrors);
        if (Object.keys(updatedFormErrors).length === 0) {
            setIsSaving(true)
            formMode === formModes.EDIT
                ? await submitUpdate()
                : await submitCreate()
        } else {
            showToast('There are errors in the form, please correct them before continuing', 'error');
        }
    }

    const submitCreate = async () => {
        const result = await addAssessorUser(formValues, user?.token)
        if (result.status) {
            setIsSaving(false)
            history.replace('/assessors')
            showToast('Assessor User added successfully')
        } else {
            showToast(`Problem creating assessor user: ${result.errorMessage}`, 'error');
        }
    }

    const submitUpdate = async () => {
        const result = await updateAssessorUser(formValues, user?.token)
        if (result.status) {
            setIsSaving(false)
            history.replace('/assessors')
            showToast('Assessor User updated successfully')
        } else {
            showToast(`Problem updating assessor user: ${result.errorMessage}`, 'error');
        }
    }

    return (
        <AssessorUserFormContext.Provider value={{
            formValues,
            setFormValues,
            formErrors,
            setFormErrors,
            formMode,
            setFormMode,
            handleInput,
            handleSubmit,
            isSaving
        }}>
            {children}
        </AssessorUserFormContext.Provider>
    )
}

AssessorUserFormProvider.propTypes = {
    children: PropTypes.element
}