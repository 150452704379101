import {useEffect, useState} from "react";
import {apiUrl} from "./apiEnv";
import {axios} from "./apiWrapper";

export const useAnswers = (verificationCode) => {
    const [isLoading, setIsLoading] = useState(true);
    const [answers, setAnswers] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;
        const getAnswers = async () => {
            if (verificationCode) {
                return await axios.get(`${apiUrl}/cases/${verificationCode}/answers`)
                    .catch(error => {
                        setError(error.message)
                        return null;
                });
            }
        }
        getAnswers().then((result) => {
            if (mounted) {
                setAnswers(result ? result.data : null);
                setIsLoading(false);
            }
        });

        return () => mounted = false;

    }, [verificationCode]);

    return {
        isLoading: isLoading,
        error: error,
        groundsForAppealList: answers,
    };
}