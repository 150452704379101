import React, {useState, ChangeEvent, useEffect} from 'react';
import {
    Box, Divider, MenuItem, Paper, TextField, Theme, Button, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {submitCaseState} from "../../api/caseApi";
import withSnackbar from "../../components/WithSnackbar";
import StatusChip from "../StatusChip";
import {genericMapper} from "../../utils/stringUtils";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        height: '100%',
        boxSizing: 'border-box',
        paddingBottom: theme.spacing(2)
    },
    statusDisplay: {
        display: 'table',
        '& > div': {
            display: 'table-row',
            '& > *': {
                display: 'table-cell',
                paddingBottom: theme.spacing(1),
                paddingRight: theme.spacing(2)
            }
        }
    },
    formControl: {
        marginBottom: theme.spacing(2)
    },
}));

export const StatusView = withSnackbar((props: StatusViewProps) => {
    const classes = useStyles();
    const [disableUpdate, setDisableUpdate] = useState(true);
    const [status, setStatus] = useState('');
    const [condition, setCondition] = useState('');
    const [statusReason, setStatusReason] = useState('');

    const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStatus(event.target.value as string);
        setCondition("")
        setStatusReason("");
    };

    const handleStatusReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setStatusReason(event.target.value as string);
    };

    const handleConditionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCondition(event.target.value as string);
    };

    useEffect(
        () => {
            if ((status === props.caseObject.status) && (condition === props.caseObject.condition) && (status !== 'ON_HOLD')) {
                setDisableUpdate(true);
            } else if ((status === 'ON_HOLD') && (statusReason) && (statusReason !== "")) {
                setDisableUpdate(false);
            } else if ((status !== 'ON_HOLD') && (condition) && (condition !== "")) {
                setDisableUpdate(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [status, statusReason, condition]
    );

    const handleSubmitUpdate = () => {
        let condition_ = condition === "" ? props.caseObject.condition : condition;
        let statusReason_ = statusReason === "" ? null : statusReason;
        submitCaseState(props.caseObject.verificationCode, status, statusReason_, condition_).then((error) => {
            if (error.errorMessage) {
                props.showToast(`Could not update state: ${error.errorMessage}`, 'error');
            } else {
                props.setCaseObject({
                    ...props.caseObject,
                    status: status,
                    statusReason: statusReason,
                    condition: condition_
                })
                props.showToast('Status Updated', 'success');
            }
        })
        setDisableUpdate(true);
    };


    return (
        <Paper className={classes.paper}>
            <Box p={2}>
                <Typography variant="subtitle1" gutterBottom>Case State</Typography>
                <div className={classes.statusDisplay}>
                    <div>
                        <strong>Status:</strong>
                        <span><StatusChip size="small" label={props.caseObject.status || '-'}
                                          status={props.caseObject.status || '-'}/></span>
                    </div>
                    <div hidden={props.caseObject.status !== "ON_HOLD"}>
                        <strong>Status Reason:</strong>
                        <span>{genericMapper(props.caseObject.statusReason || '-')}</span>
                    </div>
                    <div>
                        <strong>Condition:</strong>
                        <span>{genericMapper(props.caseObject.condition || '-')}</span>
                    </div>
                </div>
            </Box>

            <Divider/>

            <Box p={2} pb={0}>
                <Typography variant="subtitle1" gutterBottom>Issue Case State Change</Typography>
                <div className={classes.formControl}>
                    <TextField
                        label="Status"
                        name="status"
                        id="select-status"
                        variant="filled"
                        value={status}
                        onChange={handleStatusChange}
                        fullWidth
                        select
                    >
                        <MenuItem value={"NEW"}>New</MenuItem>
                        <MenuItem value={"ACTIVE"}>Active</MenuItem>
                        <MenuItem value={"ON_HOLD"}>On Hold</MenuItem>
                    </TextField>
                </div>

                {status === "ON_HOLD" && (
                    <div className={classes.formControl}>
                        <TextField
                            label="On-Hold Reason"
                            name="statusReason"
                            id="select-statusReason"
                            variant="filled"
                            value={statusReason}
                            onChange={handleStatusReasonChange}
                            fullWidth
                            select
                        >
                            <MenuItem value={"THIRD_PARTY"}>Third Party</MenuItem>
                            <MenuItem value={"CASE_ADVICE"}>Case Advice</MenuItem>
                            <MenuItem value={"MITIGATION"}>Mitigation</MenuItem>
                            <MenuItem value={"MOTORIST_COMMENTS"}>Motorist Comments</MenuItem>
                            <MenuItem value={"OTHER"}>Other</MenuItem>
                        </TextField>
                    </div>
                )}

                <div className={classes.formControl}>
                    <TextField
                        label="Condition"
                        name="condition"
                        id="condition-select"
                        variant="filled"
                        value={condition}
                        onChange={handleConditionChange}
                        fullWidth
                        select
                    >
                        <MenuItem disabled={status !== 'NEW'} value={"AWAITING_OPERATOR_EVIDENCE"}>Awaiting Operator
                            Evidence</MenuItem>
                        <MenuItem disabled={status !== 'NEW'} value={"AWAITING_MOTORIST_COMMENT"}>Awaiting Motorist
                            Comments</MenuItem>
                        <MenuItem disabled={status !== 'ACTIVE'} value={"WAITING_FOR_ASSESSMENT"}>Waiting for
                            Assessment</MenuItem>
                    </TextField>
                </div>
            </Box>
            <Box px={2} display="flex" justifyContent="flex-end">
                <Button data-testid="button_submit" onClick={handleSubmitUpdate} disabled={disableUpdate}>Update
                    State</Button>
            </Box>
        </Paper>
    );
})

interface StatusViewProps {
    caseObject: any;
    setCaseObject: any;
    showToast: any;
}