import React from 'react';
import PropTypes from "prop-types";
import {Paper, Box, Typography, Button} from "@mui/material";

const styles = {
    container: {
        background: '#0E2361',
        color: '#fff',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    },
    panel: {
        maxWidth: 550,
        margin: '79px auto',
        textAlign: 'center'
    },
    details: {
        whiteSpace: 'pre-wrap',
        marginBottom: '2rem',
        textAlign: 'left',
        overflow: 'auto',
        fontSize: '0.9em',
        padding: '1rem',
        maxHeight: 300,
        background: '#f1f1f1'
    }
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div style={styles.container}>
                    <Paper sx={styles.panel} elevation={3}>
                        <Box p={2}>
                            <Typography variant="h4" gutterBottom>Something went wrong</Typography>
                            <details style={styles.details}>
                                {this.state.error && this.state.error.toString()}
                                <br/>
                                {this.state.errorInfo && this.state.errorInfo.componentStack}
                            </details>
                            <Button href={"/"}>Reload</Button>
                        </Box>
                    </Paper>
                </div>
            )
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any
}

export default ErrorBoundary