import {displayDateTime} from './dateUtils'
import {formatContactName} from './contactFormatter'

export function mapCasesForExport(cases) {
    return cases.map(caseObject => {
        const newCase = {...caseObject}
        newCase.appellant = formatContactName(newCase.appellant)
        newCase.motorist = formatContactName(newCase.motorist)
        newCase.submissionDate = displayDateTime(newCase.submissionDate)
        newCase.withdrawalDate = displayDateTime(newCase.withdrawalDate)
        newCase.decisionDate = displayDateTime(newCase.decisionDate)
        return newCase;
    })
}