import React, {useContext, useEffect} from 'react';
import {
    Box,
    FormControl,
    FormControlLabel, FormLabel,
    Paper,
    Radio,
    RadioGroup,
    Button, MenuItem, TextField
} from "@mui/material";
import ValidInput from "../../components/ValidInput";
import {AssessorUserFormContext, formModes} from "./AssessorUserFormContext.jsx"
import {useHistory} from 'react-router-dom';
import {PersonAdd, Save, ArrowBack} from "@mui/icons-material";
import PropTypes from "prop-types";
import {useRoles} from "../../api/securityApi";

const CreateAssessorUserForm = ({assessorUser}) => {
    const history = useHistory();
    const {isLoading, roles} = useRoles();
    const {
        formValues,
        setFormValues,
        formErrors,
        formMode,
        setFormMode,
        handleInput,
        handleSubmit
    } = useContext(AssessorUserFormContext)

    useEffect(() => {
        if (assessorUser) {
            setFormMode(formModes.EDIT)
            setFormValues({
                id: assessorUser.id,
                email: assessorUser.email,
                name: assessorUser.name,
                roleId: assessorUser.roleId,
                accountLocked: assessorUser.accountLocked,
                disabled: assessorUser.disabled,
            })
        }
    }, [assessorUser, setFormMode, setFormValues])


    return (
        <Paper elevation={3}>
            <Box p={2}>
                <ValidInput
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                    value={formValues.email}
                    onChange={handleInput}
                    errorText={formErrors.email}
                    inputProps={{maxLength: 100}}
                    required
                />

                <ValidInput
                    id="name"
                    label="Name"
                    name="name"
                    value={formValues.name}
                    onChange={handleInput}
                    errorText={formErrors.name}
                    inputProps={{maxLength: 70}}
                    required
                />

                <Box mb={"30px"}>
                        <TextField
                            label="Role"
                            name="roleId"
                            id="roleId"
                            onChange={handleInput}
                            disabled={isLoading}
                            fullWidth
                            select
                            required={true}
                            SelectProps={{
                                multiple: false,
                                value: !isLoading && formValues.roleId ? formValues.roleId : ''
                            }}
                        >
                            { isLoading && (
                                <MenuItem></MenuItem>
                            )}
                            { !isLoading && roles.map((role) => (
                                <MenuItem key={role.id}
                                          value={role.id}>{role.name}</MenuItem>
                            ))}

                        </TextField>
                </Box>
                <Box mb={"30px"}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Status</FormLabel>
                        <RadioGroup row name="disabled" value={formValues.disabled.toString()} onChange={handleInput}>
                            <FormControlLabel value="false" control={<Radio />} label="Enabled" />
                            <FormControlLabel value="true" control={<Radio />} label="Disabled" />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box mb={"30px"}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Account</FormLabel>
                        <RadioGroup row name="accountLocked" value={formValues.accountLocked.toString()} onChange={handleInput}>
                            <FormControlLabel value="false" control={<Radio />} label="Unlocked" />
                            <FormControlLabel value="true" control={<Radio />} label="Locked" />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Button data-testid="back-button" startIcon={<ArrowBack />} onClick={() => history.goBack()}>Back</Button>
                    {formMode === formModes.EDIT && <Button data-testid="submit-button" startIcon={<Save />} onClick={handleSubmit}>Save User</Button>}
                    {formMode === formModes.CREATE && <Button data-testid="submit-button" startIcon={<PersonAdd />} onClick={handleSubmit}>Add User</Button>}
                </Box>
            </Box>
        </Paper>
    )
}

CreateAssessorUserForm.propTypes = {
    assessorUser: PropTypes.object
}

export default CreateAssessorUserForm