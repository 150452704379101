import {capitalize} from "./stringUtils";

const conditionMap = {};
conditionMap['POST_SUBMISSION'] = 'Post Submission';
conditionMap['MOTORIST_EVIDENCE_SUBMITTED'] = 'Motorist Evidence Submitted';
conditionMap['AWAITING_OPERATOR_EVIDENCE'] = 'Awaiting Operator Evidence';
conditionMap['WITHDRAWN'] = 'Withdrawn';
conditionMap['AWAITING_MOTORIST_COMMENT'] = 'Awaiting Motorist Comment';
conditionMap['WAITING_FOR_ASSESSMENT'] = 'Waiting for Assessment';
conditionMap['UNDER_REVIEW'] = 'Under Review';
conditionMap['DECISION_SENT'] = 'Decision Sent';

conditionMap['AWAITING_MOTORIST_COMMENT'] = 'Awaiting Motorist Comment';
conditionMap['WAITING_FOR_ASSESSMENT'] = 'Waiting for Assessment';
conditionMap['UNDER_REVIEW'] = 'Under Review';
conditionMap['DECISION_SENT'] = 'Decision Sent';

conditionMap['THIRD_PARTY'] = 'Third Party';
conditionMap['CASE_ADVICE'] = 'Case Advice';
conditionMap['MITIGATION'] = 'Mitigation';
conditionMap['OTHER'] = 'Other';

export const conditionMapper = (value) => {
    if (!value) return ''

    let condition = conditionMap[value];
    if (condition) {
        return condition;
    } else {
        let newValue = value.toLowerCase()
        newValue = newValue.replace(/_/g, ' ');
        newValue = newValue.split(/\s+/).map(s => capitalize(s)).join(' ')
        return (newValue);
    }
}