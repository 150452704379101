import React, {useEffect, useRef, useState} from 'react';
import {
    Box, TextField, Typography, Paper, Tooltip, useTheme,
    IconButton, Chip, Button
} from '@mui/material';
import {Add, Close} from "@mui/icons-material";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {grey} from "@mui/material/colors"
import {useStateStore} from "../StateStoreProvider";

const useStyles = makeStyles((theme) => ({
    root: {
        background: grey["300"]
    },
    field: {
        display: 'inline-grid',
        gridTemplateColumns: '10em 20em auto',
        alignItems: 'center',
        '& > *': {
            marginRight: theme.spacing(2),
        },
    },
    addField: {
        '& > *': {
            marginRight: theme.spacing(1),
        }
    },
    dateRange: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(1),
        gap: theme.spacing(1)
    }
}));

const CaseAdvancedSearch = ({columns, onChange}) => {
    const timer = useRef()
    const previousParams = useRef()
    const [params, setParams] = useStateStore('cases.advancedSearchParams', [])
    const [fieldSelectIsActive, setFieldSelectIsActive] = useState(true)
    const availableColumns = columns.filter(column => !params.map(p => p.field).includes(column.field))
    const classes = useStyles()
    const theme = useTheme()

    function addParam(column) {
        const {field, headerName} = column
        setParams([...params, {field, headerName, value: ''}])
        setFieldSelectIsActive(false)
    }

    function removeParam(i) {
        let updatedParams = [...params]
        updatedParams.splice(i, 1)
        setParams(updatedParams)
    }

    function setParamValue(i, value) {
        let updatedParams = [...params]
        updatedParams[i].value = value
        setParams(updatedParams)
    }

    function setParamDateFromValue(i, value) {
        let updatedParams = [...params]
        let toDate = getToDate(updatedParams[i].value) ?? value

        // Ensure From date is not greater than To Date
        if (value > toDate) {
            toDate = value
        }

        updatedParams[i].value = `${value}|${toDate}`
        setParams(updatedParams)
    }

    function setParamDateToValue(i, value) {
        let updatedParams = [...params]
        let fromDate = getFromDate(updatedParams[i].value) ?? value

        // Ensure From date is not greater than To Date
        if (value < fromDate) {
            fromDate = value
        }

        updatedParams[i].value = `${fromDate}|${value}`
        setParams(updatedParams)
    }

    // Send search value to onChange
    useEffect(() => {
        clearTimeout(timer.current)

        timer.current = setTimeout(() => {
            let validParams = mapSearchParams(params)

            if (!paramsAreEqual(previousParams.current || [], validParams)) {
                onChange(validParams)
            }

            previousParams.current = []
            validParams.forEach((param, i) => {
                previousParams.current[i] = {...param}
            })
        }, 600)
    }, [params, onChange])

    // Clear timeout when removed
    useEffect(() => {
        return () => {
            onChange([])
            clearTimeout(timer.current)
        }
    }, [onChange])

    return (
        <Paper variant="outlined" className={classes.root} data-testid="advanced-search">
            <Box p={2}>
                <Typography variant="subtitle2" gutterBottom>Advanced search</Typography>
                {params.map((param, i) => (
                    <Box key={param.field} mb={2}>
                        <div className={classes.field}>
                            <TextField
                                id={`filter-name-${param.field}`}
                                label="Field"
                                variant="standard"
                                disabled
                                inputProps={{style: {color: theme.palette.primary.main}}}
                                value={param.headerName}
                            />
                            {getSearchType(param) === 'text' && (
                                <Tooltip title="Search requires a minimum of 3 characters" disableFocusListener>
                                    <TextField
                                        id={`filter-value-${param.field}`}
                                        label="Contains"
                                        placeholder="Search"
                                        variant="standard"
                                        name={`filter-${param.field}`}
                                        value={param.value}
                                        autoFocus
                                        onChange={e => setParamValue(i, e.target.value)}
                                    />
                                </Tooltip>
                            )}
                            {getSearchType(param) === 'date' && (
                                <div className={classes.dateRange}>
                                    <TextField
                                        id={`filter-${param.field}-from`}
                                        label="From"
                                        placeholder="dd/mm/yyyy"
                                        variant="standard"
                                        name={`filter-${param.field}-from`}
                                        value={getFromDate(param.value) ?? ''}
                                        autoFocus
                                        InputLabelProps={{shrink: true}}
                                        type="date"
                                        onChange={e => setParamDateFromValue(i, e.target.value)}
                                    />
                                    <TextField
                                        id={`filter-${param.field}-to`}
                                        label="To"
                                        placeholder="dd/mm/yyyy"
                                        variant="standard"
                                        name={`filter-${param.field}-to`}
                                        value={getToDate(param.value) ?? ''}
                                        InputLabelProps={{shrink: true}}
                                        type="date"
                                        onChange={e => setParamDateToValue(i, e.target.value)}
                                    />
                                </div>
                            )}
                            <IconButton data-testid="remove-filter" color="primary" size="small"
                                        onClick={() => removeParam(i)}><Close/></IconButton>
                        </div>
                    </Box>
                ))}
                <Box className={classes.addField}>
                    {fieldSelectIsActive && availableColumns.map(column => <Chip key={column.field} color="primary"
                                                                                 label={column.headerName}
                                                                                 onClick={() => addParam(column)}/>)}
                    {params.length < columns.length &&
                    fieldSelectIsActive
                        ? (<Button variant="text" startIcon={<Close/>} data-testid="close-add-filter"
                                   onClick={() => setFieldSelectIsActive(false)}>Cancel</Button>)
                        : (<Button variant="text" startIcon={<Add/>} data-testid="show-add-filter"
                                   onClick={() => setFieldSelectIsActive(true)}>Add filter</Button>)
                    }
                </Box>
            </Box>
        </Paper>
    )
}

CaseAdvancedSearch.propTypes = {
    columns: PropTypes.array,
    onChange: PropTypes.func
}

export default CaseAdvancedSearch

export function paramsAreEqual(oldParams, newParams) {
    if (!oldParams || !newParams) {
        return false;
    }

    if (oldParams.length !== newParams.length) {
        return false;
    }

    let equal = true
    newParams.forEach((p, i) => {
        equal = JSON.stringify(oldParams[i]) === JSON.stringify(p);
    })

    return equal
}

export function getFromDate(dateString) {
    const dates = dateString.split('|')
    if (dates.length !== 2) {
        return dateString
    }
    return dates[0]
}

export function getToDate(dateString) {
    const dates = dateString.split('|')
    if (dates.length !== 2) {
        return dateString
    }
    return dates[1]
}

export function getSearchType(param) {
    switch (param.field) {
        case 'submissionDate':
        case 'targetDate':
        case 'decisionDate':
            return 'date'
        default:
            return 'text'
    }
}

// Convert params array into valid field names for search API
export function mapSearchParams(params) {
    let validParams = params
        .filter(param => param.value.length > 2)

    for (let i = 0; i < validParams.length; i++) {
        const param = validParams[i]
        if (getSearchType(param) === 'date') {
            const filter = {filter: param.field}
            const fieldCapitalised = param.field.replace(/^\w/, c => c.toUpperCase())

            const fromDate = getFromDate(param.value)
            filter[`from${fieldCapitalised}`] = new Date(`${fromDate}T00:00:00Z`).getTime()

            const toDate = getToDate(param.value)
            filter[`to${fieldCapitalised}`] = new Date(`${toDate}T23:59:59Z`).getTime()

            validParams.splice(i, 1, filter)
        }
    }

    return validParams.map(param => {
        if (param.field) {
            return {
                filter: param.field,
                value: param.value
            }
        }
        return param
    })
}