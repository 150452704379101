import React, {useState} from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    Button
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Add} from "@mui/icons-material";
import PropTypes from "prop-types";
import FileAttachments from "../../cases/CreateCasePage/FileAttachments";

export const UploadAppellantFiles = ({onUpload}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    function handleClose() {
        setIsOpen(false)
        setFiles([])
    }

    async function handleUploadFiles() {
        setIsLoading(true)
        await onUpload(files)
        setIsLoading(false)
        setIsOpen(false)
    }

    return (
        <div>
            <Button data-testid='upload-appellant-files' variant="outlined" startIcon={<Add />} onClick={() => setIsOpen(true)}>Upload Appellant Files</Button>
            <Dialog
                data-testid={'upload-appellant-files-dialog'}
                open={isOpen}
                onClose={handleClose}
                fullWidth>
                <DialogTitle>Upload Appellant Files</DialogTitle>
                <Box px={3}>
                    <FileAttachments onFilesChanged={setFiles} heading={''} />
                </Box>
                <Box px={2} pb={2}>
                    <DialogActions>
                        <Button data-testid="upload-appellant-files-cancel" onClick={handleClose} color="default">Cancel</Button>
                        <LoadingButton data-testid="upload-appellant-files-submit" onClick={handleUploadFiles} loading={isLoading} disabled={!files.length}>Upload</LoadingButton>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );
}

UploadAppellantFiles.propTypes = {
    onUpload: PropTypes.func
}