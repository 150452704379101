import {Grid, MenuItem, Typography} from "@mui/material";
import Select from "../../components/Select";
import ValidInput from "../../components/ValidInput";
import React, {useContext} from "react";
import {CaseFormContext} from "./CaseFormContext";

const AppellantFields = () => {
    const {
        formValues,
        formErrors,
        handleInput,
    } = useContext(CaseFormContext)

    return (
        <>
            <Typography variant="h5">Appellant details</Typography>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Select dataTestId="appellant-title-select" name="appellantTitle" value={formValues.appellantTitle} onChange={handleInput} label="Title">
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Mrs">Mrs</MenuItem>
                        <MenuItem value="Miss">Miss</MenuItem>
                        <MenuItem value="Dr">Dr</MenuItem>
                        <MenuItem value="Ms">Ms</MenuItem>
                        <MenuItem value="Prof">Prof</MenuItem>
                        <MenuItem value="Rev">Rev</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <ValidInput id="appellantFirstName" label="First name" name="appellantFirstName" value={formValues.appellantFirstName} onChange={handleInput} errorText={formErrors.appellantFirstName} required />
                </Grid>
                <Grid item xs={4}>
                    <ValidInput id="appellantLastName" label="Last name" name="appellantLastName" value={formValues.appellantLastName} onChange={handleInput} errorText={formErrors.appellantLastName} required />
                </Grid>
            </Grid>
            <ValidInput id="appellantAddressLine1" label="Address line 1" name="appellantAddressLine1" value={formValues.appellantAddressLine1} onChange={handleInput} errorText={formErrors.appellantAddressLine1} required />
            <ValidInput id="appellantAddressLine2" label="Address line 2" name="appellantAddressLine2" value={formValues.appellantAddressLine2} onChange={handleInput} />
            <ValidInput id="appellantPostTown" label="Town" name="appellantPostTown" value={formValues.appellantPostTown} onChange={handleInput} errorText={formErrors.appellantPostTown} required />
            <ValidInput id="appellantCounty" label="County" name="appellantCounty" value={formValues.appellantCounty} onChange={handleInput} errorText={formErrors.appellantCounty} required />
            <ValidInput id="appellantPostcode" label="Postcode" name="appellantPostcode" value={formValues.appellantPostcode} onChange={handleInput} errorText={formErrors.appellantPostcode} required />
            <ValidInput id="appellantPhoneNumber" label="Phone number" name="appellantPhoneNumber" value={formValues.appellantPhoneNumber} onChange={handleInput} errorText={formErrors.appellantPhoneNumber} required />
            <ValidInput id="appellantEmail" label="Email" name="appellantEmail" value={formValues.appellantEmail} onChange={handleInput} errorText={formErrors.appellantEmail} />
        </>
    )
}

export default AppellantFields