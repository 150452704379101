const sourceMap = new Map([
    ['CASE_ASSIGNED', 'Case assigned'],
    ['FILE_SUBMITTED', 'File submitted'],
    ['DECISION_MADE', 'Decision made'],
    ['WITHDRAWN', 'Withdrawn'],
    ['EMAIL_SENT', 'Email sent'],
    ['CASE_UPDATED', 'Case updated'],
    ['DELETE_MESSAGE', 'Message deleted'],
    ['DELETE_FILE', 'Document deleted'],
    ['EMAIL_ATTACHED', 'Message linked'],
    ['NOTE_ADDED', 'Note added'],
    ['NOTE_DELETED', 'Note deleted'],
    ['CHARGE_MADE', 'Charge added']
]);

export const auditEventTypeMapper = (value: string): string => {
    return sourceMap.get(value) ?? 'State change';
}
