import RemoveCircle from "@mui/icons-material/RemoveCircle";
import React from "react";
import PropTypes from "prop-types";
import {IconButton} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        margin: '10px 0'
    },
    table: {
        display: 'table'
    },
    row: {
        display: 'table-row'
    },
    descriptionCell: {
        display: 'table-cell',
        fontWeight: 'bold',
        maxWidth: '40rem',
    },
    filenameCell: {
        display: 'table-cell',
        fontStyle: 'italic',
        padding: '0 1em'
    },
    actionCell: {
        display: 'table-cell'
    }
});

const FileAttachmentFiles = ({files, onRemove}) => {
    const styles = useStyles()

    return (
        <div className={styles.root} data-testid="attached-files">
            <div className={styles.table}>
                {files.map((file, i) => (
                    <div key={i} className={styles.row}>
                        <span className={styles.descriptionCell}>{file.values.title}</span>
                        <span className={styles.filenameCell}>{file.values.filename.name}</span>
                        <span className={styles.actionCell}><IconButton onClick={() => onRemove(i)} title="Remove this file" size="large"><RemoveCircle /></IconButton></span>
                    </div>
                ))}
            </div>
        </div>
    );
}

const file = PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.object,
})

FileAttachmentFiles.propTypes = {
    files: PropTypes.arrayOf(file),
    onRemove: PropTypes.func
}

export default FileAttachmentFiles