import React from "react";
import { Box } from "@mui/material";
import {ArrowForward} from "@mui/icons-material"
import StatusChip from "../StatusChip";
import {conditionMapper} from "../../utils/conditionMapper";

interface History {
    from_status: string
    from_condition: string
    to_status: string
    to_condition: string,
    status_reason: string
}

/**
 * Parse a description string into separate parts.
 * @param description e.g. 'Previous State: NEW-AWAITING_OPERATOR_EVIDENCE, New State: NEW-AWAITING_MOTORIST_COMMENT'
 */
export const parseAuditDescription = (description: string): History | null => {
    const parts = description.split(", ")
    if (parts.length < 2) {
        return null
    }

    // Remove 'Previous State: '
    if (parts[0].substring(0, 16) !== 'Previous State: ') {
        return null
    }
    let from: string = parts[0].substring(16)

    // Remove 'New State: '
    if (parts[1].substring(0, 11) !== 'New State: ') {
        return null
    }
    let to: string = parts[1].substring(11)

    // Split based on <status>-<condition> string
    if (from.indexOf('-') === -1 || to.indexOf('-') === -1) {
        return null
    }
    let [from_status, from_condition] = from.split('-')
    let [to_status, to_condition] = to.split('-')

    let status_reason : string = "";
    if (parts.length > 2) {
        status_reason = parts[2].substring(8);
    }
    return { from_status, from_condition, to_status, to_condition, status_reason}
}

const AuditDescription = (props: AuditDescriptionProps) => {
    const {description} = props;
    const parsedDescription = parseAuditDescription(description)

    if (parsedDescription === null) {
        return <Box data-testid="description-fallback">{description}</Box>
    }

    const {from_status, from_condition, to_status, to_condition, status_reason} = parsedDescription
    return (
        <Box display="flex" alignItems="center">
            <Box title="Previous state" flex="1">
                <StatusChip status={from_status} label={from_status} />
                <span style={{marginLeft: 10}}>{conditionMapper(from_condition)}</span>
            </Box>
            <Box px={2}><ArrowForward /></Box>
            <Box title="New state" flex="1">
                <StatusChip status={to_status} label={to_status} />
                <span style={{marginLeft: 10}}>{conditionMapper(to_condition)}</span>
                <span hidden={status_reason.length === 0}>, {conditionMapper(status_reason)}</span>
            </Box>
        </Box>
    )
}

export default AuditDescription

interface AuditDescriptionProps {
    description: string
}