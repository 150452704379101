import React, {useState} from "react"
import {Box, Button, CircularProgress, Paper} from "@mui/material";
import {Email} from "@mui/icons-material";
import EmailView from "./EmailView"
import PropTypes from "prop-types";
import {EmailTarget, useEmails} from "../../api/emailApi";
import {getOperator} from "../../api/securityApi";
import {LoadingButton} from "@mui/lab";
import featureIsEnabled from "../../utils/featureFlag";

function EmailTab(props) {
    const {caseObject} = props;
    const [loadingOperatorEmail, setLoadingOperatorEmail] = useState(false)
    const {emails, error, isLoading, refetch: refetchEmails} = useEmails(EmailTarget.case, caseObject.verificationCode)

    const composeAppellantEmail = () => {
        openEmailLink(caseObject.appellant?.email, 'APPELLANT', caseObject.verificationCode)
    }

    const composeOperatorEmail = async () => {
        setLoadingOperatorEmail(true)
        const operator = await getOperator(caseObject.operatorCode)
        setLoadingOperatorEmail(false)
        openEmailLink(operator?.email, 'OPERATOR', caseObject.verificationCode)
    }

    const openEmailLink = (address, type, verificationCode) => {
        let recipientType = `|TO-${type.toUpperCase()}`;
        if (!featureIsEnabled('compose-email-operator')) {
            recipientType = '';
        }
        let idParam = `CASE_VC-${verificationCode}`
        window.open(`mailto:${address}?subject=POPLA_MANUAL_MAIL${recipientType}|${idParam}&body=POPLA_MANUAL_MAIL`);
    }

    const refreshEmails = () => {
        refetchEmails();
    }

    if (isLoading) {
        return <CircularProgress />
    }

    if (error) {
        return (
            <Paper>
                <Box>Unable to load emails</Box>
            </Paper>
        )
    }

    return (
        <div>
            <Box mb={2} sx={{display: 'flex', gap: '10px'}}>
                <Button
                    variant="contained"
                    color="inherit"
                    startIcon={<Email/>}
                    data-testid="compose-email-appellant"
                    onClick={composeAppellantEmail}>Compose E-mail to Appellant</Button>
                {featureIsEnabled('compose-email-operator') && (
                    <LoadingButton
                        loading={loadingOperatorEmail}
                        variant="contained"
                        color="inherit"
                        startIcon={<Email/>}
                        data-testid="compose-email-operator"
                        onClick={composeOperatorEmail}
                    >Compose E-mail to Operator</LoadingButton>
                )}
            </Box>
            {emails.map(email => (
                <EmailView
                    key={email.messageId}
                    className="EmailTab"
                    email={email}
                    onChange={refreshEmails}
                />
            ))}
            {!emails.length && (
                <Paper>
                    <Box p={2}>There are no emails attached to this case</Box>
                </Paper>
            )}
        </div>
    )
}

EmailTab.propTypes = {
    caseObject: PropTypes.object
}

export default EmailTab