import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {schema, validate} from "./schema";
import {useHistory} from "react-router-dom";
import {SnackBarContext} from "../../../components/SnackBarContext";
import {useUser} from "../../../auth/useUser";
import {addOperatorUser, updateOperatorUser} from "../../../api/operatorUsersApi";

export const formModes = { CREATE: "create", EDIT: "edit" }

export const OperatorUserFormContext = React.createContext({
    formValues: {},
    setFormValues: {},
    formErrors: {},
    setFormErrors: {},
    formMode: formModes.CREATE,
    setFormMode: {},
    handleInput: {},
    handleOperatorCodeInput: {},
    handleSubmit: {},
    isSaving: false
})

const defaultFormValues = {
    operatorCodes: {},
    roles: 'SUPERUSER',
    email: '',
    name: '',
    disabled: 'false',
}

const defaultFormErrors = {}

export const OperatorUserFormProvider = ({children}) => {
    const [formValues, setFormValues] = useState({...defaultFormValues})
    const [formErrors, setFormErrors] = useState({...defaultFormErrors})
    const [formMode, setFormMode] = useState(formModes.CREATE)
    const [isSaving, setIsSaving] = useState(false);
    const {user} = useUser();
    const history = useHistory()
    const {showToast} = useContext(SnackBarContext)

    function handleOperatorCodeInput(e, values) {
        if (values) {
            const operatorCodes = values.map(v => v.operatorCode);
            setFormValues({...formValues, operatorCodes})
        }
    }

    function handleInput(e) {
        let { name, value, type } = e.target;
        let updatedValues = {}

        if (type === 'checkbox') {
            value = e.target.checked
        }

        updatedValues[name] = value;
        setFormValues(Object.assign({...formValues}, updatedValues))
    }

    async function handleSubmit() {
        const updatedFormErrors = validate(formValues, schema)
        setFormErrors(updatedFormErrors);
        if (Object.keys(updatedFormErrors).length === 0) {
            setIsSaving(true)
            formMode === formModes.EDIT
                ? await submitUpdate()
                : await submitCreate()
        } else {
            showToast('There are errors in the form, please correct them before continuing', 'error');
        }
    }

    const submitCreate = async () => {
        const result = await addOperatorUser(formValues, user?.token)
        if (result.status) {
            setIsSaving(false)
            history.replace('/operators/users')
            showToast('Operator User added successfully')
        } else {
            showToast(`Problem creating operator user: ${result.errorMessage}`, 'error');
        }
    }

    const submitUpdate = async () => {
        const result = await updateOperatorUser(formValues, user?.token)
        if (result.status) {
            setIsSaving(false)
            history.replace('/operators/users')
            showToast('Operator User updated successfully')
        } else {
            showToast(`Problem updating operator user: ${result.errorMessage}`, 'error');
        }
    }

    return (
        <OperatorUserFormContext.Provider value={{
            formValues,
            setFormValues,
            formErrors,
            setFormErrors,
            formMode,
            setFormMode,
            handleInput,
            handleOperatorCodeInput,
            handleSubmit,
            isSaving
        }}>
            {children}
        </OperatorUserFormContext.Provider>
    )
}

OperatorUserFormProvider.propTypes = {
    children: PropTypes.element
}