import * as yup from "yup";

// Add custom validation to require a field only if another is empty
yup.string.prototype['requiredUnless'] = function(dependantField, label) {
    return this.test('${path}', 'Please provide either ${path} or ' + label, function(value) {
        const dependant = this.parent[dependantField]
        if (!dependant) {
            return !!value;
        }
        return true
    })
}

// Validation object to use with motorist fields
const requiredIfShowMotorist = {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string()
}

export const postalSchema = yup.object().shape({
    verificationCode: yup.string().matches(/^[0-9]{10}$/, "Please provide a valid 10-digit verification code").required().min(10).label('Verification code'),
    operatorCode: yup.string().label('Operator code'),
    showMotorist: yup.bool().label('Show motorist'),
    motoristTitle: yup.string().when('showMotorist', requiredIfShowMotorist).label('Title'),
    motoristFirstName: yup.string().when('showMotorist', requiredIfShowMotorist).max(50).label('First name'),
    motoristLastName: yup.string().when('showMotorist', requiredIfShowMotorist).max(50).label('Last name'),
    motoristAddressLine1: yup.string().when('showMotorist', requiredIfShowMotorist).max(250).label('Address line 1'),
    motoristAddressLine2: yup.string().max(250).label('Address line 2'),
    motoristPostTown: yup.string().when('showMotorist', requiredIfShowMotorist).max(80).label('Town'),
    motoristCounty: yup.string().when('showMotorist', requiredIfShowMotorist).max(50).label('County'),
    motoristPostcode: yup.string().when('showMotorist', requiredIfShowMotorist).max(20).label('Postcode'),
    appellantTitle: yup.string().label('Title'),
    appellantFirstName: yup.string().required().max(50).label('First name'),
    appellantLastName: yup.string().required().max(50).label('Last name'),
    appellantPhoneNumber: yup.string().required().max(50).label('Phone number'),
    appellantEmail: yup.string().email().max(100).label('Email'),
    appellantBuildingNumber: yup.string().label('Building number'),
    appellantAddressLine1: yup.string().required().max(250).label('Address line 1'),
    appellantAddressLine2: yup.string().max(250).label('Address line 2'),
    appellantCounty: yup.string().required().max(50).label('County'),
    appellantPostTown: yup.string().required().max(80).label('Town'),
    appellantPostcode: yup.string().required().max(20).label('Postcode'),
    appellantPcnNumber: yup.string().required().max(255).label('Parking Charge Notice number'),
    vehicleRegistration: yup.string().required().max(10).label('Vehicle registration'),
    vehicleMake: yup.string().required().max(50).label('Vehicle make'),
    vehicleModel: yup.string().required().max(50).label('Vehicle model'),
    vehicleColor: yup.string().required().max(20).label('Vehicle color'),
})

export const draftSchema = yup.object().shape({
    verificationCode: yup.string().matches(/^[0-9]{10}$/, { message: "Please provide a valid 10-digit verification code", excludeEmptyString: true }).label('Verification code'),
    operatorCode: yup.string().label('Operator code'),
    showMotorist: yup.bool().label('Show motorist'),
    motoristTitle: yup.string().label('Title'),
    motoristFirstName: yup.string().max(50).label('First name'),
    motoristLastName: yup.string().max(50).label('Last name'),
    motoristAddressLine1: yup.string().max(250).label('Address line 1'),
    motoristAddressLine2: yup.string().max(250).label('Address line 2'),
    motoristCounty: yup.string().max(50).label('County'),
    motoristPostTown: yup.string().max(80).label('Town'),
    motoristPostcode: yup.string().max(20).label('Postcode'),
    appellantTitle: yup.string().label('Title'),
    appellantFirstName: yup.string().required().max(50).label('First name'),
    appellantLastName: yup.string().required().max(50).label('Last name'),
    appellantPhoneNumber: yup.string().requiredUnless('appellantEmail', 'Email').max(50).label('Phone number'),
    appellantEmail: yup.string().email().requiredUnless('appellantPhoneNumber', 'Phone number').max(100).label('Email'),
    appellantBuildingNumber: yup.string().label('Building number'),
    appellantAddressLine1: yup.string().required().max(250).label('Address line 1'),
    appellantAddressLine2: yup.string().max(250).label('Address line 2'),
    appellantCounty: yup.string().required().max(50).label('County'),
    appellantPostTown: yup.string().required().max(80).label('Town'),
    appellantPostcode: yup.string().required().max(20).label('Postcode'),
    appellantPcnNumber: yup.string().max(255).label('Parking Charge Notice number'),
    vehicleRegistration: yup.string().max(10).label('Vehicle registration'),
    vehicleMake: yup.string().max(50).label('Vehicle make'),
    vehicleModel: yup.string().max(50).label('Vehicle model'),
    vehicleColor: yup.string().max(20).label('Vehicle color'),
})

export function validate(formValues, schema) {
    let errors = {}
    if (!formValues) return errors;

    schema._nodes.forEach((node) => {
        try {
            schema.validateSyncAt(node, formValues)
        } catch (err) {
            errors[node] = err.message
        }
    })

    return errors
}

export function validateAnswers(answers, isDraft) {
    let errors = {}
    if (!answers) return errors;

    Object.entries(answers).forEach(([questionCode, answer]) => {
        if (answer.length > 2000) {
            errors[questionCode] = `you have entered ${answer.length} characters, the limit is 2000`;
        }

        if (!isDraft && !answer.trim().length) {
            errors[questionCode] = "this field is required";
        }
    })

    return errors;
}