import React, {useState} from 'react';
import {
    Box,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle, Button
} from "@mui/material";
import {Add} from "@mui/icons-material";
import ValidInput from "../ValidInput";
import PropTypes from "prop-types";

const defaultFormValues = {
    userType: 'APPELLANT',
    commentType:'APPELLANT_COMMENT',
}

const defaultFormErrors = {
    comment: ''
}

export const AddComment = ({onAdd}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [formValues, setFormValues] = useState({...defaultFormValues})
    const [formErrors, setFormErrors] = useState({...defaultFormErrors})

    function handleClose() {
        setIsOpen(false)
    }

    function handleAddComment() {
        if (!validate()) {
            return;
        }

        let newNote = {
            ...defaultFormValues,
            comment: formValues.comment,
        }
        onAdd(newNote)
        setFormValues({...defaultFormValues})
        setIsOpen(false)
    }

    function handleInput(e) {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value})
    }

    function validate() {
        setFormErrors({...defaultFormErrors})

        if (!formValues.comment || !formValues.comment.length) {
            setFormErrors({...formErrors, comment: 'This field is required'})
            return false
        }

        if (formValues.comment.length > 2000) {
            setFormErrors({...formErrors, comment: 'Maximum 2000 characters'})
            return false
        }

        if (formValues.comment.match(/[<>]+/g)) {
            setFormErrors({...formErrors, comment: 'Must not contain <> characters'})
            return false
        }

        return true
    }

    return (
        <div>
            <Button data-testid="add-comment" variant="text" startIcon={<Add />} onClick={() => setIsOpen(true)}>Add comment</Button>
            <Dialog open={isOpen} onClose={handleClose} fullWidth>
                <DialogTitle data-testid="add-comment-dialog">Add comment</DialogTitle>
                <DialogContent>
                    <Box px={3}>
                        <ValidInput
                            id={`appellant-comment`}
                            name="comment"
                            label="Appellant Comment"
                            rows={5}
                            onChange={handleInput}
                            errorText={formErrors.comment}
                            value={formValues.comment}
                            required autoFocus multiline
                        />
                    </Box>
                    <Box px={2} pb={2}>
                        <DialogActions>
                            <Button data-testid="add-comment-cancel" onClick={handleClose} color="default">Cancel</Button>
                            <Button data-testid="add-comment-submit" onClick={handleAddComment}>Add</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

AddComment.propTypes = {
    onAdd: PropTypes.func,
}