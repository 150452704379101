import React from 'react';
import './App.css';
import {Routes} from "./routes/Routes";
import ThemeProvider from "./components/ThemeProvider";
import {SnackBarProvider} from "./components/SnackBarContext"
import ErrorBoundary from "./components/ErrorBoundary";
import {AuthProvider} from "./auth/AuthProvider";
import {StateStoreProvider} from "./components/StateStoreProvider";

function App() {
  return (
      <div className="App">
          <ThemeProvider>
              <ErrorBoundary>
                  <StateStoreProvider>
                      <AuthProvider>
                          <SnackBarProvider>
                              <Routes />
                          </SnackBarProvider>
                      </AuthProvider>
                  </StateStoreProvider>
              </ErrorBoundary>
          </ThemeProvider>
      </div>
  );
}

export default App;
