import * as yup from "yup";

export const schema = yup.object().shape({
    roleId: yup.number().required().label('Role'),
    email: yup.string().email().required().max(100).label('Email'),
    name: yup.string().required().max(100).label('Name')
})

export function validate(formValues, schema) {
    let errors = {}
    schema._nodes.forEach((node) => {
        try {
            schema.validateSyncAt(node, formValues)
        } catch (err) {
            errors[node] = err.message
        }
    })
    return errors
}