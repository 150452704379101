import React from 'react';
import {Breadcrumbs, Container, Link as MaterialLink, Typography} from "@mui/material";
import {Link as ReactLink, useHistory} from "react-router-dom";
import CreateAssessorUserForm from "./CreateAssessorUserForm"
import {AssessorUserFormProvider} from "./AssessorUserFormContext.jsx";

const CreateAssessorUserPage = () => {
    const history = useHistory();
    const assessorUser = history?.location?.state?.assessorUser;

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '28px'}}>
                <MaterialLink component={ReactLink} to="/" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <MaterialLink component={ReactLink} to="/assessors" className={"App-Breadcrumb-Link"}>
                    <u>User Management</u>
                </MaterialLink>
                <Typography color="textPrimary">{assessorUser ? `Edit ${assessorUser.name}` : "Add Assessor User"}</Typography>
            </Breadcrumbs>
            <Container>
                <Typography variant="h1" gutterBottom>{assessorUser ?  `Edit ${assessorUser.name}` : "New Assessor User"}</Typography>
                <AssessorUserFormProvider>
                    <CreateAssessorUserForm assessorUser={assessorUser} />
                </AssessorUserFormProvider>
            </Container>
        </div>
    )
}

CreateAssessorUserPage.propTypes = {}

export default CreateAssessorUserPage