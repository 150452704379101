import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Paper,
    Radio,
    RadioGroup,
    Typography,
    Grid,
    Button,
} from "@mui/material";
import MultiLineInput from "../MultiLineInput";
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {submitCaseDecision} from "../../api/caseApi";
import {useTemplate} from "../../api/templateApi";
import makeStyles from '@mui/styles/makeStyles';
import withSnackbar from "../WithSnackbar";
import featureFlag from "../../utils/featureFlag";
import featureIsEnabled from "../../utils/featureFlag";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        maxWidth: '80rem'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

export const CaseAssessmentView = withSnackbar(({caseObject, setCaseObject, showToast}) => {
    const {template: assessorTemplate} = useTemplate('CASE_ASSESSMENT-NO_OP_EVIDENCE-ASSESSOR_SUMMARY');
    const {template: appellantTemplate} = useTemplate('CASE_ASSESSMENT-NO_OP_EVIDENCE-APPELLANT_SUMMARY')
    const {template: summaryTemplate} = useTemplate('CASE_ASSESSMENT-NO_OP_EVIDENCE-SUMMARY_REASONS');
    const [enableGenerateDecisionButton, setEnableGenerateDecisionButton] = useState(false);

    const classes = useStyles();

    const handleDecisionChange = (event) => {
        caseObject.decision = event.target.value;
    }

    const onChangeSummaryOperator = (event) => {
        setCaseObject({...caseObject, summaryOperator: event.target.value})
    }
    const onChangeSummaryAppellant = (event) => {
        setCaseObject({...caseObject, summaryAppellant: event.target.value})
    }
    const onChangeSummaryReasons = (event) => {
        setCaseObject({...caseObject, summaryReasons: event.target.value})
    }

    const onChangeAssessorDecision = (value) => {
        setCaseObject({...caseObject, decision: value});
    }

    const generateDecision = (event) => {
        if (!caseObject) {
            return;
        }

        if (!caseObject.decision ||
            !caseObject.summaryOperator ||
            caseObject.summaryOperator.trim().length === 0 ||
            !caseObject.summaryAppellant ||
            caseObject.summaryAppellant.trim().length === 0 ||
            !caseObject.summaryReasons ||
            caseObject.summaryReasons.trim().length === 0) {
            showToast('Please fill in all summary fields', 'info');

        } else {
            event.target.checked
                ? setEnableGenerateDecisionButton(true)
                : setEnableGenerateDecisionButton(false)
        }
    }

    const submitDecision = () => {
        // this payload is okay to be sent to case
        const decisionData = {
            'decision': caseObject.decision,
            'summaryOperator': caseObject.summaryOperator,
            'summaryAppellant': caseObject.summaryAppellant,
            'summaryReasons': caseObject.summaryReasons
        };

        submitCaseDecision(caseObject.verificationCode, decisionData)
            .then((error) => {
                if (error.errorMessage) {
                    showToast(`Could not change decision: ${error.errorMessage}`, 'error');
                } else {
                    showToast('Decision Updated', 'success');
                    setCaseObject({...caseObject, status: 'CLOSED', condition: 'DECISION_SENT'})
                }
            });
    }

    useEffect(() => {
        if (featureFlag('assessor-prepopulated-responses') && caseObject?.operatorEvidenceExcluded === true) {
            setCaseObject({
                ...caseObject,
                summaryOperator: caseObject.summaryOperator ? caseObject.summaryOperator : assessorTemplate?.templateBody,
                summaryAppellant: caseObject.summaryAppellant ? caseObject.summaryAppellant : appellantTemplate?.templateBody,
                summaryReasons: caseObject.summaryReasons ? caseObject.summaryReasons : summaryTemplate?.templateBody
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryTemplate, assessorTemplate, appellantTemplate]);

    const multilineRows = featureIsEnabled('case-assessment-expandable-inputs') ? {minRows: 4} : {rows: 3}

    return (

        <Paper elevation={3} className={classes.paper}>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="subtitle1" style={{color: "#0E2361"}}>Case Assessment</Typography>
                <Typography style={{}}>
                    Assessor <span style={{color: "#7BAFDF"}}>{caseObject?.assessorName}</span>
                </Typography>
            </Box>
            <MultiLineInput dataTestId={"input_summary_one"}
                            name={"Assessor Summary of the Operator Case"}
                            label={"Assessor Summary of the Operator Case"}
                            value={caseObject?.summaryOperator || ""}
                            onChange={onChangeSummaryOperator}
                            {...multilineRows}
            />

            <MultiLineInput dataTestId={"input_summary_two"}
                            name={"Assessor Summary of the Appellant Case"}
                            label={"Assessor Summary of the Appellant Case"}
                            value={caseObject?.summaryAppellant || ""}
                            onChange={onChangeSummaryAppellant}
                            {...multilineRows}
            />

            <MultiLineInput dataTestId={"input_summary_three"}
                            name={"Summary of Reasons"}
                            label={"Summary of Reasons"}
                            value={caseObject?.summaryReasons || ""}
                            onChange={onChangeSummaryReasons}
                            {...multilineRows}
            />

            <Grid container spacing={4} alignItems="center" justifyContent="space-between">
                <Grid item>
                    <FormLabel component="legend">Outcome of Decision</FormLabel>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset">
                        <RadioGroup row={true} aria-label="decision"
                                    name="decision">
                            <FormControlLabel value="ALLOWED"
                                              control={<Radio color={"primary"}/>}
                                              label="Allow"
                                              labelPlacement="top"
                                              onClick={() => onChangeAssessorDecision('ALLOWED')}
                                              checked={caseObject ? caseObject.decision === 'ALLOWED' : false}
                                              onChange={handleDecisionChange}
                                              data-testid={"decision_allowed"}/>
                            <FormControlLabel value="REFUSED"
                                              control={<Radio color={"primary"}/>}
                                              label="Refuse"
                                              labelPlacement="top"
                                              onClick={() => onChangeAssessorDecision('REFUSED')}
                                              checked={caseObject ? caseObject.decision === 'REFUSED' : false}
                                              onChange={handleDecisionChange}
                                              data-testid={"decision_refused"}
                            /></RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        data-testid={"check_confirm_decision"}
                        value="confirm"
                        control={<Checkbox color="primary"/>}
                        label="I confirm I am ready to issue a decision"
                        labelPlacement="start"
                        checked={enableGenerateDecisionButton}
                        onChange={event => {
                            generateDecision(event)
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        data-testid="button_generate_decision"
                        disabled={!enableGenerateDecisionButton}
                        onClick={submitDecision}
                    >Generate Decision</Button>
                </Grid>
            </Grid>
        </Paper>
    );
});

CaseAssessmentView.propTypes = {
    caseObject: PropTypes.shape({
        assessorName: PropTypes.string,
        summaryOperator: PropTypes.string,
        summaryAppellant: PropTypes.string,
        summaryReasons: PropTypes.string,
        decision: PropTypes.string,
        status: PropTypes.string,
        verificationCode: PropTypes.string,
    }),
    setCaseObject: PropTypes.func,
    showToast: PropTypes.func,
}
