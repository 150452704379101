import React from "react"
import PropTypes from "prop-types";
import DomPurify from "dompurify";

function MessageBody({name, value, type}) {
    return <div>
        <strong>{name}</strong>:
        {
            type === 'html'
                ? <div dangerouslySetInnerHTML={{__html: DomPurify.sanitize(value)}}/> :
                <div> {value} </div>
        }
    </div>
}

MessageBody.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
}

export default MessageBody