import React, {useState, useContext} from "react";
import {Snackbar, Alert} from "@mui/material";
import PropTypes from "prop-types";

/*
    Context used to provide a global snackbar that persists between pages and route changes

    Usage:
        <SnackBarProvider>
            <App>
        </SnackBarProvider>

    (This context is used by the 'WithSnackBar' Higher Order Component)
 */

export const SnackBarContext = React.createContext({
    // eslint-disable-next-line no-unused-vars
    showToast: (message, severity, duration) => {},
})

export const SnackBarProvider = ({children}) => {
    const [isToastOpen, setIsToastOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastSeverity, setToastSeverity] = useState('success')
    const [autoHideDuration, setAutoHideDuration] = useState(4000)

    function showToast(message, severity = 'success', duration = 4000) {
        setToastMessage(message)
        setToastSeverity(severity)
        setIsToastOpen(true)
        setAutoHideDuration(duration)
    }

    function handleCloseMessage() {
        setToastMessage('')
        setIsToastOpen(false)
    }

    return (
        <>
            <SnackBarContext.Provider value={{showToast}}>
                {children}
            </SnackBarContext.Provider>
            <Snackbar
                open={isToastOpen}
                onClose={handleCloseMessage}
                autoHideDuration={autoHideDuration}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                data-testid={"toast-alert"}
            >
                <Alert
                    severity={toastSeverity}
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseMessage}
                >{toastMessage}</Alert>
            </Snackbar>
        </>
    )
}

SnackBarProvider.propTypes = {
    children: PropTypes.any
}

export default function useSnackBar() {
    return useContext(SnackBarContext);
}