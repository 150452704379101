import React from 'react'
import {useCaseHistory} from "../../api/caseApi";
import {
    Paper,
    Box,
    CircularProgress,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CardContent,
    useTheme
} from "@mui/material";
import {displayDateTime, displayTargetDecisionDate} from "../../utils/dateUtils";
import {auditEventTypeMapper} from "../../utils/auditEventTypeMapper";
import AuditDescription from "./AuditDescription"

const CaseHistoryView = (props: Props) => {
    const {verificationCode, submissionDate} = props;
    const {history, isLoading, error} = useCaseHistory(verificationCode)
    const theme = useTheme()

    return (
        <Paper elevation={3}>
            <Box p={2}>
                <Typography variant="subtitle1" gutterBottom>Case History</Typography>
                <Typography variant="subtitle2" gutterBottom>Target Decision Date: {displayTargetDecisionDate(submissionDate, "-")}</Typography>
                <TableContainer component={OutlinedPaper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="normal">Event type</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Actioned by</TableCell>
                                <TableCell>Timestamp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history && history.map((item: HistoryItem) => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        {auditEventTypeMapper(item.eventType)}
                                    </TableCell>
                                    <TableCell>
                                        <AuditDescription description={item.description} />
                                    </TableCell>
                                    <TableCell>{item.actionedBy}</TableCell>
                                    <TableCell
                                        style={{width: '15ch'}}>{displayDateTime(new Date(item.timestamp))}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {isLoading && <Box p={2} style={{textAlign: 'center'}}><CircularProgress /></Box>}
                {!isLoading && (
                    <>
                        {error && <CardContent style={{textAlign: 'center', color: theme.palette.error.main}}>Error retrieving history.</CardContent>}
                        {(!error && history.length === 0) && <CardContent style={{textAlign: 'center', color: theme.palette.text.secondary}}>This case has no history.</CardContent>}
                    </>
                )}
            </Box>
        </Paper>
    );
}

const OutlinedPaper = (props: any) => <Paper variant="outlined" {...props} />

interface Props {
    verificationCode: string
    submissionDate: string
}

interface HistoryItem {
    id: string
    caseVerificationCode: string
    eventType: string
    actionedBy: string
    timestamp: string
    description: string
}

export default CaseHistoryView