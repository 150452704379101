export interface DownloadedFile {
    fileName: string,
    documentData: any,
}

export function deduplicateNames(downloadedFiles: DownloadedFile[]): DownloadedFile[] {
    let fileMap = new Map<string, number>()
    return downloadedFiles.map((downloadedFile: DownloadedFile) => {
        const count = fileMap.get(downloadedFile.fileName) ?? 0;
        let uniqueFilename = downloadedFile.fileName;
        if (count > 0) {
            uniqueFilename = addCountToFilename(uniqueFilename, count);
        }
        fileMap.set(downloadedFile.fileName, count + 1);
        return {
            ...downloadedFile,
            fileName: uniqueFilename
        };
    });
}

function addCountToFilename(filename: string, count: number): string {
    let extensionPos = filename.lastIndexOf(".");
    if (extensionPos < 0) {
        return filename + ` (${count})`;
    }
    return filename.substring(0, extensionPos) + ` (${count})` + filename.substring(extensionPos);
}