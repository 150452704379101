import {axios} from "./apiWrapper";
import {apiUrl} from "./apiEnv";
import {useEffect, useState} from "react";

export const getEmails = (targetType, targetId) => {
    const url = targetType === EmailTarget.draft
        ? `${apiUrl}/drafts/${targetId}/messages`
        : `${apiUrl}/messages?case_verification_code=${targetId}&page=0&size=100`

    return axios.get(url)
}

export const useEmails = (targetType, targetId) => {
    const [isLoading, setIsLoading] = useState(true);
    const [emails, setEmails] = useState(null);
    const [error, setError] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);

    const refetch = () => {
        setRefreshKey(refreshKey => refreshKey + 1)
    }

    useEffect(() => {
        if (targetId) {
            getEmails(targetType, targetId)
            .then((result) => {
                setEmails(result.data.content)
            }).catch(error => {
                setError(error.message)
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            setIsLoading(false)
        }
    }, [targetId, refreshKey, targetType]);

    return {
        isLoading,
        error,
        emails,
        refetch
    };
}

export const deleteEmail = (emailGuid, targetType) => {
    const url = targetType === EmailTarget.draft
        ? `${apiUrl}/drafts/message/${emailGuid}`
        : `${apiUrl}/messages/${emailGuid}`

    return axios.delete(url)
}

export const EmailTarget = {
    case: 'cases',
    draft: 'drafts'
}