import jwt_decode from "jwt-decode";

export interface JwtContent {
    exp: number,
    operators: string[],
    roles: string[],
    userTypes: string[],
    authorities: string[],
}

export const validateToken = (token: string) => {
    const decoded = jwt_decode<JwtContent>(token);
    if (decoded?.userTypes == null || !decoded.userTypes.includes('ASSESSOR')) {
        throw Error("Invalid user");
    }
};

// Check a token has not expired
export const isActive = (token: string): boolean => {
    const decoded = jwt_decode<JwtContent>(token);
    let now = new Date().getTime();
    let expires = new Date(decoded.exp * 1000).getTime();
    return expires > now
};
