import React, {useContext} from 'react'
import {
    Paper,
    Box,
    FormControlLabel,
    Checkbox,
    Typography,
    CircularProgress,
    LinearProgress,
    Button
} from "@mui/material";
import {Publish, Save, AddCircle} from '@mui/icons-material'
import {CaseFormContext} from "./CaseFormContext";
import ValidInput from "../../components/ValidInput";
import FileAttachments from "./FileAttachments";
import AppealDetails from "./AppealDetails";
import PropTypes from "prop-types";
import DVLAFields from "./DVLAFields";
import AppellantFields from "./AppellantFields";
import MotoristFields from "./MotoristFields";

const CaseForm = () => {
    const {
        formValues,
        formErrors,
        isDraft,
        setIsDraft,
        draftId,
        handleInput,
        setFiles,
        handleSubmit,
        isLoading,
        isSaving
    } = useContext(CaseFormContext)

    if (isLoading) {
        return (
            <Paper elevation={3}>
                <Box p={3} display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            </Paper>
        )
    }

    return (
        <Paper elevation={3}>
            <Box p={3}>
                <Box mb={2} display="flex">
                    <Box mr={1}>
                        <FormControlLabel
                            data-testid={"check_draft_case"}
                            checked={isDraft}
                            value="confirm"
                            control={<Checkbox name="draftCase" color="primary" />}
                            label="Draft case"
                            labelPlacement="end"
                            disabled={!!draftId}
                            onChange={(e) => setIsDraft(e.target.checked)}
                        />
                    </Box>
                    {isDraft && !!draftId && <Box mr={1}><Button data-testid="draft-promote" variant="text" startIcon={<AddCircle />} onClick={() => setIsDraft(false)}>Promote to full case</Button></Box>}
                </Box>
                <Typography variant="h5">Appeal details</Typography>
                <ValidInput
                    id="verification-code"
                    label="Verification code"
                    name="verificationCode"
                    value={formValues.verificationCode}
                    errorText={formErrors.verificationCode}
                    onChange={handleInput}
                    required={!isDraft}
                    inputProps={{maxLength: 10}}
                />
                <p><em>Operator code: <b>{formValues.operatorCode}</b></em></p>

                <Typography variant="subtitle1">
                    <b>Is the appellant submitting this appeal on behalf of someone else?</b>
                </Typography>
                <FormControlLabel
                    data-testid={"check_show_driver_details"}
                    value="confirm"
                    control={<Checkbox name="showMotorist" color="primary" />}
                    label="Yes"
                    labelPlacement="end"
                    checked={formValues.showMotorist}
                    onChange={handleInput}
                    style={{marginBottom: 16}}
                />
                {formValues.showMotorist && (
                    <MotoristFields />
                )}
                <AppellantFields />
                <DVLAFields />
                <AppealDetails />
                <FileAttachments onFilesChanged={setFiles} heading={'Supporting evidence'} disabled={isDraft}/>
                <div style={{textAlign: 'right', marginBottom: '10px'}}>
                    <Button
                        data-testid="finish"
                        startIcon={isDraft ? <Save /> : <Publish />}
                        disabled={isSaving}
                        onClick={handleSubmit}>
                        {isDraft ? 'Save draft' : 'Publish'}
                    </Button>
                </div>
                {isSaving && <LinearProgress />}
            </Box>
        </Paper>
    )
}

CaseForm.propTypes = {
    showToast: PropTypes.func
}

export default CaseForm