import {Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Search} from '@mui/icons-material'
import ValidInput from "../../components/ValidInput";
import React, {useContext, useState} from "react";
import {CaseFormContext} from "./CaseFormContext";
import {dvlaSearch} from "../../api/dvlaApi";

const DVLAFields = () => {
    const [isDVLALoading, setIsDVLALoading] = useState(false)
    const {
        formValues,
        setFormValues,
        formErrors,
        setFormErrors,
        isDraft,
        handleInput,
    } = useContext(CaseFormContext)

    function handleDVLALookup() {
        if (!formValues.vehicleRegistration) {
            return
        }

        setIsDVLALoading(true)
        dvlaSearch(formValues.vehicleRegistration).then(res => {
            setIsDVLALoading(false)
            const { data } = res;
            if (data.error !== undefined) {
                return setFormErrors({...formErrors, vehicleRegistration: data.message})
            } else {
                const updatedErrors = {...formErrors}
                delete updatedErrors.vehicleRegistration
                setFormErrors(updatedErrors)
            }

            const DVLAFormValues = {
                vehicleMake: data.make,
                vehicleModel: data.model,
                vehicleColor: data.colour,
                taxed: data.taxed,
                mot: data.mot,
                motDetails: data.motDetails,
                dateOfFirstRegistration: data.dateOfFirstRegistration,
                yearOfManufacture: data.yearOfManufacture,
                cylinderCapacity: data.cylinderCapacity,
                co2Emissions: data.co2Emissions,
                fuelType: data.fuelType,
                revenueWeight: data.revenueWeight,
                taxDetails: data.taxDetails,
                taxStatus: data.taxStatus,
                transmission: data.transmission,
                typeApproval: data.typeApproval,
                vin: data.vin,
                wheelPlan: data.wheelPlan,
            }

            setFormValues(Object.assign({...formValues}, DVLAFormValues))
        })
    }

    return (
        <>
            <Typography variant="h5">Vehicle details</Typography>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{paddingRight: '20px'}}>
                    <ValidInput
                        data-testid="vehicle-registration"
                        id="vehicleRegistration"
                        label="Vehicle registration"
                        name="vehicleRegistration"
                        onChange={handleInput}
                        value={formValues.vehicleRegistration}
                        errorText={formErrors.vehicleRegistration}
                        onKeyDown={(e) => e.key === "Enter" && handleDVLALookup()}
                        inputProps={{style: {textTransform: 'uppercase'}}}
                        required={!isDraft}
                    />
                </div>
                <LoadingButton
                    data-testid="find-vehicle-details"
                    onClick={handleDVLALookup}
                    startIcon={<Search />}
                    loading={isDVLALoading}
                >Find vehicle details</LoadingButton>
            </div>
            <div style={{ opacity: isDVLALoading ? 0.5 : 1}}>
                <ValidInput id="vehicleMake" label="Vehicle make" name="vehicleMake" value={formValues.vehicleMake} onChange={handleInput}  errorText={formErrors.vehicleMake} required={!isDraft} />
                <ValidInput id="vehicleModel" label="Vehicle model" name="vehicleModel" value={formValues.vehicleModel} onChange={handleInput} errorText={formErrors.vehicleModel} required={!isDraft} />
                <ValidInput id="vehicleColor" label="Vehicle color" name="vehicleColor" value={formValues.vehicleColor} onChange={handleInput} errorText={formErrors.vehicleColor} required={!isDraft} />
            </div>
            <Typography variant="h5">Parking Charge details</Typography>
            <ValidInput id="appellantPcnNumber" label="Parking Charge Notice number" name="appellantPcnNumber" value={formValues.appellantPcnNumber} onChange={handleInput} errorText={formErrors.appellantPcnNumber} required={!isDraft} />
        </>
    )
}

export default DVLAFields