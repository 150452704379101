import React from "react";
import AppealQuestion from "./AppealQuestion";
import PropTypes from "prop-types";

const AppealQuestions = ({questions}) => {
    return (
        <div style={{paddingLeft: '30px'}}>
            {questions.map(q => <AppealQuestion key={q.questionCode} question={q} />)}
        </div>
    )
}

AppealQuestions.propTypes = {
    questions: PropTypes.arrayOf(PropTypes.shape({
        questionCode: PropTypes.string,
        question: PropTypes.string,
    }))
}

export default AppealQuestions