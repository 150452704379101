import React, {useEffect, useState} from 'react';
import {
    Box, MenuItem, Paper, TextField, Typography, Button, Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {submitCaseWithdraw, useWithdrawalReasons} from "../../api/caseApi";
import withSnackbar from "../../components/WithSnackbar";
import {Case, CaseReason} from "../../model/Case"
import {genericMapper} from "../../utils/stringUtils";
import featureIsEnabled from "../../utils/featureFlag";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        height: '100%',
        boxSizing: 'border-box',
        color: theme.palette.text.secondary,
    },
    formControl: {
        marginBottom: theme.spacing(2)
    }
}));

export const WithdrawView = withSnackbar((props: WithdrawViewProps) => {
    const {caseObject, showToast, setCaseObject} = props;
    const classes = useStyles();
    const [disableChange, setDisableChange] = useState(false);
    const [withdrawnBy, setWithdrawnBy] = useState(caseObject.withdrawnBy || "");
    const [reasonCode, setReasonCode] = useState(caseObject.withdrawalReasonCode || "");
    const [reason, setReason] = useState(caseObject.withdrawalReasons || "");
    const {reasons : operatorReasons, loading: loadingOperatorReasons} = useWithdrawalReasons("OPERATOR");
    const {reasons : appellantReasons, loading: loadingAppellantReasons} = useWithdrawalReasons("APPELLANT");
    const [selectedReasons, setSelectedReasons] = useState(operatorReasons);

    const canSubmit = () => {
        if (disableChange) {
            return false
        }
        if (reasonCode === 'OTHER' && reason.length > 0) {
            return true;
        } else if ((reasonCode !== 'OTHER' && !!reasonCode) || !!reason) {
            return true;
        }
        return false;
    }

    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWithdrawnBy(event.target.value as string);
        setReasonCode('')
        if (event.target.value === "APPELLANT") {
            setSelectedReasons(appellantReasons);
        } else {
            setSelectedReasons(operatorReasons);
        }
    };

    const handleReasonCodeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setReasonCode(event.target.value as string);
    };

    const handleReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setReason(event.target.value as string);
    };

    const handleSubmitWithdraw = () => {
        submitCaseWithdraw(caseObject.verificationCode, reason, withdrawnBy, reasonCode).then((error) => {
            if (error.errorMessage) {
                showToast(`Could not withdraw case: ${error.errorMessage}`, 'error');
            } else {
                showToast('Withdraw Complete', 'success');
                setCaseObject({...caseObject, status: 'CLOSED', condition: 'WITHDRAWN'})
            }
            setDisableChange(true)
        });
    };

    const caseCanTransitionWithdrawn = (condition: string, status: string) => {
        const conditions = ["UNDER_REVIEW", "AWAITING_OPERATOR_EVIDENCE", "AWAITING_MOTORIST_COMMENT", "MOTORIST_EVIDENCE_SUBMITTED", "WAITING_FOR_ASSESSMENT", null];
        const states = ["NEW", "ACTIVE", "ON_HOLD"]
        return conditions.includes(condition) && states.includes(status)
    }

    useEffect(() => {
        const {condition, status} = caseObject;
        setDisableChange(!caseCanTransitionWithdrawn(condition, status))
        if (withdrawnBy === 'OPERATOR') {
            setSelectedReasons(operatorReasons);
        } else {
            setSelectedReasons(appellantReasons);
        }
    }, [caseObject, withdrawnBy, operatorReasons, appellantReasons])

    return (
        <Paper className={classes.paper}>
            <Typography variant="subtitle1" gutterBottom>Case Withdrawal</Typography>
            <Box>
                <div className={classes.formControl}>
                    <TextField
                        label="Withdrawn By"
                        name="withdrawnBy"
                        id="withdrawnBy-select"
                        variant="filled"
                        value={withdrawnBy}
                        disabled={disableChange}
                        onChange={handleStatusChange}
                        fullWidth
                        select
                        required
                    >
                        <MenuItem value={"OPERATOR"}>Operator</MenuItem>
                        <MenuItem value={"APPELLANT"}>Appellant</MenuItem>
                    </TextField>
                </div>

                {featureIsEnabled('withdrawal-reason-selection') && (
                     (
                        <div className={classes.formControl}>
                            <TextField
                                label="Reason Type"
                                name="reasonCode"
                                id="reasonCode-select"
                                value={reasonCode}
                                onChange={handleReasonCodeChange}
                                required={true}
                                disabled={loadingAppellantReasons || loadingOperatorReasons || disableChange}
                                fullWidth
                                select
                            >
                                {selectedReasons.map((reason: CaseReason) => (
                                    <MenuItem key={reason.reasonCode}
                                              value={reason.reasonCode}>{genericMapper(reason.description)}</MenuItem>
                                ))}
                            </TextField>
                        </div>
                    )
                )}

                <div className={classes.formControl}>
                    <TextField
                        label="Reason"
                        name="reason"
                        id="withdraw-reason"
                        placeholder="Reason For Withdraw"
                        variant="filled"
                        value={reason}
                        disabled={disableChange}
                        minRows={8}
                        onChange={handleReasonChange}
                        fullWidth
                        multiline
                        required={(withdrawnBy === "OPERATOR" && !reasonCode) || withdrawnBy === "APPELLANT"}
                    />
                </div>
            </Box>

            <Box display="flex" justifyContent="flex-end">
                <Button
                    data-testid="button_submit"
                    onClick={handleSubmitWithdraw}
                    disabled={!canSubmit()}
                >Withdraw</Button>
            </Box>
        </Paper>
    );
});

interface WithdrawViewProps {
    caseObject: Case;
    setCaseObject: any;
    showToast: any;
}