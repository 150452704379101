import Container from "@mui/material/Container";
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import tabPanelProps from "../../utils/tabPanelProps";
import React from 'react';


const TabPanel = function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...tabPanelProps(index) }
            {...other}
        >
            {value === index && (
                <Container>
                    <Box p={3}>
                        {children}
                    </Box>
                </Container>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default TabPanel;