import {displayDate} from "../../utils/dateUtils";
import validateUrl from "../../utils/validateUrl";
import {ArrowDropDownCircle, DeleteForever} from "@mui/icons-material";
import React, {useState} from "react";
import {IconButton, CircularProgress, TableCell, TableRow} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {deleteFile} from "../../api/evidenceApi";
import ConfirmDialog from "../ConfirmDialog";
import withSnackbar from "../WithSnackbar";
import {hasAuthority} from "../../auth/AuthorityHelper";

const useStyles = makeStyles(() => ({
    rowLoading: {
        opacity: 0.4,
        pointerEvents: 'none'
    },
    row: {}
}));

const DocumentRow = (props: Props) => {
    const {row, onChange, showToast} = props;
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const promptDelete = () => {
        setDialogIsOpen(true)
    }

    const deleteDocument = () => {
        setIsLoading(true);
        deleteFile(row.guid)
            .then(() => {
                showToast(`The document ${row.filename} was deleted`)
                onChange(row);
            })
            .catch(() => {
                showToast(`There was a problem deleting '${row.filename}'`, "error")
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <TableRow className={ isLoading ? classes.rowLoading : classes.row }>
            <TableCell component="th" scope="row">{row.filename}</TableCell>
            <TableCell align="left">{row.category}</TableCell>
            <TableCell align="left">{displayDate(row.createdAt)}</TableCell>
            <TableCell align="center" className={'pointer'}>
                <a href={validateUrl(row.signedUrl)} download target="_blank" rel="noreferrer">
                    <ArrowDropDownCircle style={{color: '#0E2361'}}/>
                </a>
            </TableCell>
            {   hasAuthority("DELETE_DOCUMENT") &&
                (<TableCell align="center" className={'pointer'}>
                    {isLoading
                        ? <CircularProgress color="secondary" size={25}/>
                        : (
                            <IconButton data-testid="delete-document" size="small" onClick={() => promptDelete()}>
                                <DeleteForever style={{color: '#d32f2f'}}/>
                            </IconButton>
                        )
                    }
                    <ConfirmDialog title="Confirm deletion" open={dialogIsOpen} setOpen={setDialogIsOpen}
                                   onConfirm={() => deleteDocument()}>
                        Are you sure you want to delete <b>{row.filename}</b>?
                    </ConfirmDialog>
                </TableCell>)
            }
        </TableRow>
    )
}

interface Props {
    row: any,
    // eslint-disable-next-line no-unused-vars
    onChange: (row: any) => void,
    // eslint-disable-next-line no-unused-vars
    showToast: (message: string, type?: string) => void
}

export default withSnackbar(DocumentRow);