import React, {useContext, useState} from 'react'
import {useGroundsForAppeal} from "../../api/caseApi";
import {LinearProgress} from "@mui/material";
import {CaseFormContext} from "./CaseFormContext";
import AppealGrounds from "./questions/AppealGrounds";
import {useUser} from "../../auth/useUser";
import Typography from "@mui/material/Typography";

const AppealDetails = () => {
    const {user} = useUser();
    const {answers} = useContext(CaseFormContext)
    const { isLoading, grounds } = useGroundsForAppeal(user?.token);
    const [disabledGrounds, setDislabledGrounds] = useState([])
    const mandatoryGrounds = ["G7"];

    function handleChange(e) {
        if (e.target.name === 'G1' && e.target.checked) {
            const disabledGrounds = grounds.grounds
                .filter(g => g.groundsCode !== "G1" && !mandatoryGrounds.includes(g.groundsCode))
                .map(g => g.groundsCode)
            setDislabledGrounds(disabledGrounds)
        } else {
            setDislabledGrounds([])
        }
    }

    return (
        <div>
            <Typography variant="h5" gutterBottom>Appeal details</Typography>
            <Typography variant="subtitle1">Grounds for appeal</Typography>
            { isLoading && <LinearProgress />}
            { !isLoading && grounds.grounds.map(grounds => (
                <AppealGrounds
                    answers={answers}
                    disabled={disabledGrounds.includes(grounds.groundsCode)}
                    mandatory={mandatoryGrounds.includes(grounds.groundsCode)}
                    onChange={handleChange}
                    key={grounds.groundsCode}
                    grounds={grounds}
                />
            ))}
        </div>
    )
}

AppealDetails.propTypes = {}

export default AppealDetails