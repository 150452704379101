import React, {useState} from "react";
import {Paper, Box, Grid, List, ListItem, ListItemText} from "@mui/material";
import Typography from "@mui/material/Typography";
import {displayDateTime} from "../../utils/dateUtils";
import {useUser} from "../../auth/useUser";
import {addComment, useComments} from "../../api/commentsApi";
import {AddComment} from "./AddComment";
import withSnackbar from "../WithSnackbar";

export const OperatorResponseView = withSnackbar(({ caseObject, showToast }: OperatorResponseViewProps) => {
    const {user} = useUser();
    const [updateTime, setUpdateTime] = useState<Date | null>(null);
    const {operatorEvidence, motoristEvidence} = useComments(caseObject?.verificationCode, user?.token, updateTime) as CommentsResponse

    function handleAddNote(comment:any) {
        let newComment = {
            ...comment,
            caseId: caseObject?.caseId,
            operatorCode: caseObject?.operatorCode,
        }
        addComment(newComment, user?.token)
            .catch(err => {
                showToast(`Could not add comment: ${err.error}`, 'error');
            }).then( () => {
                setUpdateTime(new Date())
            }
        )
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper style={{width: '100%', height: '100%'}}>
                        <Box p={2}>
                            <Typography variant="subtitle1" style={{color: "#0E2361"}}>
                                <b>Operator Responses</b>
                            </Typography>
                            <List data-testid="operatorComment">
                                {operatorEvidence.length === 0 && (
                                    <ListItem disableGutters>
                                        <ListItemText primary="No responses are available at this time." />
                                    </ListItem>
                                )}
                                {operatorEvidence.map(evidence => (
                                    <ListItem key={evidence.id} disableGutters>
                                        <ListItemText primary={evidence.comment} secondary={displayDateTime(evidence.createdDate, "-")} style={{whiteSpace : 'pre-wrap'}} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper  style={{width: '100%', height: '100%'}}>
                        <Box p={2}>
                            <Typography variant="subtitle1" style={{color: "#0E2361"}}>
                                <b>Motorist response to operator evidence</b>
                            </Typography>
                            <List data-testid="motoristComment">
                                {motoristEvidence.map(evidence => (
                                    <ListItem key={evidence.id} disableGutters>
                                        <ListItemText primary={evidence.comment} secondary={displayDateTime(evidence.createdDate, "-")} style={{whiteSpace : 'pre-wrap'}} />
                                    </ListItem>
                                ))}
                                <AddComment onAdd={handleAddNote} />
                            </List>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <p><i>Documents are available on the Documents tab</i></p>
        </>
    );
});

interface OperatorResponseViewProps {
    caseObject: any;
    showToast: any;
}

interface Comment {
    caseId: number
    comment: string
    commentType: string
    createdDate?: string
    id: number
    userType: string
}

interface CommentsResponse {
    isLoading: boolean
    error: string | null
    operatorEvidence: Comment[]
    motoristEvidence: Comment[]
}