import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Paper, Box, FormControlLabel, Radio, Theme} from "@mui/material";
import Typography from "@mui/material/Typography";
import AnswersView from "./AnswersView";

interface MotoristResponseViewProps {
    caseObject: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        color: theme.palette.text.secondary,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export const MotoristResponseView = (props: MotoristResponseViewProps) => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper} style={{padding: 0}}>
            <Box p={2}>
                <Typography variant="subtitle1" gutterBottom>
                    Motorist Responses
                </Typography>
                <Box mb={2} display="flex" alignItems="center">
                    Submitted on behalf:
                    <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Yes"
                        checked={props.caseObject.submittedOnBehalf}
                        labelPlacement="start"
                        disabled
                    />
                    <FormControlLabel
                        control={<Radio color="primary" />}
                        label="No"
                        checked={!props.caseObject.submittedOnBehalf}
                        labelPlacement="start"
                        disabled
                    />
                </Box>
                <AnswersView verificationCode={props.caseObject.verificationCode}/>
            </Box>
        </Paper>
    );
};