import React, {useState} from 'react'
import makeStyles from '@mui/styles/makeStyles';
import {Breadcrumbs, Box, Link as MaterialLink, Typography, Grid, Tooltip, Button} from "@mui/material";
import {Link as ReactLink, useHistory} from "react-router-dom";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {PersonAdd} from "@mui/icons-material";
import {useOperatorUsers} from "../../api/operatorUsersApi.js";
import {useOperators} from "../../api/operatorApi";
import {hasAuthority} from "../../auth/AuthorityHelper";

const useStyles = makeStyles(() => ({
    truncatedCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}));

const OperatorUsersPage = () => {
    const [pageNum, setPageNum] = useState(0);
    const { isLoading: isLoadingOperators, operators } = useOperators(0, 1000);
    const { isLoading, operatorUsers } = useOperatorUsers();
    const classes = useStyles();
    const history = useHistory();

    const getOperatorName = (code) => {
        if (operators && operators?.content) {
            const operator = operators?.content.find(op => op.operatorCode === code);
            return operator?.name || code;
        }
        return code;
    }

    const columns = [
        {field: 'name', headerName: 'Name', flex: 1},
        {field: 'email', headerName: 'Email', flex: 1},
        {field: 'operatorCodes', headerName: 'Operators', flex: 1,
            valueGetter: (params) => params.value.map(code => getOperatorName(code)).join(', '),
            renderCell(params) {
                return (
                    <Tooltip title={params.value}>
                        <span className={classes.truncatedCell}>{params.value}</span>
                    </Tooltip>
                );
            }
        },
        {field: 'roles', headerName: 'Roles', flex: 1, valueFormatter: params => {
                return params.value?.toLowerCase();
       }},
    ]

    const handleRowClick = (params) => {
        history.push({
            pathname: `/operators/users/edit`,
            state: { operatorUser: params.row },
        })
    }

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: '28px'}}>
                <MaterialLink component={ReactLink} to="/" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <MaterialLink component={ReactLink} to="/operators" className={"App-Breadcrumb-Link"}>
                    <u>Operator Management</u>
                </MaterialLink>
                <Typography color="textPrimary">View Operator Users</Typography>
            </Breadcrumbs>
            <Box>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h1" gutterBottom>View Operator Users</Typography>
                    </Grid>
                    { hasAuthority('CREATE_OPERATOR_USER') &&
                    (<Grid item>
                        <Button data-testid="add-operator-user" startIcon={<PersonAdd />} component={ReactLink} to="/operators/users/create">Add Operator User</Button>
                    </Grid>)}
                </Grid>
                <DataGridPro
                    rows={operatorUsers?.users || []}
                    columns={columns}
                    loading={isLoading || isLoadingOperators}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    page={pageNum}
                    paginationMode="client"
                    autoHeight
                    disableColumnMenu
                    disableSelectionOnClick
                    columnBuffer={4}
                    onPageChange={setPageNum}
                    onRowClick={handleRowClick}
                />
            </Box>
        </div>
    );
}

OperatorUsersPage.protoTypes = {
}

export default OperatorUsersPage