import {apiUrl} from "./apiEnv";
import {axios} from "./apiWrapper";


export const addAssessorUser = async (AssessorUser) => {
    AssessorUser.userType = "ASSESSOR";
    let result = { errorMessage: null, status: null };
    try {
        const res = await axios.post(`${apiUrl}/security/users`,
            AssessorUser);
        result.status = res.status;
    } catch(err) {
        result.errorMessage = err.response.data.message;
    }
    return result;
};

export const updateAssessorUser = async (AssessorUser) => {
    AssessorUser.userType = "ASSESSOR";
    let result = { errorMessage: null, status: null };
    try {
        const res = await axios.put(`${apiUrl}/security/users`,
            AssessorUser);
        result.status = res.status;
    } catch(err) {
        result.errorMessage = err.response.data.message;
    }
    return result;
};
